import React from "react";
import { useAppContext } from "../context/AppContext";

import "../styles/components/Theme.scss";

import SunlightIcon from "../assets/images/icons/sun-light.svg";
import MoondarkIcon from "../assets/images/icons/moon-dark.svg";

export default function Theme() {
  const [{ theme }, dispatch] = useAppContext();
  const themeValidation = theme === "dark";

  const handleTheme = () => {
    dispatch({ type: "CHANGE_THEME" });
  };

  return (
    <button type="button" className="theme" onClick={handleTheme}>
      <span className="theme__slider" data-dark={themeValidation}>
        <img
          src={themeValidation ? MoondarkIcon : SunlightIcon}
          alt={`${theme} theme`}
          className="theme__icon"
        />
      </span>
    </button>
  );
}
