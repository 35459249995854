export default function getBoot() {
  return {
    en: {
      days: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ],
      range: {
        today: "Today",
        yesterday: "Yesterday",
        thisWeek: "This Week",
        lastWeek: "Last Week",
        thisMonth: "This Month",
        lastMonth: "Last Month",
        thisYear: "This Year",
        range: "Range"
      },
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      welcome: {
        alert2FA: "Enable two-step authentication.",
        alert2FAI: "Safeguard your account!",
        greeting: "Welcome",
        signout: "Log Out",
        menuLink1: "Affico",
        menuLink2: "Paysys",
        menuLink3: "Tickets",
        menuLink4: "Advanced settings",
        menuLink5: "Leads",
        menuLink6: "Clients",
        menuLink7: "Compliance",
        menuLink8: "Permissions",
        menuLink9: "Support",
        menuLink10: "Dashboard"
      },
      login: {
        email: "Email",
        password: "Password",
        signIn: "Sign In",
        signOut: "Log Out",
        welcome: "Welcome",
        g2fa: "Google Authenticator PIN",
        g2faDont: "I want to use my recovery key",
        g2faReturn: "I have my Google Authenticator PIN",
        g2faRecovery: "Enter the recovery code"
      },
      general: {
        cancel: "Cancel",
        save: "Save",
        summary: "Summary",
        all: "All",
        pay: "Pay",
        send: "Send"
      },
      error: {
        EUNF: "Error: User Not Found",
        EIPW: "Error: Invalid Password",
        ECNE: "Error: Incorrect code"
      },
      swal: {
        errorTitle: "Ooops!",
        error: {
          ENPF: "Format Incorrect!",
          EIBU: "Business Unit not found!"
        }
      },
      permissions: {
        title: "Permissions",
        users: "Users",
        positions: "Positions",
        addUser: "Add User",
        userInfo: "User Information",
        addPermission: "Add Permission",
        addPosition: "Add Position"
      },
      table: {
        affFtdDate: "Affiliate FTD Date",
        last4: "Last 4",
        name: "Name",
        userName: "Username",
        firstName: "First Name",
        lastName: "Last name",
        password: "Password",
        position: "Position",
        businessUnit: "Business Unit",
        action: "Actions",
        details: "Details",
        search: "Search",
        download: "Download",
        print: "Print",
        assignLead: "Assign Leads",
        changeStatus: "Change Status",
        user: "User",
        affiliate: "Affiliate",
        remAmount: "Rem Amount",
        totalLeads: "Total Leads",
        totalFtd: "Total FTD",
        convertion: "Convertion",
        affiliateConvertion: "Affiliate Convertion",
        realConvertion: "Real Convertion",
        avgFtd: "Avg FTD",
        avgLeads: "Avg Leads",
        lifeTimeValue: "LTV Aff",
        highPotential: "HP%",
        country: "Country",
        crmId: "CRM ID",
        email: "Email",
        funnel: "Funnel",
        idCrmAffiliate: "IdAffiliate",
        idCrmCustomerStatusCat: "IdCustomer Status",
        insertDate: "Insert Date",
        confirmDate: "Confirm Date",
        ftdDate: "FTD Date",
        source: "Source",
        status: "Status",
        id: "ID Client",
        gender: "Gender",
        agent: "Agent",
        contact: "Last contact",
        phone: "Phone Number",
        amount: "Amount",
        psp: "PSP",
        agentCustomer: "Agent Customer",
        agentTransaction: "Agent Transaction",
        ftd: "FTD",
        idReservation: "Reservation ID",
        supplierAmount: "Supplier Amount",
        clientAmount: "Client Amount",
        b2cAmount: "B2C Amount",
        b2cEmail: "B2C Email",
        clientEmail: "Client Email",
        filter: "Filter",
        section: "Section",
        page: "Page",
        yes: "YES",
        referral: "Referral",
        dpto: "Departament",
        transType: "Transaction Type",
        paymentStatus: "Payment Status",
        transactionDepartment: "Department",
        transCategories: "Transaction Categories",
        pspName: "PSP Name",
        totalPayments: "Total Payments",
        totalAmount: "Total Amount",
        netAmount: "Net Amount",
        approvedPercentage: "Approved %",
        avgPayments: "Avg Payments/Day",
        avgAmount: "Avg Amount/Day",
        minDeposit: "Min Deposit",
        maxDeposit: "Max Deposit",
        ftdPayments: "FTD Payments",
        retPayments: "Retention Payments",
        retAmount: "Retention Amount",
        ftdAmount: "FTD Amount",
        ftdNumber: "FTD number",
        totalDeposits: "Total Deposits",
        totalWithdrawal: "Total Withdrawal",
        approved: "Approved",
        expired: "Expired",
        pending: "Pending",
        rejected: "Rejected",
        refounded: "Refounded",
        ftdsAverage: "AVG FTDS",
        leadsAverage: "AVG Leads",
        totalFtds: "Total FTDS",
        leadsDetails: "Total Leads Details",
        all: "All",
        reference: "Reference",
        idTrans: "Transaction ID",
        depositDate: "Deposit Date",
        approvedDocuments: "Approved Documents",
        rejectedDocuments: "Rejected Documents",
        pendingDocuments: "Pending Documents",
        transactionId: "Transaction ID",
        providerId: "Provider ID"
      },
      clients: {
        title: "Clients"
      },
      affico: {
        addNewLead: "Add New Lead",
        addNewLeads: "Add Massive Leads",
        addLeads: "Add Leads",
        affiliateDetails: "Affiliate Details",
        addAffiliate: "Add Affiliate",
        sendToCRM: "Send to CRM",
        hasFtd: "Has FTD",
        traffic: "Traffic",
        date: "Date",
        dateStart: "Date Start",
        dateEnd: "Date End",
        filters: "Filters",
        leadsDetails: "Leads Details",
        approveFtd: "Approve FTDs",
        sendFtdToCrm: "Enviar a CRM",
        activeFilters: "Active Filters",
        affName: "Affiliate Name",
        partnerID: "Partner ID",
        convertionCost: "Convertion Cost",
        allowedIP: "Allowed IP",
        addIP: "Add IP",
        code: "Code",
        prepay: "Prepay",
        secretKey: "Secret Key",
        usdAmount: "USD Amount",
        notes: "Notes",
        addPredeposit: "Add Predeposit",
        searchTP: "Search TP",
        downloadTable: "Download Table",
        downloadCustomersInfo: "Download Customers Information",
        "move-customer": "Move customer",
        "move-to": "To",
        "move-submit": "Move",
        "move-cancel": "Cancel",
        "move-close": "Close",
        "ftd-date": "FTD Date:",
        "insert-date": "Insert Date:",
        "affiliate-date": "Affiliate Date:",
        country: "Country:",
        referral: "Referral:",
        status: "Status:",
        "move-required": "Error!",
        "move-required-text": "You must select a customer to move",
        "transaction-id": "Transaction ID:",
        "transaction-status": "Transaction status:",
        "name": "Full name:",
        "email": "Email:",
        "sure-move": "Warning!",
        "sure-move-text": "Are you sure to move it?",
        "ftd-moved": "FTD moved",
        "ftd-moved-text": "FTD moved successfully",
        "search-by-email": "Search by email",
        "search-by-email-placeholder": "Enter email",
        search: "Search",
        "search-normal": "Normal search",
        "search-normal-placeholder": "Filter",
      },
      paysys: {
        activeTrans: "Active Transactions",
        pspSummary: "PSP Summary",
        paysysUser: "Paysys User",
        userDetails: "User details",
        addTransaction: "Add transaction",
        manualPay: "Manual Pay",
        infoUser: "User info",
        savedCards: "Saved cards",
        addCard: "Add card",
        addNewCard: "Add new card",
        updateCard: "Update card",
        cardNumber: "Card number",
        holderName: "Card Name",
        expires: "Expires in",
        cvv: "CVV",
        userAccount: "User account",
        transMethod: "Transaction Method",
        selectPayment: "Select your payment method",
        manualTransaction: "Manual Transaction",
        alreadyDeposit:
          "If you already have your deposit details enter them below",
        typeTrans: "Type of transaction",
        method: "Method",
        ccLast: "CC Last",
        amount: "Monto",
        currency: "Currency",
        userDocumentNumber: "DNI",
        minAmount: "Min Amount",
        bankTransfer: "Bank Transfer",
        cashTransfer: "Cash Transfer"
      },
      settings: {
        title: "Settings",
        generalInfo: "General Info",
        newPass: "New Password",
        repeatNewPass: "Repeat New Password",
        actualPass: "Current Password",
        addAuth: "Active 2FA",
        removeAuth: "Remove 2FA",
        pin: "Insert PIN",
        copyCode: "Copy the next code and save it"
      },
      compliance: {
        transaction: "Transaction",
        clients: "Clients",
        agents: "Agents",
        assignedTo: "Assigned To",
        assignClients: "Assign clients",
        documents: "Documents",
        download: "Download documents",
        email: "Email",
        phone: "Phone",
        country: "Country",
        complianceStatus: "Compliance status",
        address: "Address",
        dateOfBirth: "Date of birth",
        lastDeposit: "Last deposit day",
        ftdDate: "FTD Date",
        upload: "Upload",
        expirationDate: "Expiration date",
        choose: "Choose file",
        chooseHere: "Or drag file here",
        cancel: "Cancel",
        fileToUpload: "File to upload",
        dod: "Declaration of Deposit (DOD)",
        por: "Proof of Residence (POR)",
        ccf: "Credit card (Front)",
        ccb: "Credit card (Back)",
        idf: "Proof of Identity (POI) (Front)",
        idb: "Proof of Identity (POI) (Back)",
        pop: "Proof of Payment (POP)",
        notFile: "No files to display",
        historyUploads: "History uploads",
        dodTransactions: "DOD Transactions",
        amount: "Amount",
        date: "Date",
        status: "Status",
        actions: "Actions",
        uploadDate: "Upload date",
        swalErrorAssign: "Select any client",
        swalErrorAssignAgent: "Select an agent",
        swalConfirmAssignAgent: "Are you sure you want to assignment all to",
        swalAssignSuccess: "Assignment successfully",
        swalAssignCancel: "Assignment canceled",
        swalAssignError: "Clients can't be assigned",
        swalErrorFile: "Please upload a document",
        swalSuccessFile: "File uploaded successfully",
        swalErrorDOD: "Select any transaction REJECTED, or not Not File"
      },
      support: {
        infoTransaction: "Send a transacción to CRM"
      },
      dashboard: {
        range: "Range",
        date: "Date",
        applyFilters: "Apply Filters"
      }
    },
    es: {
      days: [
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
        "Domingo"
      ],
      range: {
        today: "Hoy",
        yesterday: "Ayer",
        thisWeek: "Esta semana",
        lastWeek: "Semana pasada",
        thisMonth: "Este mes",
        lastMonth: "Último mes",
        thisYear: "Este año",
        range: "Rango"
      },
      months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      ],
      welcome: {
        alert2FA: "Activa la autenticación en dos pasos.",
        alert2FAI: "¡Protege tu cuenta!",
        greeting: "Bienvenid@",
        signout: "Cerrar Sesión",
        menuLink1: "Affico",
        menuLink2: "Paysys",
        menuLink3: "Tickets",
        menuLink4: "Configuraciones avanzadas",
        menuLink5: "Leads",
        menuLink6: "Clientes",
        menuLink7: "Compliance",
        menuLink8: "Permisos",
        menuLink9: "Soporte",
        menuLink10: "Tablero"
      },
      login: {
        email: "Correo",
        password: "Contraseña",
        signIn: "Iniciar Sesión",
        signOut: "Cerrar Sesión",
        welcome: "Bienvenid@",
        g2fa: "Google Authenticator PIN",
        g2faDont: "Quiero usar mi código de recuperación",
        g2faReturn: "Tengo my PIN de Google Authenticator",
        g2faRecovery: "Ingrese el codigo de recuperación"
      },
      general: {
        cancel: "Cancelar",
        save: "Guardar",
        summary: "Resumen",
        all: "Todos",
        pay: "Pagar",
        send: "Send"
      },
      error: {
        EUNF: "Error: Usuario no Encontrado",
        EIPW: "Error: Contraseña Invalida",
        ECNE: "Error: Código Incorrecto"
      },
      swal: {
        errorTitle: "Ooops!",
        error: {
          ENPF: "Formato Incorrecto!",
          EIBU: "Unidad de negocio no encontrada!"
        }
      },
      permissions: {
        title: "Permisos",
        users: "Usuarios",
        positions: "Posiciones",
        addUser: "Añadir Usuario",
        userInfo: "Información de Usuario",
        addPermission: "Añadir Permiso",
        addPosition: "Añadir Posición"
      },
      table: {
        affFtdDate: "Fecha de Afiliado FTD",
        last4: "Últimos 4",
        name: "Nombre",
        userName: "Nombre de usuario",
        firstName: "Nombre",
        lastName: "Apellido",
        password: "Contraseña",
        position: "Posición",
        businessUnit: "Marca",
        action: "Acciones",
        details: "Detalles",
        search: "Buscar",
        download: "Descargar",
        print: "Imprimir",
        assignLead: "Asignar Leads",
        changeStatus: "Cambiar Status",
        user: "Usuario",
        affiliate: "Afiliado",
        remAmount: "Monto Rem",
        totalFtd: "FTD total",
        convertion: "Conversión",
        affiliateConvertion: "Conversion de Afiliado",
        realConvertion: "Conversión Real",
        avgFtd: "FTD Promedio",
        avgLeads: "Leads Promedio",
        lifeTimeValue: "LTV Aff",
        highPotential: "HP%",
        country: "Pais",
        crmId: "CRM ID",
        email: "Email",
        funnel: "Funnel",
        idCrmAffiliate: "idAfiliado",
        idCrmCustomerStatusCat: "iDEstado Cliente",
        phone: "Teléfono",
        source: "Fuente",
        status: "Status",
        id: "Cliente ID",
        gender: "Genero",
        insertDate: "Fecha de inserción",
        confirmDate: "Fecha de confirmación",
        ftdDate: "Fecha de FTD",
        agent: "Agente",
        contact: "Ultimo contacto",
        amount: "Cantidad",
        psp: "PSP",
        agentCustomer: "Agente del Cliente",
        agentTransaction: "Transacción del Agente",
        ftd: "FTD",
        idReservation: "ID de Reservación",
        supplierAmount: "Monto del Proveedor",
        volindoAmount: "Monto de Volindo",
        clientAmount: "Monto del Cliente",
        b2cAmount: "Monto B2C",
        b2cEmail: "Correo B2C",
        clientEmail: "Correo del Cliente",
        filter: "Filtro",
        section: "Sección",
        page: "Pagina",
        yes: "SI",
        referral: "Referido",
        dpto: "Departamento",
        transType: "Tipo de Transacción",
        paymentStatus: "Estado del Pago",
        transactionDepartment: "Departamento",
        transCategories: "Categoria de Transacción",
        pspName: "Nombre del PSP",
        totalPayments: "Pagos Totales",
        totalAmount: "Monto Total",
        netAmount: "Monto Neto",
        approvedPercentage: "% Aprobado",
        avgPayments: "Pagos Promedio al Dia",
        avgAmount: "Monto Promedio al Dia",
        minDeposit: "Deposito Minimo",
        maxDeposit: "Deposit Maximo",
        bankTransfer: "Transferencia",
        cashTransfer: "Efectivo",
        ftdPayments: "Pagos FTD",
        retPayments: "Pagos de retención",
        retAmount: "Monto de retención",
        ftdAmount: "Monto FTD",
        ftdNumber: "Numero de FTDs",
        totalDeposits: "Total de Depositos",
        totalWithdrawal: "Retiro Total",
        approved: "Aprobado",
        expired: "Expirado",
        pending: "Pendiente",
        rejected: "Rechazado",
        refounded: "Devuelto",
        ftdsAverage: "Promedio FTDS",
        leadsAverage: "Promedio Leads",
        totalFtds: "FTDS totales",
        totalLeads: "Leads totales",
        leadsDetails: "Detalles de leads totales",
        all: "Todas",
        reference: "Referencia",
        idTrans: "ID de transacción",
        depositDate: "Fecha de Deposito",
        approvedDocuments: "Documentos Aprobados",
        rejectedDocuments: "Documentos Rechazados",
        pendingDocuments: "Documentos Pendientes",
        transactionId: "Transacción ID",
        providerId: "Provedor ID"
      },
      clients: {
        title: "Clientes"
      },
      affico: {
        addNewLead: "Añadir Nuevo Lead",
        addNewLeads: "Añadir Lead Masivamente",
        addLeads: "Añadir Leads",
        affiliateDetails: "Detalles de Afiliado",
        addAffiliate: "Añadir Afiliado",
        sendToCRM: "Enviar al CRM",
        hasFtd: "Tiene FTD",
        traffic: "Tráfico",
        date: "Fecha",
        dateStart: "Fecha Inicial",
        dateEnd: "Fecha Final",
        filters: "Filtros",
        leadsDetails: "Detalle de Leads",
        approveFtd: "Aprobar FTDs",
        sendFtdToCrm: "Send to CRM",
        activeFilters: "Filtros Activos",
        affName: "Nombre de Afiliado",
        partnerID: "Partner ID",
        convertionCost: "Costo de Conversión",
        allowedIP: "IP Permitida",
        addIP: "Añadir IP",
        code: "Codigo",
        prepay: "Prepago",
        usdAmount: "Monto en USD",
        secretKey: "Llave Secreta",
        notes: "Notas",
        addPredeposit: "Añadir predeposito",
        searchTP: "Buscar TP",
        downloadTable: "Descargar Tabla",
        downloadCustomersInfo: "Descargar Información de Clientes",
        name: "Nombre:",
        email: "Correo:",
        "move-customer": "Move cliente",
        "move-to": "A",
        "move-submit": "Mover",
        "move-cancel": "Cancelar",
        "move-close": "Cerrar",
        "ftd-date": "Fecha de FTD:",
        "insert-date": "Fecha de inserción:",
        "affiliate-date": "Fecha del afiliado:",
        country: "País:",
        referral: "Referencia:",
        status: "Estado:",
        "move-required": "Error!",
        "move-required-text": "Debe seleccionar un cliente para mover",
        "transaction-id": "ID de Transacción:",
        "transaction-status": "Estado de Transacción:",
        "sure-move": "Advertencia!",
        "sure-move-text": "¿Estás seguro de moverlo?",
        "ftd-moved": "FTD movido",
        "ftd-moved-text": "FTD movido exitosamente",
        "search-by-email": "Buscar por correo",
        "search-by-email-placeholder": "Ingresa el correo",
        search: "Buscar",
        "search-normal": "Búsqueda normal",
        "search-normal-placeholder": "Filtro",
      },
      paysys: {
        activeTrans: "Transacciones Activas",
        pspSummary: "Resumen de PSP",
        paysysUser: "Usuario de Paysys",
        userDetails: "Detalles de usuario",
        addTransaction: "Añadir transacción",
        manualPay: "Pago manual",
        infoUser: "Información de usuario",
        savedCards: "Tarjetas guardadas",
        addCard: "Añadir tarjeta",
        addNewCard: "Añadir nueva tarjeta",
        updateCard: "Actualizar tarjeta",
        cardNumber: "Numero de tarjeta",
        holderName: "Nombre en la tarjeta",
        expires: "Expira en",
        cvv: "CVC",
        userAccount: "Cuenta de Usuario",
        transMethod: "Metodo de Transacción",
        selectPayment: "Selecciona tu metodo de pago",
        manualTransaction: "Transacción manual",
        alreadyDeposit: "Si ya tienes los datos del deposito agregalos abajo",
        typeTrans: "Tipo de transacción",
        method: "Metodo",
        ccLast: "Ultimos cuatro digitos",
        amount: "Monto",
        currency: "Currency",
        userDocumentNumber: "DNI",
        minAmount: "Monto minimo",
        bankTransfer: "Transferencia Bancaria",
        cashTransfer: "Transferencia de Efectivo"
      },
      settings: {
        title: "Ajustes",
        generalInfo: "Información General",
        newPass: "Nueva contraseña",
        repeatNewPass: "Repite la nueva contraseña",
        actualPass: "Contraseña actual",
        addAuth: "Activar 2FA",
        removeAuth: "Desactivar 2FA",
        pin: "Insertar PIN",
        copyCode: "Copia el codigo y guardalo en un lugar seguro"
      },
      compliance: {
        transaction: "Transacción",
        clients: "Clientes",
        agents: "Agentes",
        assignedTo: "Asignado a",
        assignClients: "Asignar Clientes",
        documents: "Documentos",
        download: "Descargar documentos",
        email: "Correo",
        phone: "Teléfono",
        country: "País",
        complianceStatus: "Estado de cumplimiento",
        address: "Dirección",
        dateOfBirth: "Fecha de nacimiento",
        lastDeposit: "Último día de depósito",
        ftdDate: "Fecha FTD",
        upload: "Subir",
        expirationDate: "Fecha de expiración",
        choose: "Elija el archivo",
        chooseHere: "O arrastre el archivo aquí",
        cancel: "Cancelar",
        fileToUpload: "Archivo a subir",
        dod: "Declaración de Depositos (DOD)",
        por: "Comprobante de residencia (POR)",
        ccf: "Tarjeta de crédito (frente)",
        ccb: "Tarjeta de crédito (reverso)",
        idf: "Prueba de Identidad (POI) (frente)",
        idb: "Prueba de Identidad (POI) (reverso)",
        pop: "Prueba de pago (POP)",
        notFile: "No hay archivos para mostrar",
        historyUploads: "Historial de documentos",
        dodTransactions: "Transacciones DOD",
        amount: "Monto",
        date: "Fecha",
        status: "Estatus",
        actions: "Acciones",
        uploadDate: "Fecha de subida",
        swalErrorAssign: "Selecciona algún cliente",
        swalErrorAssignAgent: "Selecciona algún agente",
        swalConfirmAssignAgent: "Estas seguro de reasignar los con",
        swalAssignSuccess: "Asignación exitosa",
        swalAssignCancel: "Asignación cancelada",
        swalAssignError: "Clientes no pudieron ser asignados",
        swalErrorFile: "Sube un documento por favor",
        swalSuccessFile: "Archivo subido exitosamente",
        swalErrorDOD: "Selecciona alguna transacción rechazada, o sin documento"
      },
      support: {
        infoTransaction: "Enviar una transacción al CRM"
      },
      dashboard: {
        range: "Rango",
        date: "Fecha",
        applyFilters: "Aplicar filtros"
      }
    }
  };
}
export function getCountries() {
  return {
    BD: "Bangladesh",
    BE: "Belgium",
    BF: "Burkina Faso",
    BG: "Bulgaria",
    BA: "Bosnia and Herzegovina",
    BB: "Barbados",
    WF: "Wallis and Futuna",
    BL: "Saint Barthelemy",
    BM: "Bermuda",
    BN: "Brunei",
    BO: "Bolivia",
    BH: "Bahrain",
    BI: "Burundi",
    BJ: "Benin",
    BT: "Bhutan",
    JM: "Jamaica",
    BV: "Bouvet Island",
    BW: "Botswana",
    WS: "Samoa",
    BQ: "Bonaire, Saint Eustatius and Saba ",
    BR: "Brazil",
    BS: "Bahamas",
    JE: "Jersey",
    BY: "Belarus",
    BZ: "Belize",
    RU: "Russia",
    RW: "Rwanda",
    RS: "Serbia",
    TL: "East Timor",
    RE: "Reunion",
    TM: "Turkmenistan",
    TJ: "Tajikistan",
    RO: "Romania",
    TK: "Tokelau",
    GW: "Guinea-Bissau",
    GU: "Guam",
    GT: "Guatemala",
    GS: "South Georgia and the South Sandwich Islands",
    GR: "Greece",
    GQ: "Equatorial Guinea",
    GP: "Guadeloupe",
    JP: "Japan",
    GY: "Guyana",
    GG: "Guernsey",
    GF: "French Guiana",
    GE: "Georgia",
    GD: "Grenada",
    GB: "United Kingdom",
    GA: "Gabon",
    SV: "El Salvador",
    GN: "Guinea",
    GM: "Gambia",
    GL: "Greenland",
    GI: "Gibraltar",
    GH: "Ghana",
    OM: "Oman",
    TN: "Tunisia",
    JO: "Jordan",
    HR: "Croatia",
    HT: "Haiti",
    HU: "Hungary",
    HK: "Hong Kong",
    HN: "Honduras",
    HM: "Heard Island and McDonald Islands",
    VE: "Venezuela",
    PR: "Puerto Rico",
    PS: "Palestinian Territory",
    PW: "Palau",
    PT: "Portugal",
    SJ: "Svalbard and Jan Mayen",
    PY: "Paraguay",
    IQ: "Iraq",
    PA: "Panama",
    PF: "French Polynesia",
    PG: "Papua New Guinea",
    PE: "Peru",
    PK: "Pakistan",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PM: "Saint Pierre and Miquelon",
    ZM: "Zambia",
    EH: "Western Sahara",
    EE: "Estonia",
    EG: "Egypt",
    ZA: "South Africa",
    EC: "Ecuador",
    IT: "Italy",
    VN: "Vietnam",
    SB: "Solomon Islands",
    ET: "Ethiopia",
    SO: "Somalia",
    ZW: "Zimbabwe",
    SA: "Saudi Arabia",
    ES: "Spain",
    ER: "Eritrea",
    ME: "Montenegro",
    MD: "Moldova",
    MG: "Madagascar",
    MF: "Saint Martin",
    MA: "Morocco",
    MC: "Monaco",
    UZ: "Uzbekistan",
    MM: "Myanmar",
    ML: "Mali",
    MO: "Macao",
    MN: "Mongolia",
    MH: "Marshall Islands",
    MK: "Macedonia",
    MU: "Mauritius",
    MT: "Malta",
    MW: "Malawi",
    MV: "Maldives",
    MQ: "Martinique",
    MP: "Northern Mariana Islands",
    MS: "Montserrat",
    MR: "Mauritania",
    IM: "Isle of Man",
    UG: "Uganda",
    TZ: "Tanzania",
    MY: "Malaysia",
    MX: "Mexico",
    IL: "Israel",
    FR: "France",
    IO: "British Indian Ocean Territory",
    SH: "Saint Helena",
    FI: "Finland",
    FJ: "Fiji",
    FK: "Falkland Islands",
    FM: "Micronesia",
    FO: "Faroe Islands",
    NI: "Nicaragua",
    NL: "Netherlands",
    NO: "Norway",
    NA: "Namibia",
    VU: "Vanuatu",
    NC: "New Caledonia",
    NE: "Niger",
    NF: "Norfolk Island",
    NG: "Nigeria",
    NZ: "New Zealand",
    NP: "Nepal",
    NR: "Nauru",
    NU: "Niue",
    CK: "Cook Islands",
    XK: "Kosovo",
    CI: "Ivory Coast",
    CH: "Switzerland",
    CO: "Colombia",
    CN: "China",
    CM: "Cameroon",
    CL: "Chile",
    CC: "Cocos Islands",
    CA: "Canada",
    CG: "Republic of the Congo",
    CF: "Central African Republic",
    CD: "Democratic Republic of the Congo",
    CZ: "Czech Republic",
    CY: "Cyprus",
    CX: "Christmas Island",
    CR: "Costa Rica",
    CW: "Curacao",
    CV: "Cape Verde",
    CU: "Cuba",
    SZ: "Swaziland",
    SY: "Syria",
    SX: "Sint Maarten",
    KG: "Kyrgyzstan",
    KE: "Kenya",
    SS: "South Sudan",
    SR: "Suriname",
    KI: "Kiribati",
    KH: "Cambodia",
    KN: "Saint Kitts and Nevis",
    KM: "Comoros",
    ST: "Sao Tome and Principe",
    SK: "Slovakia",
    KR: "South Korea",
    SI: "Slovenia",
    KP: "North Korea",
    KW: "Kuwait",
    SN: "Senegal",
    SM: "San Marino",
    SL: "Sierra Leone",
    SC: "Seychelles",
    KZ: "Kazakhstan",
    KY: "Cayman Islands",
    SG: "Singapore",
    SE: "Sweden",
    SD: "Sudan",
    DO: "Dominican Republic",
    DM: "Dominica",
    DJ: "Djibouti",
    DK: "Denmark",
    VG: "British Virgin Islands",
    DE: "Germany",
    YE: "Yemen",
    DZ: "Algeria",
    US: "United States",
    UY: "Uruguay",
    YT: "Mayotte",
    UM: "United States Minor Outlying Islands",
    LB: "Lebanon",
    LC: "Saint Lucia",
    LA: "Laos",
    TV: "Tuvalu",
    TW: "Taiwan",
    TT: "Trinidad and Tobago",
    TR: "Turkey",
    LK: "Sri Lanka",
    LI: "Liechtenstein",
    LV: "Latvia",
    TO: "Tonga",
    LT: "Lithuania",
    LU: "Luxembourg",
    LR: "Liberia",
    LS: "Lesotho",
    TH: "Thailand",
    TF: "French Southern Territories",
    TG: "Togo",
    TD: "Chad",
    TC: "Turks and Caicos Islands",
    LY: "Libya",
    VA: "Vatican",
    VC: "Saint Vincent and the Grenadines",
    AE: "United Arab Emirates",
    AD: "Andorra",
    AG: "Antigua and Barbuda",
    AF: "Afghanistan",
    AI: "Anguilla",
    VI: "U.S. Virgin Islands",
    IS: "Iceland",
    IR: "Iran",
    AM: "Armenia",
    AL: "Albania",
    AO: "Angola",
    AQ: "Antarctica",
    AS: "American Samoa",
    AR: "Argentina",
    AU: "Australia",
    AT: "Austria",
    AW: "Aruba",
    IN: "India",
    AX: "Aland Islands",
    AZ: "Azerbaijan",
    IE: "Ireland",
    ID: "Indonesia",
    UA: "Ukraine",
    QA: "Qatar",
    MZ: "Mozambique"
  };
}
