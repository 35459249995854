import { withPortal } from "../../hoc";

import "./Loader.scss";

export function Loader({ message, container, type = "normal", size = "normal" }) {
  if (type !== "normal") {
    return (
      <div className={`loading loading--${type}`} data-size={size}>
        <div className="loading__data">
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  }

  return (
    <div className={`loader${container ? " loader__container" : ""}`}>
      <div className="loader__spinner" />
      <p className="loader__text">{message}</p>
    </div>
  );
}

const LoaderPortal = withPortal(Loader, "#root-loader");

export default LoaderPortal;
