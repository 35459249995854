import React, { useState } from "react";
import { Link } from "react-router-dom";
import { eraseLocalStorage } from "../common/Functions";
import { signOut } from "../common/services/auth";
import { useAppContext } from "../context/AppContext";

import "../styles/components/Sidebar.scss";

/* Assets */
import SettingsIcon from "../assets/images/icons/settings-white.svg";
import AfficoIcon from "../assets/images/icons/affico-white.svg";
import PaysysIcon from "../assets/images/icons/paysys-white.svg";
// import TicketsIcon from "../assets/images/icons/tickets-white.svg";
// import LeadsIcon from "../assets/images/icons/leads-white.svg";
// import ClientsIcon from "../assets/images/icons/clients-white.svg";
// import ComplianceIcon from "../assets/images/icons/compliance-white.svg";
import PermissionsIcon from "../assets/images/icons/permissions-white.svg";
import LogOutIcon from "../assets/images/icons/signout-white.svg";
import MenuIcon from "../assets/images/icons/menu-white.svg";
import CloseIcon from "../assets/images/icons/close-aqua.svg";
import ComplianceIcon from "../assets/images/icons/compliance-white.svg";
import AdvancedSettingsIcon from "../assets/images/icons/advanced-settings-white.svg";
import SupportIcon from "../assets/images/icons/support.svg";
import DashboardIcon from "../assets/images/icons/dashboard.svg";

import Language from "./Language";
import MenuLink from "./MenuLink";
import Theme from "./Theme";

export default function Sidebar(props) {
  const [{ user, sessionId, boot, TwoFAEnable }, dispatch] = useAppContext();
  const [mobile, setMobile] = useState(false);

  return (
    <>
      <nav className="sidebar">
        <article className="sidebar__profile">
          <Link to="/welcome" className="sidebar__picture-container">
            <img
              className="sidebar__picture"
              src={user.profileImage}
              alt={`${user.firstName} ${user.lastName}`}
            />
          </Link>
          <Link to="/settings" className="sidebar__settings">
            <img
              src={SettingsIcon}
              alt="Settings"
              className="sidebar__settings-icon"
            />
          </Link>
        </article>
        <h2 className="sidebar__name">
          {boot.login.welcome}
          <br />
          {user.firstName}
        </h2>
        <h3 className="sidebar__position">{user.position}</h3>
        {TwoFAEnable ? (
          <ul className="sidebar__list">
            <li className="sidebar__item">
              <MenuLink
                to="/affico"
                permission="sPpFdKPt5aZfrQbA58VAp0eSCCwZbFHOlOjocoQNB8ed3oHPZtO6D3xlCRgVPDBp"
                icon={AfficoIcon}
                label="Affico"
                sidebar
              />
            </li>
            <li className="sidebar__item">
              <MenuLink
                to="/paysys"
                permission="zIXBTgeWF6FQ9GhRaiaffs1ecmiKY7rZFgEpngkTcif96CrEDPxmkizF3bzIrGAU"
                icon={PaysysIcon}
                label="Paysys"
                sidebar
              />
            </li>
            <li className="sidebar__item">
              <MenuLink
                to="/compliance"
                permission="4EgD4YoFMRqfLmp8CxjUuUhwN0wqdq8vTyGJwZEV0IYkn3eylMg9xUOIdc9cjGek"
                icon={ComplianceIcon}
                label="Compliance"
                sidebar
              />
            </li>
            <li className="sidebar__item">
              <MenuLink
                to="/permissions"
                permission="Gfr72984m1jQH81LP3dZBndjkrVdNIbccXkkvfVU8P6yrMtHK9Pqr7v73QcWE7Xv"
                icon={PermissionsIcon}
                label={boot.permissions.title}
                sidebar
              />
            </li>
            <li className="sidebar__item">
              <MenuLink
                to="/advanced/settings"
                permission="SJfQMrmJf1tkEl5lzSOMbtqyxYRjSGo3LlMnuZgcKegBvO7OZbXc85a96Y6Gpejt"
                icon={AdvancedSettingsIcon}
                label="Advanced Settings"
                sidebar
              />
            </li>
            <li className="sidebar__item">
              <MenuLink
                to="/support"
                permission="zbBybdrmf1mkirCpW6MFtwMjaUAwYBOlZYIYtbSIpwIpJIwCNLvlkfOvpOmTaZUc"
                icon={SupportIcon}
                label="Support"
                sidebar
              />
            </li>
            <li className="sidebar__item">
              <MenuLink
                to="/dashboard"
                permission="BjmLMZ2S9HV5epRBNWgqNINcgumF0Q31iOoAK0gBgDaVRzxFY4HuwEWoNZayQu6U"
                icon={DashboardIcon}
                label="Dashboard"
                sidebar
              />
              <li className="sidebar__item">
                <MenuLink
                  to="/data"
                  permission="AwllWFlEvxRGqZxEylyCfvw4tCh01kiPaaHEfscKvHMAYpf57WSSSrHfq40TwLI5"
                  icon={SupportIcon}
                  label="Data"
                  sidebar
                />
              </li>
            </li>
            {/* <li className="sidebar__item">
              <img src={TicketsIcon} alt="" className="sidebar__isologo" />
              Tickets
            </li>
            <li className="sidebar__item">
              <img src={LeadsIcon} alt="" className="sidebar__isologo" />
              Leads
            </li>
            <li className="sidebar__item">
              <img src={ClientsIcon} alt="" className="sidebar__isologo" />
              {boot.clients.title}
            </li>
            <li className="sidebar__item">
              <img src={ComplianceIcon} alt="" className="sidebar__isologo" />
              Compliance
            </li>
          */}
          </ul>
        ) : null}
        <Theme />
      </nav>
      <nav className="dock">
        <Language mobile function={props.function} />
        <button
          onClick={async () => {
            await signOut({ token: sessionId });
            dispatch({ type: "LOGOUT" });
            eraseLocalStorage();
          }}
          className="dock__button"
        >
          <img src={LogOutIcon} alt="" className="dock__button-icon" />
        </button>
        <button onClick={() => setMobile(true)} className="dock__button">
          <img src={MenuIcon} alt="" className="dock__button-icon" />
        </button>
      </nav>
      {mobile && (
        <nav className="mobile">
          <button onClick={() => setMobile(false)} className="mobile__close">
            <img src={CloseIcon} className="mobile__close-icon" alt="Close" />
          </button>
          {TwoFAEnable ? (
            <ul className="mobile__list">
              <li className="mobile__item">
                <MenuLink
                  to="/affico"
                  permission="sPpFdKPt5aZfrQbA58VAp0eSCCwZbFHOlOjocoQNB8ed3oHPZtO6D3xlCRgVPDBp"
                  icon={AfficoIcon}
                  label="Affico"
                  small
                />
              </li>
              <li className="mobile__item">
                <MenuLink
                  to="/paysys"
                  permission="zIXBTgeWF6FQ9GhRaiaffs1ecmiKY7rZFgEpngkTcif96CrEDPxmkizF3bzIrGAU"
                  icon={PaysysIcon}
                  label="Paysys"
                  small
                />
              </li>
              <li className="mobile__item">
                <MenuLink
                  to="/compliance"
                  permission="4EgD4YoFMRqfLmp8CxjUuUhwN0wqdq8vTyGJwZEV0IYkn3eylMg9xUOIdc9cjGek"
                  icon={ComplianceIcon}
                  label="Compliance"
                  small
                />
              </li>
              <li className="mobile__item">
                <MenuLink
                  to="/permissions"
                  permission="Gfr72984m1jQH81LP3dZBndjkrVdNIbccXkkvfVU8P6yrMtHK9Pqr7v73QcWE7Xv"
                  icon={PermissionsIcon}
                  label={boot.permissions.title}
                  small
                />
              </li>
              <li className="mobile__item">
                <MenuLink
                  to="/advanced/settings"
                  permission="SJfQMrmJf1tkEl5lzSOMbtqyxYRjSGo3LlMnuZgcKegBvO7OZbXc85a96Y6Gpejt"
                  icon={AdvancedSettingsIcon}
                  label="Advanced Settings"
                  small
                />
              </li>
              <li className="mobile__item">
                <MenuLink
                  to="/support"
                  permission="zbBybdrmf1mkirCpW6MFtwMjaUAwYBOlZYIYtbSIpwIpJIwCNLvlkfOvpOmTaZUc"
                  icon={SupportIcon}
                  label="Support"
                  small
                />
              </li>
              <li className="mobile__item">
                <MenuLink
                  to="/dashboard"
                  permission="BjmLMZ2S9HV5epRBNWgqNINcgumF0Q31iOoAK0gBgDaVRzxFY4HuwEWoNZayQu6U"
                  icon={DashboardIcon}
                  label="Dashboard"
                  small
                />
              </li>
            </ul>
          ) : null}
          <Theme />
        </nav>
      )}
    </>
  );
}
