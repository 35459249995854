import { useState } from "react";
import swal from "sweetalert";
import { useAppContext } from "../../context/AppContext";
import { moveCustomer } from "../../common/services/move";

export default function useAfficoMoveCustomer({ token }) {
  const [{ boot }] = useAppContext();
  const [customer, setCustomer] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleOpen = (ftd, newOpen) => {
    setCustomer(ftd);
    if (typeof newOpen === "boolean") {
      setOpen(newOpen);
    } else {
      setOpen((prev) => !prev);
    }
  };

  const toggleLoading = (newLoading) => {
    if (typeof newLoading === "boolean") {
      setLoading(newLoading);
    } else {
      setLoading((prev) => !prev);
    }
  };

  const move = async (event) => {
    try {
      event.preventDefault();
      const { business } = Object.fromEntries(new FormData(event.target).entries());
      if (!business) {
        await swal({
          title: boot.affico["move-required"],
          text: boot.affico["move-required-text"],
          icon: "warning",
        });
        return;
      }

      const accept = await swal({
        title: boot.affico["sure-move"],
        text: boot.affico["sure-move-text"],
        icon: "warning",
        dangerMode: true,
        buttons: true,
      });

      if (!customer || !accept) return;
      toggleOpen(customer, false);
      setLoading(true);

      await moveCustomer({
        tpid: customer.tpId,
        businessId: business,
        token,
      });
      await swal({
        title: boot.affico["ftd-moved"],
        text: boot.affico["ftd-moved-text"],
        icon: "success",
      });
      toggleOpen(null, false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    customer,
    open,
    loading,
    move,
    toggleOpen,
    toggleLoading,
  };
}
