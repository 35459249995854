import React, { useState, useEffect, Fragment } from 'react'
import { getBaseApi } from "../common/Routes";
import {
  eraseLocalStorage,
  convertToQuery,
} from "../common/Functions";
import swal from "@sweetalert/with-react";
import { recomposeColor } from '@material-ui/core';

const DEFAULT_PSPCONFIG = { method: [] };

export default function Payments({props, psps, customer, tpId, setAux, setLoading}) {
  const [manualTrans, setManualTrans] = useState({});
  const [pspConfig, setPspConfig] = useState(DEFAULT_PSPCONFIG);
  const [blockFields, setBlockFields] = useState(false);

  const availablePsps = (psps) => {
    return psps.filter((psp) => psp.settings)
      .map((psp) => ({...psp, settings: JSON.parse(psp.settings)}))
      .filter((psp) => psp.settings.available)
      .filter((psp) => psp.settings?.availableCountries?.some((country) => country === customer.country))
      .filter((psp) => !psp.settings.availableBusinessUnits || psp.settings.availableBusinessUnits.some((bu) => bu === customer.idCrmBusinessUnit));
  };

  const onSubmitTransaction = (e) => {
    e.preventDefault()
    console.log(`TPID: ${tpId}`, manualTrans);
    swal({
      icon: "warning",
      title: "Are you sure you want to continue?",
      text: "",
      buttons: true,
    }).then((willInserted) => {
      if (willInserted) {
        setLoading(true)
        fetch(
            getBaseApi() +
              "/manage/Transaction",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("sessionId"),
                "Accept-Language": localStorage.getItem("moisesLang")
              },
              body: JSON.stringify({ tpId, ...manualTrans, transactionCat: 1, payment: "PSP" }),
            }
          ).then((res) => res.json())
          .then((response) => {
            if (response.result) {
              if (!response.data) {
                swal({
                  icon: "success",
                  title: "Success!",
                  text: "The transaction was inserted successfully!",
                });
              } else {
                swal({
                  icon: "success",
                  title: "Success!",
                  content: (
                    <span className="swal__link">
                      {response.data.map((k) => (
                        <a
                          href={k.link}
                          key={k.label}
                          target="_blank"
                        >
                          {k.label}
                        </a>
                      ))}
                    </span>
                  ),
                });
              }
              setAux((prevAux) => !prevAux);
            } else if (response.error === "EISN") {
              eraseLocalStorage();
            } else {
              swal({
                icon: "error",
                title: props.boot.swal.errorTitle,
                text: response.error,
              });
            }
            setLoading(false)
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: props.boot.swal.errorTitle,
              text: error.message,
            });
            setLoading(false)
          });
      }
    });
  };

  
  const onHandleManualTransaction = (prop) => (event) => {
    const data = manualTrans.data ?? {};
    setManualTrans({ ...manualTrans, data: { ...data, [prop]: event.target.value} });
  };

  const antifraudPayU = async () => {
    /* antifraude para PayU */
    const params = {
      action: "device",
      data: {
        sessionId: localStorage.getItem("sessionId")
      }
    };
    const response = await fetch("https://webservicesnt.org/phpPunkaso/PayU/payu.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(params)
    });
    const { result, data: deviceSessionId, error } = await response.json();
    const data = manualTrans.data ?? {};
    setManualTrans({ ...manualTrans, data: { sessionId: deviceSessionId } });
    const script = document.createElement("script");
    script.src = `https://maf.pagosonline.net/ws/fp/tags.js?id=${deviceSessionId}80200`;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  };

  useEffect(() => {
    if (!manualTrans.pspCat) return;
    (async() => {
      let url;
      switch (manualTrans?.pspCat) {
        case 4: case 54:
          url = `https://client.orotrader.com/api/configuration/`;
          setManualTrans({ ...manualTrans, data: {}});
        break;
        case 49: case 50: case 52:
          url = `https://client.thetradingclub.net/api/configurations/`;
          antifraudPayU();
        break;
        case 53:
          url = `https://clientzone.fxbravo.com/api/config/`;
          setManualTrans({ ...manualTrans, data: {}});
        break;
        case 56:
          url = `https://abogadosnu.com/api/config/`;
          setManualTrans({ ...manualTrans, data: {}});
        break;
        default:
      }
      if (url) {
        try {
          setBlockFields(true);
          const request = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Authorization: "Bearer " + localStorage.getItem("sessionId"),
              // "Accept-Language": localStorage.getItem("moisesLang")
            },
            body: JSON.stringify({ tp_id: tpId, psp_id: manualTrans?.pspCat}),
          });
          const data = await request.json();
          const validationArray = Array.isArray(data);
          const arrayOrObject = validationArray ? data : Object.keys(data);
          const config =  arrayOrObject.filter((psp) => `${validationArray ? psp.id : data[psp].id}` === `${manualTrans?.pspCat}`);
          const configData = validationArray ? config.shift() : data[config] ?? {method: []};
          setPspConfig({
            ...configData,
            method: configData.method ? configData.method[customer.country] : [],
            currency: configData.currency ? configData.currency[customer.country] : []
          });
          setBlockFields(false);
        } catch (error) {
          console.error(error.message);
          setBlockFields(false);
        }
      } else {
        setPspConfig(DEFAULT_PSPCONFIG);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manualTrans.pspCat, manualTrans.action])

  return (
    <form onSubmit={(e) => onSubmitTransaction(e)}>
      <>
        <h3 className="user__title">
          {props.boot.paysys.manualPay}
        </h3>
        <p className="user__paragraph">
          {props.boot.paysys.alreadyDeposit}
        </p>
        <div className="user__grid user__grid--fixed user__grid--two">
          <article className="user__item">
            <label
              className="user__label user__label--upper"
              htmlFor=""
            >
              {props.boot.paysys.method}
            </label>
            <select
              name="psp"
              id="psp"
              className="user__select"
              onChange={(event) => setManualTrans({
                pspCat: psps.filter((psp) => `${psp.id}` === event.target.value).shift()?.id
              })}
              required
            >
              <option defaultValue hidden value="">
                {props.boot.paysys.method}
              </option>
              {availablePsps(psps).map((psp, i) => (
                <option key={i} defaultValue value={psp.id}>
                  {psp.name}
                </option>
              ))}
            </select>
          </article>
          <article className="user__item" data-disabled={blockFields}>
            <label
              className="user__label user__label--upper"
              htmlFor=""
            >
              {props.boot.paysys.amount}{" [USD]"}
            </label>
            <input
              className="user__input"
              type="text"
              id="amount"
              placeholder={pspConfig?.minAmount || customer.minAmount}
              onChange={(event) => setManualTrans({ ...manualTrans, amount: event.target.value })}
              disabled={blockFields}
              required
            />
          </article>
          <article className="user__item" data-disabled={blockFields}>
            <label
              className="user__label user__label--upper"
              htmlFor=""
            >
              {props.boot.paysys.userDocumentNumber}
            </label>
            <input
              className="user__input"
              type="text"
              id="userDocumentNumber"
              onChange={(event) => setManualTrans({ ...manualTrans, userDocumentNumber: event.target.value })}
              disabled={blockFields}
              required
            />
          </article>
          <article className="user__item" data-disabled={blockFields}>
            <label
              className="user__label user__label--upper"
              htmlFor=""
            >
              {props.boot.paysys.currency}
            </label>
            <select
              name="currency"
              id="currency"
              className="user__select"
              onChange={(event) => setManualTrans({ ...manualTrans, currency: event.target.value })}
              disabled={blockFields}
              required
            >
              <option defaultValue hidden value="">
                {props.boot.paysys.currency}
              </option>
              {pspConfig?.currency?.map((cur, i) => (
                <option key={i} defaultValue value={cur}>
                  {cur}
                </option>
              ))}
            </select>
          </article>
          {pspConfig?.method.length > 0 && (
            <>
              <article className="user__item" data-disabled={blockFields}>
                <label
                  className="user__label user__label--upper"
                  htmlFor=""
                >
                  {props.boot.paysys.method}
                </label>
                <select
                  name="methods"
                  id="methods"
                  className="user__select"
                  onChange={(event) => {
                    const data = manualTrans.data ?? {};
                    const action = event.target.value;
                    setManualTrans({
                      ...manualTrans,
                      action,
                      data: {...data, action }
                    })
                  }}
                  disabled={blockFields}
                  required
                >
                  <option defaultValue hidden value="">
                    {props.boot.paysys.method}
                  </option>
                  {pspConfig?.method?.map((m, i) => (
                    <option key={`${i}${m.value}`} defaultValue value={m.value}>
                      {m.label}
                    </option>
                  ))}
                </select>
              </article>
              <article></article>
              {manualTrans?.action && pspConfig?.form[manualTrans?.action] && pspConfig?.form[manualTrans?.action].map((e, i) => (
                <article className="user__item" key={`${i}${e.name}`} data-disabled={blockFields}>
                  <label
                    className="user__label user__label--upper"
                    htmlFor=""
                  >
                    {`${e.label}`}
                  </label>
                  {e.type === "input" && (
                    <input
                      className="user__input"
                      type={"text"}
                      id={`${e.name}`}
                      name={`${e.name}`}
                      placeholder={`${e.placeholder}`}
                      onChange={onHandleManualTransaction(`${e.name}`)}
                      disabled={blockFields}
                      required
                    />
                  )}
                  {e.type === "select" && (
                    <select
                      name={`${e.name}`}
                      id={`${e.name}`}
                      className="user__select"
                      onChange={onHandleManualTransaction(`${e.name}`)}
                      disabled={blockFields}
                      required
                    >
                      <option defaultValue hidden value="">
                        {`${e.placeholder}`}
                      </option>
                      {e?.value?.map((c, i) => (
                        <option key={i} defaultValue value={c.value}>
                          {c.label}
                        </option>
                      ))}
                    </select>
                  )}
                </article>
              ))}
            </>
          )}
        </div>
        <div className="user__actions">
          <button
            type="button"
            className="user__button user__button--cancel"
            onClick={() => props.history.push("/paysys")}
            disabled={blockFields}
          >
            {props.boot.general.cancel}
          </button>
          <button
            type="submit"
            className="user__button user__button--save"
            disabled={blockFields}
          >
            {props.boot.general.pay}
          </button>
        </div>
      </>
    </form>
  )
}
