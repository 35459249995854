import React, { useState } from "react";
import { useAppContext } from "../context/AppContext";

import "../styles/components/Language.scss";

/* Assets */
import LangIcon from "../assets/images/icons/language-blue.svg";

export default function Language({ parent, mobile, ...props }) {
  const [{ lang }, dispatch] = useAppContext();
  const [showLang, setShowLang] = useState(false);

  const handleChangeLanguage = (language) => {
    if (props.function) props.function(language);
    dispatch({ type: "CHANGE_LANGUAGE" });
  };

  const handleMobile = () => {
    if (mobile) {
      handleChangeLanguage(lang === "es" ? "en" : "es");
    } else {
      setShowLang(!showLang);
    }
  }

  return (
    <div className={`language${mobile ? " language--mobile" : ""}`}>
      <button
        className="language__selector"
        onClick={handleMobile}
        style={parent && { color: "white" }}
      >
        <img src={LangIcon} className="language__icon" alt="Language" />
        {!mobile && (
          lang === "es" ? "Español" : "English"
        )}
      </button>
      {(showLang && !mobile) && (
        <article onBlur={() => setShowLang(false)} className="language__options">
          <button
            onClick={() => handleChangeLanguage("es")}
            className="language__button"
            disabled={lang === "es"}
          >
            Español
          </button>
          <button
            onClick={() => handleChangeLanguage("en")}
            className="language__button"
            disabled={lang === "en"}
          >
            English
          </button>
        </article>
      )}
    </div>
  );
}
