import React, { useEffect, useState, useCallback } from 'react';
import { DatePicker } from '@material-ui/pickers';
import format from "date-fns/format";
import { getCountries } from '../common/Boot';
import { convertToQuery, getDateRange } from '../common/Functions';
import { getBaseApi } from '../common/Routes';
import Header from '../components/Header';
import Loader from '../components/Loader';
import { useAppContext } from '../context/AppContext';
import { Bar, Doughnut } from 'react-chartjs-2';

import CancelIcon from "../assets/images/icons/cancel-blue.svg";
import "../styles/pages/Dashboard.scss";

const DEFAULT_COLORS = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
];

const OPTIONSBARCHARTJS = {
  scales: {
    y: {
      stacked: true,
      ticks: {
        beginAtZero: true
      }
    },
    x: {
      stacked: true
    }
  }
};

const DEFAULT_BAR_BASE = {
  label: "",
  labels: [],
  data: []
};

export default function Dashboard(props) {
  const [{ boot, sessionId, lang }] = useAppContext();
  const [tab, setTab] = useState({ id: 0, label: "affico" });
  const [typeDate, setTypeDate] = useState(true);
  const [params, setParams] = useState({
    dateType: "Last Month",
    dateStart: new Date(),
    dateEnd: new Date(),
    country: "ALL",
    affiliate: "ALL",
    businessUnit: "ALL",
    range: 1
  });
  const [activeFilters, setActiveFilters] = useState({});
  const [catalogues, setCatalogues] = useState({});
  const [playerValues, setPlayerValues] = useState({ ...DEFAULT_BAR_BASE, label: "PLAYER VALUE"});
  const [reDeposits, setReDeposits] = useState({ ...DEFAULT_BAR_BASE, label: "REDEPOSITS"});
  const [cftds, setCftds] = useState({ ...DEFAULT_BAR_BASE, label: "CFTDs"});
  const [leadStatus, setLeadStatus] = useState({ ...DEFAULT_BAR_BASE, label: "LEAD STATUS"});
  const [loading, setLoading] = useState({
    Catalogues: true,
    playerValue: false,
    information: false,
    generalStatus: false
  });
  
  const barData = useCallback((dataBar) => {
    const {label, labels, data} = dataBar;
    return {
      labels,
      datasets: [
        {
          label,
          data,
          backgroundColor: DEFAULT_COLORS.map((color) => `${color.substring(0, color.length-2)}0.2)`),
          borderColor: DEFAULT_COLORS,
          borderWidth: 1,
        },
      ],
    }
  }, []);

  const handleChange = (e) => {
    if (["country", "affiliate", "businessUnit"].includes(e.target.name)) {
      let actFilters = activeFilters[e.target.name] ? activeFilters[e.target.name] : [];
      const sel = document.getElementById(e.target.name);
      const label = sel.options[sel.selectedIndex].text;
      if (actFilters.map((e) => e.id).indexOf(e.target.value) >= 0) {
        return;
      }
      if (e.target.value === "ALL") {
        actFilters = [];
      } else {
        actFilters.push({
          id: e.target.value,
          label,
        });
      }
      setActiveFilters({ ...activeFilters, [e.target.name]: actFilters });
      setParams({ ...params, [e.target.name]: !actFilters.length ? "ALL" : actFilters.map((p) => p.id) });
    } else {
      setParams({ ...params, [e.target.name]: e.target.value });
    }
  };

  const fetchRequest = useCallback(async (action) => {
    try {
      setLoading((prevLoading) => ({...prevLoading, [action]: "Loading" }));
      const dataParams = {
        ...params,
        action,
        dateStart: format(params.dateStart, "yyyy-MM-dd"),
        dateEnd: format(params.dateEnd, "yyyy-MM-dd")
      }
      const response = await fetch(`${getBaseApi()}/manage/Dashboard${convertToQuery(dataParams)}`, {
        headers: {
          Authorization: `Bearer ${sessionId}`,
          "Accept-Language": lang
        }
      });
      const {result, error, data} = await response.json();
      setLoading((prevLoading) => ({...prevLoading, [action]: false }));
      if (result) {
        return data;
      } else {

      }
    } catch(err) {
      setLoading((prevLoading) => ({...prevLoading, [action]: false }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, params, sessionId]);

  const getInfo = useCallback(async () => {
    const getPlayerValue = async () => {
      setPlayerValues({ ...DEFAULT_BAR_BASE, label: "PLAYER VALUE"});
      const request = await fetchRequest("playerValue");
      const playValue = request.playerValue.filter((aff) => aff.playValue);
      setPlayerValues((prevPlayerValues) => ({
        ...prevPlayerValues,
        labels: playValue.map((aff) => aff.affiliateName),
        data: playValue.map((aff) => aff.playValue)
      }));
    };
    
    const getInformation = async () => {
      setReDeposits({ ...DEFAULT_BAR_BASE, label: "REDEPOSITS"});
      setCftds({ ...DEFAULT_BAR_BASE, label: "CFTDs"});
      const request = await fetchRequest("information");
      const rDeposits = request.information.filter((aff) => aff.reDeposits);
      const cFtDS = request.information.filter((aff) => aff.cFtd);
      setReDeposits((prevReDeposits) => ({
        ...prevReDeposits,
        labels: rDeposits.map((aff) => aff.affiliateName),
        data: rDeposits.map((aff) => aff.reDeposits)
      }));
      setCftds((prevCftds) => ({
        ...prevCftds,
        labels: cFtDS.map((aff) => aff.affiliateName),
        data: cFtDS.map((aff) => aff.cFtd)
      }));
    };
    
    const getGeneralStatus = async () => {
      setLeadStatus({ ...DEFAULT_BAR_BASE, label: "LEAD STATUS"});
      const request = await fetchRequest("generalStatus");
      const lStatus = request.generalStatus.filter((Status) => Status.totalLeads);
      setLeadStatus((prevLeadStatus) => ({
        ...prevLeadStatus,
        labels: lStatus.map((status) => status.name),
        data: lStatus.map((status) => status.totalLeads)
      }));
    };

    getPlayerValue();
    getInformation();
    getGeneralStatus();
  }, [fetchRequest]);

  const onSubmitAfficoSearch = (e) => {
    e.preventDefault();
    getInfo();
  };

  const deleteActiveFilter = (array, index) => {
    activeFilters[array].splice(index, 1);
    if (activeFilters[array].length === 0) {
      document.getElementById(array).value = "ALL";
      activeFilters[array] = [];
    }
    setActiveFilters({
      ...activeFilters,
    });
    setParams({ ...params, [array]: !activeFilters[array].length ? "ALL" : activeFilters[array].map((p) => p.id) });
  };

  useEffect(() => {
    (async () => {
      const request = await fetchRequest("Catalogues");
      setCatalogues(request);
      getInfo();
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barData]);

  return (
    <main className="general" style={{ paddingLeft: "30px" }}>
      {loading.Catalogues ? (
        <Loader message={loading.Catalogues} />
      ) : null}
      <Header boot={boot} title={`Dashboard`} />
      <section className="dashboard">
     {/*    <div className="tabs">
          <button
            onClick={() => setTab({ id: 0, label: "affico" })}
            className={`tabs__switch${tab.id === 0 ? " tabs__switch--active" : ""}`}
          >
            Affico
          </button>
        </div> */}
        <div className="dashboard__box">
          {tab.label === "affico" && (
            <div className="dashboard__filters">
              <form onSubmit={onSubmitAfficoSearch}>
                <div className="dashboard__grid">
                  <article className="hidden__item">
                    <label className="hidden__label" htmlFor="">
                      {props.boot.table.country}
                    </label>
                    <select
                      name="country"
                      id="country"
                      className="hidden__select"
                      defaultValue={
                        params.country === "ALL"
                          ? params.country
                          : params.country[params.country.length - 1]
                      }
                      onChange={handleChange}
                    >
                      <option defaultValue value="ALL">
                        {props.boot.table.all}
                      </option>
                      {catalogues?.countries?.map((c, i) => (
                        <option key={i} defaultValue value={c.name}>
                          {getCountries()[c.name]}
                        </option>
                      ))}
                    </select>
                  </article>
                  <article className="hidden__item">
                    <label className="hidden__label" htmlFor="">
                      {props.boot.table.affiliate}
                    </label>
                    <select
                      name="affiliate"
                      id="affiliate"
                      className="hidden__select"
                      defaultValue={
                        params.affiliate === "ALL"
                          ? params.affiliate
                          : params.affiliate[
                            params.affiliate.length - 1
                          ]
                      }
                      onChange={handleChange}
                    >
                      <option defaultValue hidden value="">
                        {props.boot.table.affiliate}
                      </option>
                      <option defaultValue value="ALL">
                        {props.boot.table.all}
                      </option>
                      {catalogues?.affiliates?.map((a, i) => (
                        <option key={i} defaultValue value={a.id}>
                          {a.name}
                        </option>
                      ))}
                    </select>
                  </article>
                  <article className="hidden__item">
                    <label className="hidden__label" htmlFor="">
                      {props.boot.table.businessUnit}
                    </label>
                    <select
                      name="businessUnit"
                      id="businessUnit"
                      className="hidden__select"
                      defaultValue={
                        params.businessUnit === "ALL"
                          ? params.businessUnit
                          : params.businessUnit[
                            params.businessUnit.length - 1
                          ]
                      }
                      onChange={handleChange}
                      required
                    >
                      <option defaultValue hidden value="">
                        {props.boot.table.businessUnit}
                      </option>
                      <option defaultValue value="ALL">
                        {props.boot.table.all}
                      </option>
                      {catalogues?.businessUnits?.map((a, i) => (
                        <option key={i} defaultValue value={a.id}>
                          {a.name}
                        </option>
                      ))}
                    </select>
                  </article>
                  <article className="hidden__item">
                    <label className="hidden__label" htmlFor="">
                      {props.boot.dashboard.date}
                    </label>
                    <select
                      name="position"
                      id="position"
                      className="hidden__select"
                      defaultValue={params.dateType}
                      onChange={(e) => {
                        const { dateStart, dateEnd } = getDateRange(
                          e.target.value
                        );
                        setParams({ ...params, dateStart, dateEnd });
                        setTypeDate(e.target.value !== "Range");
                      }}
                      required
                    >
                      <option defaultValue hidden value="">
                        {props.boot.dashboard.date}
                      </option>
                      {[
                        "Today",
                        "Yesterday",
                        "This Week",
                        "Last Week",
                        "This Month",
                        "Last Month",
                        "This Year",
                        "Range",
                      ].map((dateType, i) => (
                        <option key={i} defaultValue value={dateType}>
                          {dateType}
                        </option>
                      ))}
                    </select>
                  </article>
                  <article className="hidden__item">
                    <DatePicker
                      disableToolbar
                      disabled={typeDate}
                      style={{ width: "100%" }}
                      format="yyyy-MM-dd"
                      label={props.boot.affico.dateStart}
                      value={params.dateStart}
                      onChange={(date) =>
                        setParams({ ...params, dateStart: date })
                      }
                    />
                  </article>
                  <article className="hidden__item">
                    <DatePicker
                      disableToolbar
                      disabled={typeDate}
                      style={{ width: "100%" }}
                      format="yyyy-MM-dd"
                      label={props.boot.affico.dateEnd}
                      value={params.dateEnd}
                      onChange={(date) =>
                        setParams({ ...params, dateEnd: date })
                      }
                    />
                  </article>
                  <article className="hidden__item">
                    <label className="hidden__label" htmlFor="">
                      {props.boot.dashboard.range}
                    </label>
                    <select
                      name="range"
                      id="range"
                      className="hidden__select"
                      defaultValue={params.range}
                      onChange={handleChange}
                      required
                    >
                      <option defaultValue hidden value="">
                        {props.boot.dashboard.range}
                      </option>
                      {[1, 2, 3].map((r, i) => (
                        <option key={i} defaultValue value={r}>
                          {`${props.boot.dashboard.range} ${r}`}
                        </option>
                      ))}
                    </select>
                  </article>
                </div>
                <div className="hidden__actions">
                  <button
                    onClick={() => props.history.push("/welcome")}
                    type="button"
                    className="hidden__button hidden__button--cancel"
                  >
                    {props.boot.general.cancel}
                  </button>
                  <button
                    type="submit"
                    className="hidden__button hidden__button--save"
                  >
                    {props.boot.dashboard.applyFilters}
                  </button>
                </div>
                <div className="hidden__activeFilters">
                  {Object.keys(activeFilters).map((type) => (
                    activeFilters[type].map((item, index) => (
                      <button
                        className="filters__button"
                        onClick={() => deleteActiveFilter(type, index)}
                        key={type + item.id}
                      >
                        {type}: {item.label}{" "}
                        <img className="filters__icon" src={CancelIcon} alt="Close" />
                      </button>
                    ))
                  ))}
                </div>
              </form>
            </div>
          )}
        </div>
      </section>
      {!loading.Catalogues && tab.label === "affico" && (
        <>
          <section className="dashboard">
            <div className="dashboard__box">
              {loading.playerValue ? (
                <Loader message={loading.playerValue} container />
              ) : null}
              <h2 className="hidden__title">Player Value</h2>
              <div className="dashboard__graph">
                <Bar data={barData(playerValues)} options={OPTIONSBARCHARTJS} />
              </div>
            </div>
          </section>
          <section className="dashboard">
            <div className="dashboard__box">
              {loading.information ? (
                <Loader message={loading.information} container />
              ) : null}
              <h2 className="hidden__title">Redeposits</h2>
              <div className="dashboard__graph">
                <Bar data={barData(reDeposits)} options={OPTIONSBARCHARTJS} />
              </div>
            </div>
          </section>
          <section className="dashboard">
            <div className="dashboard__box">
              {loading.information ? (
                <Loader message={loading.information} container />
              ) : null}
              <h2 className="hidden__title">CFTD</h2>
              <div className="dashboard__graph">
                <Bar data={barData(cftds)} options={OPTIONSBARCHARTJS} />
              </div>
            </div>
          </section>
          <section className="dashboard">
            <div className="dashboard__box">
              {loading.generalStatus ? (
                <Loader message={loading.generalStatus} container />
              ) : null}
              <h2 className="hidden__title">Lead Status</h2>
              <div className="dashboard__graph">
                <Doughnut data={barData(leadStatus)} plugins={[{legend: { position: "right" }}]} />
              </div>
            </div>
          </section>
        </>
      )}
    </main>
  )
}
