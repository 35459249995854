import React, { useState } from 'react'
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css'
import "../styles/Modal.scss";


export default function Modal({ showModal, setShowModal, file }) {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const extension = file.split(".").pop();
    return (
        <>
            <PureModal

                isOpen={showModal}
                className="modal"
                // width= "80vw"
                closeButton="x"
                onClose={() => {
                    setShowModal(false);
                    return true;
                }}
            >
                <div className={(extension !== "pdf")?"modal__container":"modal__containerPDF"}>
                    {extension !== "pdf" ?
                        <img src={file} className="modal__image"></img> :
                        <div>
                       <iframe id="inlineFrameExample"
                                title="Inline Frame Example"
                                width="100%"
                                height="1000"
                                src={file}
                                scrolling="false"
                                >
                        </iframe>
                        </div>
                      
                    }
                </div>
            </PureModal>;
        </>
    )
}
