import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { es, enUS } from "date-fns/locale";
import { useAppContext } from "../context/AppContext";
import { signOut } from "../common/services/auth.js";

import "../styles/pages/Welcome.scss";

/* Components */
import Language from "../components/Language.jsx";
import MenuLink from "../components/MenuLink";

/* Assets */
import SettingsIcon from "../assets/images/icons/settings-white.svg";
import Logo from "../assets/images/logo.webp";
import AdvancedSettingsIcon from "../assets/images/icons/advanced-settings-white.svg";
import SettingsMobileIcon from "../assets/images/icons/icon-settings-mobile.svg";
import AfficoIcon from "../assets/images/icons/affico-white.svg";
import PaysysIcon from "../assets/images/icons/paysys-white.svg";
import TicketsIcon from "../assets/images/icons/tickets-white.svg";
import LeadsIcon from "../assets/images/icons/leads-white.svg";
import ClientsIcon from "../assets/images/icons/clients-white.svg";
import ComplianceIcon from "../assets/images/icons/compliance-white.svg";
import PermissionsIcon from "../assets/images/icons/permissions-white.svg";
import SignoutIcon from "../assets/images/icons/icon-signout.svg";
import SupportIcon from "../assets/images/icons/support.svg";
import DashboardIcon from "../assets/images/icons/dashboard.svg";

export default function Welcome() {
  const [
    {
      lang,
      sessionId,
      boot: { welcome: boot }
    },
    dispatch
  ] = useAppContext();
  const [hour, setHour] = useState("--:--");
  const day = format(new Date(), "EEEE, dd MMMM", {
    locale: lang === "es" ? es : enUS
  });

  const handleLogout = async () => {
    await signOut({ token: sessionId });
    dispatch({ type: "LOGOUT" });
  };

  useEffect(() => {
    const clock = setInterval(() => {
      setHour(format(new Date(), "HH:mm"));
    }, 1000);

    return () => {
      clearInterval(clock);
    };
  }, []);

  return (
    <main className="welcome">
      <section className="welcome__user">
        <div className="welcome__mobile">
          <img
            src={Logo}
            alt="Logo"
            className="welcome__logo welcome__logo--mobile"
            loading="lazy"
            style={{ filter: "invert(1)" }}
          />
          <article className="welcome__time welcome__time--mobile">
            <h3 className="welcome__hour welcome__hour--mobile">{hour}</h3>
            <p className="welcome__day welcome__day--mobile">{day}</p>
          </article>
          <div className="welcome__mobile-menu">
            <Language mobile />
            <button className="welcome__mobile-link" onClick={handleLogout}>
              <img
                src={SignoutIcon}
                alt="Settings"
                className="welcome__mobile-link-icon"
              />
            </button>
            <Link to="/settings" className="welcome__mobile-link">
              <img
                src={SettingsMobileIcon}
                alt="Settings"
                className="welcome__mobile-link-icon"
              />
            </Link>
          </div>
        </div>
        <article className="welcome__profile">
          <img
            src={localStorage.getItem("profileImage")}
            alt="User name"
            className="welcome__picture"
            loading="lazy"
          />{" "}
          <Link to="/settings" className="welcome__settings">
            <img src={SettingsIcon} alt="Settings" className="welcome__icon" />
          </Link>
        </article>

        <h2 className="welcome__name">
          {boot.greeting} {localStorage.getItem("firstName")}
        </h2>
        <div className="welcome__language">
          <Language parent />
        </div>
        <button className="welcome__logout" onClick={handleLogout}>
          {boot.signout}
        </button>
        <article className="welcome__time">
          <h3 className="welcome__hour">{hour}</h3>
          <p className="welcome__day">{day}</p>
        </article>
      </section>
      <section className="welcome__principal">
        <img src={Logo} alt="Logo" className="welcome__logo" loading="lazy" style={{ filter: "invert(1)" }} />
        <div className="welcome__boxes">
          <MenuLink
            to="/affico"
            permission="sPpFdKPt5aZfrQbA58VAp0eSCCwZbFHOlOjocoQNB8ed3oHPZtO6D3xlCRgVPDBp"
            icon={AfficoIcon}
            label={boot.menuLink1}
          />
          <MenuLink
            to="/paysys"
            permission="zIXBTgeWF6FQ9GhRaiaffs1ecmiKY7rZFgEpngkTcif96CrEDPxmkizF3bzIrGAU"
            icon={PaysysIcon}
            label={boot.menuLink2}
          />
          <MenuLink
            to="/tickets"
            permission="RF5xHjSHSQlwTgO6z2pFazD2GhNpbh3op1fyWA2mqSLWTLk2WAs68fWSjlB5p8nts"
            icon={TicketsIcon}
            label={boot.menuLink3}
          />
          <MenuLink
            to="/advanced/settings"
            permission="SJfQMrmJf1tkEl5lzSOMbtqyxYRjSGo3LlMnuZgcKegBvO7OZbXc85a96Y6Gpejt"
            icon={AdvancedSettingsIcon}
            label={boot.menuLink4}
          />
          <MenuLink
            to="/leads"
            permission="X2wswnw7WidKo5JmD1lu5GOIh0QXC4jHh6whoOlPGisqZz59waxzSp68snHyGWKSs"
            icon={LeadsIcon}
            label={boot.menuLink5}
          />
          <MenuLink
            to="/clients"
            permission="X2wswnw7WidKo5JmD1lu5GOIh0QXC4jHh6whoOlPGisqZz59waxzSp68snHyGWKSs"
            icon={ClientsIcon}
            label={boot.menuLink6}
          />
          <MenuLink
            to="/compliance"
            permission="4EgD4YoFMRqfLmp8CxjUuUhwN0wqdq8vTyGJwZEV0IYkn3eylMg9xUOIdc9cjGek"
            icon={ComplianceIcon}
            label={boot.menuLink7}
          />
          <MenuLink
            to="/permissions"
            permission="Gfr72984m1jQH81LP3dZBndjkrVdNIbccXkkvfVU8P6yrMtHK9Pqr7v73QcWE7Xv"
            icon={PermissionsIcon}
            label={boot.menuLink8}
          />
          <MenuLink
            to="/support"
            permission="zbBybdrmf1mkirCpW6MFtwMjaUAwYBOlZYIYtbSIpwIpJIwCNLvlkfOvpOmTaZUc"
            icon={SupportIcon}
            label={boot.menuLink9}
          />
          <MenuLink
            to="/dashboard"
            permission="BjmLMZ2S9HV5epRBNWgqNINcgumF0Q31iOoAK0gBgDaVRzxFY4HuwEWoNZayQu6U"
            icon={DashboardIcon}
            label={boot.menuLink10}
          />
          <MenuLink
            to="/data"
            permission="AwllWFlEvxRGqZxEylyCfvw4tCh01kiPaaHEfscKvHMAYpf57WSSSrHfq40TwLI5"
            icon={SupportIcon}
            label="Data"
          />
        </div>
      </section>
    </main>
  );
}
