import React, { useEffect, useState, useCallback } from "react";
import swal from "sweetalert";
import {
  eraseLocalStorage,
} from "../common/Functions";
import { getBaseApi } from "../common/Routes";
import { useAppContext } from "../context/AppContext";

import "../styles/Settings.scss";

// import example from "../assets/images/examples/profile.png";
import EditIcon from "../assets/images/icons/edit-white.svg";

import Header from "../components/Header.jsx";
import Sidebar from "../components/Sidebar.jsx";

export default function Settings(props) {
  const [{ TwoFAEnable, boot: { welcome: boot } }, dispatch] = useAppContext();
  const [loading, setLoading] = useState(false);
  const [qrCode, setQrCode] = useState();
  const [recoveryKey, setRecoveryKey] = useState();

  const uploadProfileImage = (e) => {
    let data = new FormData();
    data.append("file", e.target.files[0]);
    data.append('action', 'image');
    setLoading(true)
    fetch(getBaseApi() + "/manage/Users", {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("sessionId"),
        "Accept-Language": localStorage.getItem("moisesLang")
      },
      body: data,
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.result) {
          swal({
            icon: "success",
            title: "Success!",
            text: "The user were uploaded successfuly!",
          });
          localStorage.setItem("profileImage", response.data.url)
        } else {
          swal({
            icon: "error",
            title: props.boot.swal.errorTitle1r,
            text: response.error,
          });
        }
        setLoading(false)
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle1r,
          text: error.message,
        });
        setLoading(false)
      });
  };
  const handleActive2FA = (e) => {
    e.preventDefault();
    fetch(getBaseApi() + "/manage/Users", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("sessionId"),
        "Accept-Language": localStorage.getItem("moisesLang")
      },
      body: JSON.stringify({action: "2FA", code: e.target[0].value})
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.result) {
          setRecoveryKey(response.data);
          setQrCode(null);
          dispatch({ type: "TOGGLE_2FA", payload: true });
        } else if (response.error === "EISN") {
          eraseLocalStorage();
        } else {
          swal({
            icon: "error",
            title: props.boot.swal.errorTitle1,
            text: response.error,
          });
        }
        setLoading(false)
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle1,
          text: error.message,
        });
        setLoading(false)
      });
  }

  const delete2FA = () => {
    fetch(getBaseApi() + "/manage/Users", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("sessionId"),
        "Accept-Language": localStorage.getItem("moisesLang")
      },
      body: JSON.stringify({action: "2FA"})
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.result) {
          swal({
            icon: "success",
            title: "Success!",
            text: "The 2FA has been removed!",
          });
          setRecoveryKey("");
          setQrCode(null);
          get2FA();
          dispatch({ type: "TOGGLE_2FA", payload: false });
        } else if (response.error === "EISN") {
          eraseLocalStorage();
        } else {
          swal({
            icon: "error",
            title: props.boot.swal.errorTitle1,
            text: response.error,
          });
        }
        setLoading(false)
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle1,
          text: error.message,
        });
        setLoading(false)
      });
  }

  const get2FA = useCallback(() => {
    fetch(
      getBaseApi() +
        "/manage/Users?action=2FA",
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("sessionId"),
          "Accept-Language": localStorage.getItem("moisesLang")
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.result) {
          setQrCode({
            ...response.data,
            active2FA: parseInt(response.data.active2FA, 10),
            qr: `https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${response.data.qr}&ecc=M`
          });
          setLoading(false);
        } else if (response.error === "EISN") {
          eraseLocalStorage();
        } else {
          swal({
            icon: "error",
            title: props.boot.swal.errorTitle1,
            text: response.error,
          });
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle1,
          text: error.message,
        });
      });
  }, [props.boot]);

  const deleteProfileImage = (e) => {
    swal({
      icon: "warning",
      title: "Are you sure you want to do this?",
      text: "The user image will be deleted",
      buttons: true,
    }).then((willInsert) => {
      if (willInsert) {
        let data = {
          action: 'Image'
        }
        setLoading(true)
        fetch(getBaseApi() + "/manage/Users", {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("sessionId"),
            "Accept-Language": localStorage.getItem("moisesLang")
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.result) {
              swal({
                icon: "success",
                title: "Success!",
                text: "The user were uploaded successfuly!",
              });
              localStorage.setItem(
                "profileImage",
                "https://webservicesnt.org/apis/epasero/cosmetics/images/user_perfil.svg"
              );
            } else {
              swal({
                icon: "error",
                title: props.boot.swal.errorTitle1,
                text: response.error,
              });
            }
            setLoading(false)
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: props.boot.swal.errorTitle1,
              text: error.message,
            });
            setLoading(false)
          });
      }
    });
  };

  const onSubmitUpdateUserPassword = (e) => {
    e.preventDefault();
    setLoading(true)
    let data = {
      action: "password",
      oldPassword: e.target[2].value,
      newPassword: e.target[0].value,
      confirmPassword: e.target[1].value,
    };
    fetch(getBaseApi() + "/manage/Users", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("sessionId"),
        "Accept-Language": localStorage.getItem("moisesLang")
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.result) {
          swal({
            icon: "success",
            title: "Success!",
            text: "The User was updated successfuly!",
          });
        } else if (response.error === "EISN") {
          eraseLocalStorage();
        } else {
          swal({
            icon: "error",
            title: props.boot.swal.errorTitle1,
            text: response.error,
          });
        }
        setLoading(false)
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle1,
          text: error.message,
        });
        setLoading(false)
      });
  }

  useEffect(() => {
    get2FA();
  }, [get2FA]);


  useEffect(() => {
    if (!TwoFAEnable) {
      swal({
        icon: "info",
        title: boot.alert2FAI,
        text: boot.alert2FA,
      });
    }
  }, [TwoFAEnable, boot]);

  return (
    <>
      <Sidebar
        boot={props.boot}
        function={props.language}
      />
      {!loading && (
        <main className="general">
          <Header
            boot={props.boot}
            title={props.boot.settings.title}
            function={props.language}
          />
          <section className="settings">
            <div className="settings__container">
              <div className="settings__box settings__box--picture">
                <h3 className="settings__username">{[localStorage.getItem('firstName'), localStorage.getItem('lastName')].join(" ")}</h3>
                <p className="settings__position">{localStorage.getItem('position')}</p>
                <article className="settings__picture">
                  <img
                    src={localStorage.getItem('profileImage')}
                    alt="Example"
                    className="settings__user-image"
                  />
                  <div className="settings__upload-box">
                    <label htmlFor="userimage" className="settings__upload-button">
                      <img
                        src={EditIcon}
                        alt="Edit"
                        className="settings__upload-icon"
                      />
                    </label>
                    <input
                      type="file"
                      name="userimage"
                      id="userimage"
                      className="settings__user-input"
                      onChange={(e) => uploadProfileImage(e)}
                    />
                  </div>
                </article>
              </div>
              <div className="settings__box">
                <h4 className="settings__box-title">
                  {props.boot.settings.generalInfo}
                </h4>
                <div className="settings__data">
                  <article className="settings__item">
                    <label
                      className="settings__label settings__label--upper"
                      htmlFor="firstName"
                    >
                      {props.boot.table.firstName}
                    </label>
                    <input
                      className="settings__input"
                      type="text"
                      id="firstName"
                      value={localStorage.getItem('firstName')}
                      readOnly
                    />
                  </article>
                  <article className="settings__item">
                    <label
                      className="settings__label settings__label--upper"
                      htmlFor=""
                    >
                      {props.boot.table.lastName}
                    </label>
                    <input
                      className="settings__input"
                      type="text"
                      id="lastName"
                      value={localStorage.getItem('lastName')}
                      readOnly
                    />
                  </article>
                </div>
              </div>
              <div className="settings__box">
                <form onSubmit={(e) => onSubmitUpdateUserPassword(e)}>
                  <h4 className="settings__box-title">
                    {props.boot.login.password}
                  </h4>
                  <div className="settings__data">
                    <article className="settings__item">
                      <label
                        className="settings__label settings__label--upper"
                        htmlFor="newPass"
                      >
                        {props.boot.settings.newPass}
                      </label>
                      <input
                        className="settings__input"
                        type="text"
                        id="newPass"
                        required
                      />
                    </article>
                    <article className="settings__item">
                      <label
                        className="settings__label settings__label--upper"
                        htmlFor="repeatNewPass"
                      >
                        {props.boot.settings.repeatNewPass}
                      </label>
                      <input
                        className="settings__input"
                        type="text"
                        id="repeatNewPass"
                        required
                      />
                    </article>
                    <article className="settings__item">
                      <label
                        className="settings__label settings__label--upper"
                        htmlFor="actualPass"
                      >
                        {props.boot.settings.actualPass}
                      </label>
                      <input
                        className="settings__input"
                        type="text"
                        id="actualPass"
                        required
                      />
                    </article>
                  </div>
                  <article className="settings__item settings__item--actions">
                    <button className="settings__action settings__action--cancel">
                      {props.boot.general.cancel}
                    </button>
                    <button className="settings__action">
                      {props.boot.general.save}
                    </button>
                  </article>
                </form>
              </div>
              <div className="settings__box">
                <form onSubmit={(e) => handleActive2FA(e)}>
                  <h4 className="settings__box-title">
                    Google 2-Step Verification
                  </h4>
                  {(qrCode?.active2FA || !!recoveryKey) ? (
                    <article className="settings__item">
                      <button
                        type="button"
                        className="settings__action"
                        onClick={() => delete2FA()}
                      >
                        {props.boot.settings.removeAuth}
                      </button>
                    </article>
                  ) : qrCode && (
                    <>
                      <img
                        src={qrCode?.qr}
                        alt="settings"
                        className="settings__qr"
                      />
                      <h3 className="settings__secret">{qrCode?.secretCode}</h3>
                      <article className="settings__item">
                        <label
                          className="settings__label settings__label--upper"
                          htmlFor="code"
                        >
                          {props.boot.settings.pin}
                        </label>
                        <input
                          className="settings__input"
                          type="number"
                          id="code"
                          maxLength={6}
                          required
                        />
                      </article>
                      <button
                        type="submit"
                        className="settings__action"
                      >
                        {props.boot.settings.addAuth}
                      </button>
                    </>
                  )}
                  {!!recoveryKey && (
                    <>
                      <p className="settings__text">{props.boot.settings.copyCode}</p>
                      <article className="settings__item">
                        <label
                          className="settings__label settings__label--upper"
                          htmlFor="recoveryKey"
                        >
                          {props.boot.affico.code}
                        </label>
                        <input
                          className="settings__input"
                          type="text"
                          id="recoveryKey"
                          value={recoveryKey}
                          required
                        />
                      </article>
                    </>
                  )}
                </form>
              </div>
            </div>
          </section>
        </main>
      )}
      {loading && (
        <div className="loading">
          <div className="loading__data">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </>
  );
}
