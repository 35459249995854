import React from "react";
import Sidebar from "../components/Sidebar.jsx";

export default function User(props) {
  return (
    <>
      <Sidebar
        boot={props.boot}
        function={props.language}
      />
      <div className="general">
        <h1>Hola</h1>
      </div>
    </>
  );
}
