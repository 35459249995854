import React, { useState, useEffect } from "react";
import "../styles/PaysysUser.scss";
import { getBaseApi } from "../common/Routes";
import {
  eraseLocalStorage,
  convertToQuery,
  inArray,
  getParameterByName,
} from "../common/Functions";

import Header from "../components/Header.jsx";
import Sidebar from "../components/Sidebar.jsx";
import swal from "sweetalert";

import SearchIcon from "../assets/images/icons/search-white.svg";
import AddCardIcon from "../assets/images/icons/card-blue.svg";
import RemoveIcon from "../assets/images/icons/delete.svg";
import EditIcon from "../assets/images/icons/edit.svg";
import FxLogo from "../assets/images/icons/fx-logo.svg";
import LatamLogo from "../assets/images/icons/ltmbt-logo.svg";
import TransferIcon from "../assets/images/icons/transfer-blue.svg";
import CashIcon from "../assets/images/icons/cash-blue.svg";
import Payments from "../components/Payments";

export default function PaysysUser(props) {
  const [loading, setLoading] = useState(true);
  const [crmId, setCrmId] = useState(0);
  const [customer, setCustomer] = useState({});
  const [activeBlock, setActiveBlock] = useState("user");
  const [addCard, setAddCard] = useState(false);
  const [catalogues, setCatalogues] = useState(false);
  const [manualTrans, setManualTrans] = useState({});
  const [permissions, setPermissions] = useState({});
  const [aux, setAux] = useState(false);

  useEffect(() => {
    if (getParameterByName("customer") !== "") {
      if (
        !inArray(
          "zIXBTgeWF6FQ9GhRaiaffs1ecmiKY7rZFgEpngkTcif96CrEDPxmkizF3bzIrGAU",
          JSON.parse(localStorage.getItem("permissions"))
        )
      ) {
        props.history.push("/welcome");
      }
      getPayUsereInfo();
      getCatalogues();
      setPermissions({
        customerInfo: inArray(
          "zIXBTgeWF6FQ9GhRaiaffs1ecmiKY7rZFgEpngkTcif96CrEDPxmkizF3bzIrGAU",
          JSON.parse(localStorage.getItem("permissions"))
        ),
        updateCustomerInfo: inArray(
          "6ghTVRFB4HDWpjUrELqj7vjTf303EKiDNSYgaH5U8BphfQCT4n7HCvXV0E1tIeCJ",
          JSON.parse(localStorage.getItem("permissions")),
        ),
        HideMethodLinks: inArray(
          "ADXwNovQCOxBWghhRv3ggrDR4WrdaCxHwZolJqqbKonUGaPZrErJT9Pkfyj2wR0R",
          JSON.parse(localStorage.getItem("permissions")),
        )
      });
    } else {
      props.history.push("/paysys");
    }
  }, [aux]);

  const getPayUsereInfo = () => {
    setLoading(true);
    const params = {
      action: "ById",
      tpId: getParameterByName("customer"),
    };
    fetch(getBaseApi() + "/manage/Customers" + convertToQuery(params), {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("sessionId"),
        "Accept-Language": localStorage.getItem("moisesLang")
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.result) {
          setCustomer(response.data);
          setCrmId(response.data.id);
          setManualTrans({
            tpId: getParameterByName("customer"),
            pspCat: "",
            transactionCat: "",
            amount: 0,
            currency: "USD",
            comment: "",
          });
          setLoading(false);
        } else if (response.error === "EISN") {
          eraseLocalStorage();
        } else {
          swal({
            icon: "error",
            title: props.boot.swal.errorTitle,
            text: response.error,
          });
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle,
          text: error.message,
        });
      });
  };

  const getCatalogues = () => {
    fetch(getBaseApi() + "/manage/Transaction", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("sessionId"),
        "Accept-Language": localStorage.getItem("moisesLang")
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.result) {
          setCatalogues(response.data);
        } else if (response.error === "EISN") {
          eraseLocalStorage();
        } else {
          swal({
            icon: "error",
            title: props.boot.swal.errorTitle,
            text: response.error,
          });
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle,
          text: error.message,
        });
      });
  };

  const onSubmitUpdateCustomer = (e) => {
    e.preventDefault();
    swal({
      icon: "warning",
      title: "Are you sure you want to do this?",
      text: "A customer will be updated",
      buttons: true,
    }).then((willUpdate) => {
      if (willUpdate) {
        let data = {
          action: "minAmount",
          firstName: customer.firstName,
          lastName: customer.lastName,
          minAmount: customer.minAmount,
          crmId: customer.id,
        };
        setLoading(true)
        fetch(getBaseApi() + "/manage/Customers", {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("sessionId"),
            "Accept-Language": localStorage.getItem("moisesLang")
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.result) {
              swal({
                icon: "success",
                title: "Success!",
                text: "The user was updated successfuly!",
              });
              setAux(!aux);
            } else if (response.error === "EISN") {
              eraseLocalStorage();
            } else {
              swal({
                icon: "error",
                title: props.boot.swal.errorTitle1,
                text: response.error,
              });
            }
            setLoading(false)
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: props.boot.swal.errorTitle1,
              text: error.message,
            });
            setLoading(false)
          });
      }
    });
  };

  const onSubmitTransaction = (e) => {
    e.preventDefault()
    setLoading(true)
    fetch(
        getBaseApi() +
          "/manage/Transaction",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("sessionId"),
            "Accept-Language": localStorage.getItem("moisesLang")
          },
          body: JSON.stringify({ ...manualTrans, payment: "MANUAL" }),
        }
      ).then((res) => res.json())
      .then((response) => {
        if (response.result) {
          swal({
            icon: "success",
            title: "Success!",
            text: "The transaction was inserted successfully!",
          });
          setAux(!aux)
        } else if (response.error === "EISN") {
          eraseLocalStorage();
        } else {
          swal({
            icon: "error",
            title: props.boot.swal.errorTitle,
            text: response.error,
          });
        }
        setLoading(false)
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle,
          text: error.message,
        });
        setLoading(false)
      });
  };

  const onHandleCustomer = (prop) => (event) => {
    setCustomer({ ...customer, [prop]: event.target.value });
  };

  const onHandleManualTransaction = (prop) => (event) => {
    setManualTrans({ ...manualTrans, [prop]: event.target.value });
  };

  /* Cards*/
  const [addNewCardFields, setAddNewCardFields] = useState({});
  const onHandleAddCard = (prop) => (event) => {
    setAddNewCardFields({ ...addNewCardFields, [prop]: event.target.value });
  };

  const getCard = (card) => {
    fetch(
      getBaseApi() +
        "/manage/Cards" +
        convertToQuery({ card }) +
        "&action=ById",
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("sessionId"),
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.result) {
          const card = response.data;

          setAddCard(!addCard);
          setAddNewCardFields({
            cardNumber: card.cardNumber,
            holderName: card.holderName,
            expires: card.expMonth + "/" + card.expYear,
            cvv: card.cvv,
            email: "",
            userAccount: card.customer,
            idCard: card.id,
          });

          setTimeout(() => {
            if (!addCard) {
              document
                .getElementById("addCard")
                .scrollIntoView({ behavior: "smooth" });
            }
          }, 300);
        } else if (response.error === "EISN") {
          eraseLocalStorage();
        } else {
          swal({
            icon: "error",
            title: props.boot.swal.errorTitle,
            text: response.error,
          });
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle,
          text: error.message,
        });
      });
  };

  const insertCard = (params) => {
    setLoading(true)
    fetch(
      getBaseApi() +
        "/manage/Cards",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("sessionId"),
          "Accept-Language": localStorage.getItem("moisesLang")
        },
        body: JSON.stringify(params),
      }
    ).then((res) => res.json())
      .then((response) => {
        if (response.result) {
          swal({
            icon: "success",
            title: "Success!",
            text: "The card was inserted successfuly!",
          });
          setAux(!aux);
          setAddCard(false);
        } else if (response.error === "EISN") {
          eraseLocalStorage();
        } else {
          swal({
            icon: "error",
            title: props.boot.swal.errorTitle,
            text: response.error,
          });
        }
        setLoading(false)
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle,
          text: error.message,
        });
        setLoading(false)
      });
  };

  const updateCard = (params) => {
    setLoading(true)
    fetch(
      getBaseApi() +
        "/manage/Cards",
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("sessionId"),
          "Accept-Language": localStorage.getItem("moisesLang")
        },
        body: JSON.stringify(params),
      }
    ).then((res) => res.json())
      .then((response) => {
        if (response.result) {
          swal({
            icon: "success",
            title: "Success!",
            text: "The card was updated successfuly!",
          });
          setAux(!aux);
          setAddCard(false);
        } else if (response.error === "EISN") {
          eraseLocalStorage();
        } else {
          swal({
            icon: "error",
            title: props.boot.swal.errorTitle,
            text: response.error,
          });
        }
        setLoading(false)
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle,
          text: error.message,
        });
        setLoading(false)
      });
  };

  const onSubmitAddCard = (e) => {
    e.preventDefault();
    const expires = e.target[2].value.split("/");
    let params = {
      holderName: e.target[1].value,
      cardNumber: e.target[0].value,
      expMonth: expires[0],
      expYear: ["20", expires[1]].join(""),
      cvv: e.target[3].value,
      crmId,
    };

    if (addNewCardFields.idCard) {
      params.idCard = addNewCardFields.idCard;
      updateCard(params);
    } else {
      insertCard(params);
    }
  };

  const deleteCard = (idCard) => {
    swal({
      icon: "warning",
      title: "Are you sure you want to do this?",
      text: "A card will be deleted",
      buttons: true,
    }).then((willDeleted) => {
      if (willDeleted) {
        setLoading(true)
        fetch(getBaseApi() + "/manage/Cards", {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("sessionId"),
            "Accept-Language": localStorage.getItem("moisesLang")
          },
          body: JSON.stringify({ idCard }),
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.result) {
              swal({
                icon: "success",
                title: "Success!",
                text: "The card was deleted successfuly!",
              });
              setAux(!aux);
            } else if (response.error === "EISN") {
              eraseLocalStorage();
            } else {
              swal({
                icon: "error",
                title: props.boot.swal.errorTitle1,
                text: response.error,
              });
            }
            setLoading(false)
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: props.boot.swal.errorTitle1,
              text: error.message,
            });
            setLoading(false)
          });
      }
    });
  };

  return (
    <>
      <Sidebar
        boot={props.boot}
        function={props.language}
      />
      {!loading && (
        <main className="general">
          <Header boot={props.boot} title="Paysys" function={props.language} />
          <form
            onSubmit={(e) => {
              const customer = e.target[0].value;
              if (customer) {
                props.history.push(`/payuser?customer=${customer}`);
              }
            }}
          >
            <div className="search">
              <input
                placeholder={props.boot.table.searchTP}
                type="text"
                className="search__input"
                id="searchUser"
              />
              <button className="search__button">
                <img src={SearchIcon} alt="Search" className="search__icon" />
              </button>
            </div>
          </form>
          {crmId && (
            <section className="user">
              <h2 className="user__subtitle">{props.boot.paysys.paysysUser}</h2>
              <div className="tabs">
                <button
                  onClick={() => setActiveBlock("user")}
                  className={
                    "tabs__switch tabs__switch" +
                    (activeBlock === "user" && "--active")
                  }
                >
                  {props.boot.paysys.userDetails}
                </button>
                <button
                  onClick={() => setActiveBlock("transaction")}
                  className={
                    "tabs__switch tabs__switch" +
                    (activeBlock === "transaction" && "--active")
                  }
                >
                  {props.boot.paysys.addTransaction}
                </button>
                {inArray(
                  "fRNrAMfs0n1m2nf4J8DuADtfE1YVl8ku3IGzFK35ae5k0pJ3OUivCGAjrQ2WrTpx",
                  JSON.parse(localStorage.getItem("permissions"))
                ) && (
                  <button
                    onClick={() => setActiveBlock("manualPay")}
                    className={
                      "tabs__switch tabs__switch" +
                      (activeBlock === "manualPay" && "--active")
                    }
                  >
                    {props.boot.paysys.manualPay}
                  </button>
                )}
              </div>
              <div className="user__box">
                {activeBlock === "user" && (
                  <form onSubmit={(e) => onSubmitUpdateCustomer(e)}>
                    <h3 className="user__title">
                      {props.boot.paysys.infoUser}
                    </h3>
                    <div className="user__grid user__grid--fixed user__grid--three">
                      <article className="user__item">
                        <label className="user__label" htmlFor="">
                          {props.boot.table.firstName}
                        </label>
                        <input
                          className="user__input"
                          type="text"
                          id="firstName"
                          value={customer.firstName}
                          onChange={onHandleCustomer("firstName")}
                          readOnly={!permissions?.updateCustomerInfo}
                          required
                        />
                      </article>
                      <article className="user__item">
                        <label className="user__label" htmlFor="">
                          {props.boot.table.lastName}
                        </label>
                        <input
                          className="user__input"
                          type="text"
                          id="lastName"
                          value={customer.lastName}
                          onChange={onHandleCustomer("lastName")}
                          readOnly={!permissions?.updateCustomerInfo}
                          required
                        />
                      </article>
                      <article className="user__item">
                        <label className="user__label" htmlFor="">
                          {props.boot.login.email}
                        </label>
                        <input
                          className="user__input"
                          type="email"
                          id="email"
                          value={customer.email}
                          readOnly
                          required
                        />
                      </article>
                      <article className="user__item">
                        <label className="user__label" htmlFor="">
                          {props.boot.table.businessUnit}
                        </label>
                        <input
                          className="user__input"
                          type="email"
                          id="business_unit"
                          value={customer.BusinessUnit}
                          readOnly
                          required
                        />
                      </article>

                      <article className="user__item">
                        <label className="user__label" htmlFor="">
                          {props.boot.paysys.minAmount}
                        </label>
                        <input
                          className="user__input"
                          type="text"
                          id="minAmount"
                          value={customer.minAmount}
                          onChange={onHandleCustomer("minAmount")}
                          readOnly={!permissions?.updateCustomerInfo}
                          required
                        />
                      </article>
                    </div>
                    <div className="user__cards">
                      <span className="user__top">
                        {customer.cards?.length > 0 && (
                          <h3 className="user__title">
                            {props.boot.paysys.savedCards}
                          </h3>
                        )}
                        {inArray(
                          "zKt31HEpB1NtsMUDLxvyBEt5jzBG96kfdGqJd9qKowtXDvelNmZNJjvwb8FMaBcH",
                          JSON.parse(localStorage.getItem("permissions"))
                        ) && (
                          <button
                            type="button"
                            onClick={() => {
                              setAddCard(!addCard);
                              setAddNewCardFields({
                                cardNumber: "",
                                holderName: "",
                                expires: "",
                                cvv: "",
                                email: "",
                                userAccount: "",
                              });

                              setTimeout(() => {
                                if (!addCard) {
                                  document
                                    .getElementById("addCard")
                                    .scrollIntoView({ behavior: "smooth" });
                                }
                              }, 300);
                            }}
                            className="user__new"
                          >
                            <img
                              src={AddCardIcon}
                              alt="Add Card"
                              className="user__icon"
                            />
                            {props.boot.paysys.addCard}
                          </button>
                        )}
                      </span>
                      {customer.cards && (
                        <div className="user__grid user__grid--three">
                          {customer.cards.map((card, i) => {
                            return (
                              <div key={i} className="user__holder">
                                <article className="card">
                                  <p className="card__name">
                                    {card.holderName}
                                  </p>
                                  <p className="card__number">
                                    {card.cardNumber}
                                  </p>
                                  <p className="card__expires">
                                    {props.boot.paysys.expires}
                                  </p>
                                  <p className="card__number card__number--expiration">
                                    {card.expMonth} / {card.expYear}
                                  </p>
                                </article>
                                <article className="user__edit">
                                  {inArray(
                                    "NyRtBAo3deD8wiL4G8HdH8XZ1Wr5ePqx3Kg2I7DjzxOjSjQoEccCUqpORgy4rRn1",
                                    JSON.parse(
                                      localStorage.getItem("permissions")
                                    )
                                  ) && (
                                    <button
                                      type="button"
                                      className="user__button user__button--icon"
                                      onClick={() => deleteCard(card.id)}
                                    >
                                      <img
                                        src={RemoveIcon}
                                        alt=""
                                        className="user__icon user__icon--actions"
                                      />
                                    </button>
                                  )}
                                  {inArray(
                                    "FhJW4N9FIK3fSfJL50BdbOEA9wdhZ7gRieQErMUn7UPk8Xfp88JzD2Ru72lESoRu",
                                    JSON.parse(
                                      localStorage.getItem("permissions")
                                    )
                                  ) && (
                                    <button
                                      type="button"
                                      className="user__button user__button--icon"
                                      onClick={() => getCard(card.id)}
                                    >
                                      <img
                                        src={EditIcon}
                                        alt=""
                                        className="user__icon user__icon--actions"
                                      />
                                    </button>
                                  )}
                                </article>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      <div className="user__actions">
                        <button
                          type="button"
                          className="user__button user__button--cancel"
                          onClick={() => props.history.push("/paysys")}
                        >
                          {props.boot.general.cancel}
                        </button>
                        {permissions?.updateCustomerInfo && (
                          <button
                            type="submit"
                            className="user__button user__button--save"
                          >
                            {props.boot.general.save}
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                )}
                {activeBlock === "transaction" && (
                  <form onSubmit={(e) => onSubmitTransaction(e)}>
                    {!permissions?.HideMethodLinks && (
                      <>
                        <h3 className="user__title">
                          {props.boot.paysys.transMethod}
                        </h3>
                        <p className="user__paragraph">
                          {props.boot.paysys.selectPayment}
                        </p>
                        <div className="user__grid user__grid--fixed user__grid--four">
                          <article className="user__item user__item--center">
                            <a
                              href="https://www.fxcolegio.com/Courses"
                              target="_blank"
                              style={{ color: "inherit", textDecoration: "none" }} rel="noreferrer"
                            >
                              <div className="user__round">
                                <p className="user__name">FxColegio</p>
                                <img
                                  src={FxLogo}
                                  alt="FxLogo"
                                  className="user__logo"
                                />
                              </div>
                            </a>
                          </article>
                          <article className="user__item user__item--center">
                            <a
                              href="https://latambot.com/"
                              target="_blank"
                              style={{ color: "inherit", textDecoration: "none" }} rel="noreferrer"
                            >
                              <div className="user__round">
                                <p className="user__name">LatamBot</p>
                                <img
                                  src={LatamLogo}
                                  alt="LatamLogo"
                                  className="user__logo"
                                />
                              </div>
                            </a>
                          </article>
                          <article className="user__item user__item--center">
                            <a
                              href={
                                "https://www.orotrader.com/paysys/?tp_id=" +
                                getParameterByName("customer") +
                                "&newp=(cSuaz)_Np=nXBy:mLZ5(m95"
                              }
                              target="_blank"
                              style={{ color: "inherit", textDecoration: "none" }} rel="noreferrer"
                            >
                              <div className="user__round">
                                <p className="user__name">
                                  {props.boot.paysys.bankTransfer}
                                </p>
                                <img
                                  src={TransferIcon}
                                  alt="Transfer"
                                  className="user__logo"
                                />
                              </div>
                            </a>
                          </article>
                          <article className="user__item user__item--center">
                            <a
                              href={
                                "https://www.orotrader.com/paysys/?tp_id=" +
                                getParameterByName("customer") +
                                "&newp=(cSuaz)_Np=nXBy:mLZ5(m95"
                              }
                              target="_blank"
                              style={{ color: "inherit", textDecoration: "none" }} rel="noreferrer"
                            >
                              <div className="user__round">
                                <p className="user__name">
                                  {props.boot.paysys.cashTransfer}
                                </p>
                                <img
                                  src={CashIcon}
                                  alt="Cash"
                                  className="user__logo"
                                />
                              </div>
                            </a>
                          </article>
                        </div>
                      </>
                    )}
                    {inArray(
                      "fRNrAMfs0n1m2nf4J8DuADtfE1YVl8ku3IGzFK35ae5k0pJ3OUivCGAjrQ2WrTpx",
                      JSON.parse(localStorage.getItem("permissions"))
                    ) && (
                      <>
                        <h3 className="user__title">
                          {props.boot.paysys.manualTransaction}
                        </h3>
                        <p className="user__paragraph">
                          {props.boot.paysys.alreadyDeposit}
                        </p>
                        <div className="user__grid user__grid--fixed user__grid--four">
                          <article className="user__item">
                            <label
                              className="user__label user__label--upper"
                              htmlFor=""
                            >
                              {props.boot.paysys.typeTrans}
                            </label>
                            <select
                              name="typeTrans"
                              id="typeTrans"
                              className="user__select"
                              onChange={onHandleManualTransaction(
                                "transactionCat"
                              )}
                              required
                            >
                              <option defaultValue hidden value="">
                                {props.boot.paysys.typeTrans}
                              </option>
                              {catalogues.transactionCategory.map((type, i) => (
                                <option key={i} defaultValue value={type.id}>
                                  {type.name}
                                </option>
                              ))}
                            </select>
                          </article>
                          <article className="user__item">
                            <label
                              className="user__label user__label--upper"
                              htmlFor=""
                            >
                              {props.boot.paysys.method}
                            </label>
                            <select
                              name="method"
                              id="method"
                              className="user__select"
                              onChange={onHandleManualTransaction("pspCat")}
                              required
                            >
                              <option defaultValue hidden value="">
                                {props.boot.paysys.method}
                              </option>
                              {catalogues.psps.map((psp, i) => (
                                <option key={i} defaultValue value={psp.id}>
                                  {psp.name}
                                </option>
                              ))}
                            </select>
                          </article>
                          <article className="user__item">
                            <label
                              className="user__label user__label--upper"
                              htmlFor=""
                            >
                              {props.boot.paysys.ccLast}
                            </label>
                            <input
                              className="user__input"
                              type="text"
                              id="ccLast"
                              required
                            />
                          </article>
                          <article className="user__item">
                            <label
                              className="user__label user__label--upper"
                              htmlFor=""
                            >
                              {props.boot.paysys.amount}
                            </label>
                            <input
                              className="user__input"
                              type="text"
                              id="amount"
                              onChange={onHandleManualTransaction("amount")}
                              required
                            />
                          </article>
                        </div>
                        <div className="user__transactions">
                          <button
                            className="user__button user__button--cancel"
                            id="transactionButton"
                          >
                            {props.boot.paysys.addTransaction}
                          </button>
                        </div>
                      </>
                    )}
                  </form>
                )}
                {activeBlock === "manualPay" && (
                  <Payments
                    tpId={getParameterByName("customer")}
                    props={props}
                    psps={catalogues.psps}
                    customer={customer}
                    setAux={setAux}
                    setLoading={setLoading}
                  />
                )}
              </div>
              {addCard && (
                <form onSubmit={(e) => onSubmitAddCard(e)}>
                  <div className="user__box user__box--margin" id="addCard">
                    <h3 className="user__title">
                      {addNewCardFields.idCard
                        ? props.boot.paysys.updateCard
                        : props.boot.paysys.addNewCard}
                    </h3>
                    <div className="user__grid user__grid--fixed user__grid--three">
                      <article className="user__item">
                        <label className="user__label" htmlFor="">
                          {props.boot.paysys.cardNumber}
                        </label>
                        <input
                          className="user__input"
                          type="text"
                          id="cardNumber"
                          minLength="13"
                          maxLength="16"
                          defaultValue={addNewCardFields.cardNumber}
                          onChange={onHandleAddCard("cardNumber")}
                          required
                        />
                      </article>
                      <article className="user__item">
                        <label className="user__label" htmlFor="">
                          {props.boot.paysys.holderName}
                        </label>
                        <input
                          className="user__input"
                          type="text"
                          id="holderName"
                          defaultValue={addNewCardFields.holderName}
                          onChange={onHandleAddCard("holderName")}
                          required
                        />
                      </article>
                      <article className="user__item user__item--expires">
                        <span className="user__expires">
                          <label className="user__label" htmlFor="">
                            {props.boot.paysys.expires}
                          </label>
                          <input
                            className="user__input"
                            type="text"
                            id="expires"
                            placeholder="00/00"
                            maxLength="5"
                            defaultValue={addNewCardFields.expires}
                            onChange={onHandleAddCard("expires")}
                            onKeyUp={(e) => {
                              e.preventDefault();
                              let code = e.keyCode;
                              let input = e.target.value;
                              let string_r = "";
                              if (code === 8 || code === 46) return;
                              if (input.length < 2) {
                                string_r = input;
                              } else if (input.length === 2) {
                                string_r = input + "/";
                              } else if (input.length > 2) {
                                string_r = input;
                              } else {
                                string_r = input;
                              }
                              e.target.value = string_r;
                            }}
                            required
                          />
                        </span>
                        <span className="user__expires">
                          <label className="user__label" htmlFor="">
                            {props.boot.paysys.cvv}
                          </label>
                          <input
                            className="user__input"
                            type="number"
                            id="cvv"
                            maxLength="4"
                            defaultValue={addNewCardFields.cvv}
                            onChange={onHandleAddCard("cvv")}
                            required
                          />
                        </span>
                      </article>
                      <article className="user__item">
                        <label className="user__label" htmlFor="">
                          {props.boot.table.firstName}
                        </label>
                        <input
                          className="user__input"
                          type="text"
                          id="firstName"
                          value={customer.firstName}
                          readOnly
                        />
                      </article>

                      <article className="user__item">
                        <label className="user__label" htmlFor="">
                          {props.boot.paysys.userAccount}
                        </label>
                        <input
                          className="user__input"
                          type="text"
                          id="userAccount"
                          value={customer.id}
                          readOnly
                        />
                      </article>
                      <article className="user__item">
                        <article className="card">
                          <p className="card__name">
                            {addNewCardFields.holderName || "Valeria Olzen"}
                          </p>
                          <p className="card__number">
                            **** **** ****{" "}
                            {(addNewCardFields.cardNumber &&
                              addNewCardFields.cardNumber.substring(
                                addNewCardFields.cardNumber.length - 4,
                                addNewCardFields.cardNumber.length
                              )) ||
                              "4543"}
                          </p>
                          <p className="card__expires">
                            {props.boot.paysys.expires}
                          </p>
                          <p className="card__number card__number--expiration">
                            {addNewCardFields.expires || "06/21"}
                          </p>
                        </article>
                      </article>
                    </div>
                    <div className="user__actions">
                      <button
                        type="button"
                        className="user__button user__button--cancel"
                        onClick={() => setAddCard(false)}
                      >
                        {props.boot.general.cancel}
                      </button>
                      {((!addNewCardFields.idCard &&
                        inArray(
                          "zKt31HEpB1NtsMUDLxvyBEt5jzBG96kfdGqJd9qKowtXDvelNmZNJjvwb8FMaBcH",
                          JSON.parse(localStorage.getItem("permissions"))
                        )) ||
                        (addNewCardFields.idCard &&
                          inArray(
                            "8tRkUnnUaAUXBrVC5ncbTCZXZDdkLLGDpbsyCzcbUn6fRkERTTPWXoKGUWwbmRXz",
                            JSON.parse(localStorage.getItem("permissions"))
                          ))) && (
                        <button
                          type="submit"
                          className="user__button user__button--save"
                        >
                          {props.boot.general.save}
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              )}
            </section>
          )}
        </main>
      )}
      {loading && (
        <div className="loading">
          <div className="loading__data">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </>
  );
}
