import config from "../config";

export async function moveCustomer({ tpid, businessId, token }) {
  const body = {
    tpid,
    bussinesUnitName: businessId,
  };
  const request = await fetch(`${config.api}/update/move-lead-crm`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  if (!request.ok || request.status === 500) throw new Error("error_move_customer");

  const response = await request.json();

  return response;
}
