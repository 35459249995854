import { withPortal } from "../../hoc";
import { useAfficoMoveCustomer } from "../../affico/hooks";
import { useAppContext } from "../../context/AppContext";

import "./AffMoveCustomer.scss";

import IconClose from "../../assets/images/icons/close_icon.svg";

import LoaderPortal from "../Loader/Loader";

function FormMove({ customer, handleClose, move }) {
  const [{ permissions, boot }] = useAppContext();

  return (
    <div className="affmove">
      <form onSubmit={move} className="affmove__form">
        <button
          type="button"
          onClick={handleClose}
          className="affmove__close"
        >
          <img src={IconClose} alt={boot.affico["move-close"] ?? ""} />
        </button>
        <h3 className="affmove__title">{boot.affico["move-customer"]}</h3>
        <div className="affmove__customer">
          <div className="affmove__customer-box">
            <p className="affmove__customer-text">
              TPID:
              <span>
                {customer.tpId}
              </span>
            </p>
            <p className="affmove__customer-text">
              CRMID:
              <span>
                {customer.crmId}
              </span>
            </p>
          </div>
          <div className="affmove__customer-box">
            <p className="affmove__customer-text">
              {boot.affico["transaction-id"]}
              <span>
                {customer.idTransaction}
              </span>
            </p>
            <p className="affmove__customer-text" data-type="status" data-status={customer.statusTransaction?.toLowerCase()}>
              {boot.affico["transaction-status"]}
              <span>
                {customer.statusTransaction}
              </span>
            </p>
          </div>
          <div className="affmove__customer-box">
            <p className="affmove__customer-text" data-type="email">
              {boot.affico["email"]}
              <span>
                {customer.email}
              </span>
            </p>
            <p className="affmove__customer-text" data-type="name">
              {boot.affico["name"]}
              <span>
                {`${customer.firstName} ${customer.lastName}`}
              </span>
            </p>
          </div>
          <div className="affmove__customer-box">
            <p className="affmove__customer-text" data-type="country">
              {boot.affico["country"]}
              <span>
                {customer.country}
              </span>
            </p>
            {permissions.includes("8Dd6po4By5sp9gIYdDUW6L4ZbFCcYnIDoq2w6knMvvK51ACd4xeSOZCaGdyQXsvK") ? (
              <p className="affmove__customer-text">
                {boot.affico["referral"]}
                <span>
                  {customer.referral}
                </span>
              </p>
            ) : null}
            <p className="affmove__customer-text">
              {boot.affico["status"]}
              <span>
                {customer.status}
              </span>
            </p>
          </div>
          <div className="affmove__customer-box">
            {permissions.includes("s7FWvufYsTjHSQpUpo9ECALMDELWY9f3UyPgb7dKVeX6aDSoogO6JIbLvKyGzFxS") ? (
              <p className="affmove__customer-text">
                {boot.affico["ftd-date"]}
                <span>
                  {customer.ftdDate}
                </span>
              </p>
            ) : null}
            <p className="affmove__customer-text">
              {boot.affico["insert-date"]}
              <span>
                {customer.insertDate}
              </span>
            </p>
            {customer.affiliateFtdDate ? (
              <p className="affmove__customer-text">
                {boot.affico["affiliate-date"]}
                <span>
                  {customer.affiliateFtdDate}
                </span>
              </p>
            ) : null}
          </div>
        </div>
        <p className="affmove__title">{boot.affico["move-to"]}</p>
        <div className="affmove__select">
          {customer.business.map((bu) => (
            <label htmlFor={`${bu.name}`} key={bu.name} className="affmove__option">
              <input
                type="radio"
                name="business"
                id={`${bu.name}`}
                value={bu.name}
                className="affmove__radio"
              />
              <span>
                {bu.name}
              </span>
            </label>
          ))}
        </div>
        <div className="affmove__actions">
          <button type="button" onClick={handleClose} className="affmove__cancel">
            {boot.affico["move-cancel"]}
          </button>
          <button type="submit" className="affmove__submit">
            {boot.affico["move-submit"]}
          </button>
        </div>
      </form>
    </div>
  );
}

const Form = withPortal(FormMove, "#root-modal");

export default function AffMoveCustomer({ children, token }) {
  const { open, customer, loading, toggleOpen, move, toggleLoading } = useAfficoMoveCustomer({ token });

  const handleClose = () => {
    toggleOpen(null, false);
  };

  return (
    <>
      {!open && loading ? <LoaderPortal /> : null}
      {open && customer ? (
        <Form customer={customer} handleClose={handleClose} move={move} />
      ) : null}
      {children({ toggleOpen, toggleLoading })}
    </>
  );
}
