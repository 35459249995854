import React, { memo } from "react";
import { Link } from "react-router-dom";
import { inArray } from "../common/Functions";
import { useAppContext } from "../context/AppContext";

import "../styles/components/MenuLink.scss";

function MenuLink({ to, permission, icon, label, sidebar = false, small = false }) {
  const [{ permissions }] = useAppContext();

  return (
    <Link
      to={to}
      className={`menu-link${!inArray(permission, permissions) ? " menu-link--unactive" : ""}`}
      data-sidebar={sidebar}
      data-small={small}
    >
      <img src={icon} alt={label} className="menu-link__icon" />
      <p className="menu-link__title">{label}</p>
    </Link>
  );
}


export default memo(MenuLink);