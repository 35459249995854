import React from "react";
import "../styles/components/Kpis.scss";

export default function Kpis(props) {
  return (
    <>
      <section className="kpis">
        <h2 className="kpis__title">{props.title}</h2>
        <div className="kpis__summary">
          {props.kpis ? Object.keys(props.kpis).map((kpiKey) => (
            <article key={`item-${kpiKey}`} className="kpis__item">
              <h2 className="kpis__name">
                {props.boot.table[kpiKey] ?? kpiKey}
              </h2>
              <p className="kpis__data">{props.kpis[kpiKey]}</p>
            </article>
          )) : null}
        </div>
        {props.paysys && (
          <div className="kpis__summary">
            <article className="kpis__item">
              <h3 className="kpis__name">{props.boot.table.retAmount}</h3>
              <p className="kpis__data">$ {props.paysys.retAmount}</p>
            </article>
            <article className="kpis__item">
              <h3 className="kpis__name">{props.boot.table.ftdNumber}</h3>
              <p className="kpis__data">$ {props.paysys.ftdAmount}</p>
            </article>
            <article className="kpis__item">
              <h3 className="kpis__name">{props.boot.table.totalDeposits}</h3>
              <p className="kpis__data">$ {props.paysys.totalDeposits}</p>
            </article>
            <article className="kpis__item kpis__item--last">
              <h3 className="kpis__name">{props.boot.table.totalWithdrawal}</h3>
              <p className="kpis__data">$ {props.paysys.totalWithdrawal}</p>
            </article>
          </div>
        )}
      </section>
    </>
  );
}
