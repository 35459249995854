export default function AffFilter({ id, checked, onChecked, ...props }) {
  return (
    <label
      htmlFor={id}
      className="table__checker"
      data-checked={checked}
    >
      <input
        type="checkbox"
        className="table__checker-input"
        id={id}
        checked={checked}
        onChange={onChecked}
        disabled={props?.disabled}
      />
      <p className="table__checker-text">{props.text}</p>
    </label>
  );
}
