import { getCountries } from "./Boot";
// import { getCountries } from "./boot";
// Does a search 'in' a array and looks for a coincidence

export function inArray(needle, haystack = []) {
  if (haystack.length > 0) {
    let length = haystack.length;
    for (let i = 0; i < length; i++) {
      if (haystack[i] === needle) return true;
    }
  }
  return false;
}
// Gives a format depending on the date parameters passed to it
export function dateFormat(type, dateOb, days = [], months = []) {
  let r;
  let y = dateOb.getFullYear();
  let m = ("0" + (dateOb.getMonth() + 1)).substr(-2);
  let d = ("0" + dateOb.getDate()).substr(-2);
  switch (type) {
    case 1:
      r = d + "/" + m + "/" + y;
      break;
    case 2:
      r = y + "-" + m + "-" + d;
      break;
    case 3:
      if (days.length > 0 && months.length > 0) {
        let date = dateOb.getDay();
        r = days[date] + ", " + d + " " + months[dateOb.getMonth()];
      }
      break;
    default:
      r = d + "/" + m + "/" + y;
      break;
  }
  return r;
}
// Camptures the value of a URL parameter by its name
export function getParameterByName(name) {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}
// Clears the localStorage and redirects to the login page
export function eraseLocalStorage() {
  localStorage.clear();
  window.location.href = "./";
}
// Converts an object to a query
export function convertToQuery(objeto) {
  let keys = Object.keys(objeto);
  let query = "?";
  keys.forEach((item, index) => {
    if (typeof objeto[item] == "string") {
      query += item + "=" + objeto[item] + "&";
    } else if (Array.isArray(objeto[item])) {
      if (!isNaN(parseInt(objeto[item][0]))) {
        query += item + "=[";
        objeto[item].forEach((value, index) => {
          query += value + ",";
        });
        query = query.substring(0, query.length - 1) + "]&";
      } else if (typeof objeto[item][0] == "string") {
        query += item + "=[";
        objeto[item].forEach((value, index) => {
          query += '"' + value + '",';
        });
        query = query.substring(0, query.length - 1) + "]&";
      }
    } else if (typeof objeto[item] == "number") {
      query += item + "=" + objeto[item] + "&";
    }
  });
  return query.substring(0, query.length - 1);
}
// Renders a select element depending the data passed to it
export function renderGeneralSelect(catalogue = [], isCountry = 0) {
  let option = [];
  if (catalogue.length > 0) {
    option.push(
      catalogue.map((item) => {
        return (
          <option key={item.name} value={isCountry ? item.name : item.id}>
            {isCountry ? getCountries()[item.name] : item.name}
          </option>
        );
      })
    );
  }
  return option;
}
// Calculates the range between two dates depending the case that is in turn 
export function getDateRange(value) {
  let dateStart = new Date();
  let dateEnd = new Date();
  switch (value) {
    case "Today":
      dateEnd.setDate(dateStart.getDate());
      break;
    case "Yesterday":
      dateStart.setDate(dateStart.getDate() - 1);
      dateEnd.setDate(dateStart.getDate());
      break;
    case "This Week":
      if (dateStart.getDay() !== 0) {
        dateStart.setDate(dateStart.getDate() - dateStart.getDay() + 1);
      } else {
        dateStart.setDate(dateStart.getDate() - 7);
      }
      break;
    case "Last Week":
      if (dateStart.getDay() !== 0) {
        dateStart.setDate(dateStart.getDate() - dateStart.getDay() - 6);
        dateEnd.setDate(dateEnd.getDate() - dateEnd.getDay());
      } else {
        dateStart.setDate(dateStart.getDate() - 13);
        dateEnd.setDate(dateEnd.getDate() - 7);
      }
      break;
    case "This Month":
      dateStart.setDate(1);
      break;
    case "Last Month":
      dateStart.setDate(1);
      dateStart.setMonth(dateStart.getMonth() - 1);
      dateEnd.setDate(0);
      break;
    case "This Year":
      dateStart.setDate(1);
      dateStart.setMonth(0);
      break;
    default:
      break;
  }
  return {
    dateStart,
    dateEnd,
  };
}
// Gives a two-decimals monetary format to a given number
export function numberFormat(number) {
  if (typeof number === undefined || number === 0 || number === "0") {
    return "-";
  }
  number += "";
  let x = number.split(".");
  let x1 = x[0];
  let x2 = x.length > 1 ? "." + x[1].substr(0, 2) : "";
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1,$2");
  }
  let new_numb = x1 + x2;
  if (new_numb.substr(-3) === ".00") {
    return new_numb.substring(0, new_numb.length - 3);
  }
  return new_numb;
}
// Finds common elements between two arrays
export function findCommonElements(arr1, arr2) {
  return arr1.some((item) => arr2.includes(item));
}

function convertArrayOfObjectsToCSV(array, columns) {
  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = columns.map((col) => col.selector);
  // const keys = Object.keys(data[0]);
  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;
      result += item[key];

      ctr += 1;
    });
    result += lineDelimiter;
  });
  return result;
}

export function downloadTable({ data, columns, name }) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(data, columns);
  if (csv == null) return;

  const filename = `${name}.csv`;

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}
