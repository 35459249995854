import { forwardRef } from "react";
import { createPortal } from "react-dom";

export function withPortal(Component, selector) {
  const WithPortal = (props, ref) => {
    const root = document.querySelector(selector);

    if (!root) throw new Error(`Could not find element with selector: ${selector}`);

    return root ? createPortal(
      <Component ref={ref} {...props} />,
      root,
    ) : null;
  };

  WithPortal.displayName = `withPortal(${Component.displayName || Component.name})`;

  return forwardRef(WithPortal);
}
