/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from "react";
import { inArray, convertToQuery, getDateRange, dateFormat, downloadTable } from "../common/Functions";
import { getBaseApi } from "../common/Routes";
import format from "date-fns/format";
import swal from "sweetalert";
import { getCountries } from "../common/Boot";
import { DatePicker } from "@material-ui/pickers";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

/* styles */
import "../styles/Compliance.scss";
/* icons */
import ReloadIcon from "../assets/images/icons/reload-white.svg";
import DownloadIcon from "../assets/images/icons/download-white.svg";
import FiltersIcon from "../assets/images/icons/filters-white.svg";
import CancelIcon from "../assets/images/icons/cancel-blue.svg";
import CheckIcon from "../assets/images/icons/check.png";
import MarkIcon from "../assets/images/icons/x.png";
/* components */
import Header from "../components/Header.jsx";
import Sidebar from "../components/Sidebar.jsx";
import Chart from "../components/Chart.jsx";

export default function Compliance(props) {
  const [loading, setLoading] = useState(false);
  const [charts, setCharts] = useState({
    POR: {
      crmCode: "POR",
      files: {
          "approved": 0,
          "pending": 0,
          "rejected": 0
      }
    },
    POP: {
      crmCode: "POP",
      files: {
          "approved": 0,
          "pending": 0,
          "rejected": 0
      },
    },
    POIBack: {
      crmCode: "POIBack",
      extraType: "Back",
      files: {
          "approved": 0,
          "pending": 0,
          "rejected": 0
      },
    },
    CCBack: {
      crmCode: "CCBack",
      extraType: "Back",
      files: {
          "approved": 0,
          "pending": 0,
          "rejected": 0
      },
    },
    POIFront: {
      crmCode: "POIFront",
      extraType: "Front",
      files: {
          "approved": 0,
          "pending": 0,
          "rejected": 0
      },
    },
    CCFront: {
      crmCode: "CCFront",
      extraType: "Front",
      files: {
          "approved": 0,
          "pending": 0,
          "rejected": 0
      },
   },
   "kyc":0,
   "totalFtd": 0
  });
  const [aux, setAux] = useState(false);
  const [activeBlock, setActiveBlock] = useState("transaction");
  const [catalogues, setCatalogues] = useState({});
  const [typeDate, setTypeDate] = useState(true);
  const [agentsAssign, setAgentsAssign] = useState(false);
  const [agentsInfo, setAgentsInfo] = useState([]);
  const initialTransaction = {
    action: "Information",
    dateType: "Today",
    dateStart: dateFormat(2, new Date()),
    dateEnd: dateFormat(2, new Date()),
    country: "ALL",
    affiliate: "ALL",
    psp: "ALL",
    department: "ALL",
    businessUnit: "ALL",
  };
  const initialCustomer = {
    action: "CustomerDocuments",
    agent: "ALL",
    dateType: "Today",
    dateStart: dateFormat(2, new Date()),
    dateEnd: dateFormat(2, new Date()),
    country: "ALL",
    affiliate: "ALL",
    psp: "ALL",
    transactionDepartment: "ALL",
    businessUnit: "ALL"
  };
  const [filter, setFilter] = useState(initialTransaction);
  const [filters, setFilters] = useState(false);
  const [activeFilters, setActiveFilters] = useState({});
  const [transactionInfo, setTransactionInfo] = useState([]);
  const [clientsAssignInfo, setClientsAssignInfo] = useState([]);
  // const [agentsInfo, setAgentsInfo] = useState([]);
  const [clientsInfo, setClientsInfo] = useState([]);
  const [transSearch, setTransSearch] = useState("");
  const [agentsSearch, setAgentsSearch] = useState("");
  const [clientsSearch, setClientsSearch] = useState("");
  const modifyFilterState = (e) => {
    const { dateStart, dateEnd } = getDateRange(e.target.value);
    setFilter({
      ...filter,
      dateType: e.target.value,
      dateStart,
      dateEnd,
    });
    if (e.target.value === "Range") {
      setTypeDate(false);
    } else {
      setTypeDate(true);
    }
  };
  const onClickDownloadAgents = () => {
    downloadTable({ data: agentsInfo, columns: agentsColumns, name: props.boot.compliance.agents });
  };
  const onClickDownloadClients = () => {
    downloadTable({ data: clientsInfo, columns: clientsColumns, name: props.boot.compliance.clients });
  };
  const onClickDownloadTransaction = () => {
    downloadTable({ data: transactionInfo, columns: transactionColumns, name: props.boot.compliance.transaction });
  };
  const onClickdownloadDocument = (type, data) => async (event) => {
    try {
      const { idCrmTransaction: transactionId } = data;
      const response = await fetch(
        getBaseApi() +
          "/manage/CustomerDocuments" +
          convertToQuery({
            document: type,
            transactionId
          }),
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("sessionId"),
            "Accept-Language": localStorage.getItem("moisesLang")
          },
        }
      );
      const file = await response.blob();
      if (file.type !== "application/json") {
        let url = window.URL.createObjectURL(file);
        let excel = document.createElement("a");
        excel.href = url;
        excel.download = `${type}_${transactionId}.pdf`;
        document.body.appendChild(excel);
        excel.click();
        excel.remove();
      } else {
        swal({
          icon: "info",
          title: props.boot.swal.errorTitle1,
          text: "There is no download format",
        });
      }
      setLoading(false);
    } catch (error) {
      swal({
        icon: "error",
        title: props.boot.swal.errorTitle1,
        text: error.message,
      });
      setLoading(false);
    }
  };
  
  const handleChangeFilters = (e) => {
    e.preventDefault();
    let newFilters = {};
    if(activeBlock === "transaction") {
        let psps = activeFilters.psp
        ? activeFilters.psp.map((e) => e.id)
        : [];
      psps = !psps.length
        ? e.target[4].value === "ALL"
          ? "ALL"
          : [e.target[4].value]
        : psps;
        let departments = activeFilters.department
        ? activeFilters.department.map((e) => e.id)
        : [];
        departments = !departments.length
        ? e.target[3].value === "ALL"
          ? "ALL"
          : [e.target[3].value]
        : departments;
        let country = activeFilters.country
        ? activeFilters.country.map((e) => e.id)
        : [];
      country = !country.length
        ? e.target[2].value === "ALL"
          ? "ALL"
          : [e.target[2].value]
        : country;
      let affiliate = activeFilters.affiliate
        ? activeFilters.affiliate.map((e) => e.id)
        : [];
      affiliate = !affiliate.length
        ? e.target[1].value === "ALL"
          ? "ALL"
          : [e.target[1].value]
        : affiliate;
      let businessUnit = activeFilters.businessUnit
        ? activeFilters.businessUnit.map((e) => e.id)
        : [];
      businessUnit = !businessUnit.length
        ? e.target[0].value === "ALL"
          ? "ALL"
          : [e.target[0].value]
        : businessUnit;
      newFilters = {
        ...filter,
        dateType: e.target[5].value,
        dateStart: e.target[6].value,
        dateEnd: e.target[7].value,
        country: country.toString(),
        affiliate: affiliate.toString(),
        businessUnit: businessUnit.toString(),
        psp: psps.toString(),
        department: departments.toString()
      };      
    } else if (activeBlock === "clients") {
      let businessUnit = activeFilters.businessUnit
        ? activeFilters.businessUnit.map((e) => e.id)
        : [];
      businessUnit = !businessUnit.length
        ? e.target[0].value === "ALL"
          ? "ALL"
          : [e.target[0].value]
        : businessUnit;
      let country = activeFilters.country
        ? activeFilters.country.map((e) => e.id)
        : [];
      country = !country.length
        ? e.target[1].value === "ALL"
          ? "ALL"
          : [e.target[1].value]
        : country;
        let departments = activeFilters.department
        ? activeFilters.department.map((e) => e.id)
        : [];
        departments = !departments.length
        ? e.target[2].value === "ALL"
          ? "ALL"
          : [e.target[2].value]
        : departments;
      let agents = activeFilters.agents
        ? activeFilters.agents.map((e) => e.id)
        : [];
      agents = !agents.length
        ? e.target[3].value === "ALL"
          ? "ALL"
          : [e.target[3].value]
        : agents;
      newFilters = {
        ...filter,
        dateType: e.target[4].value,
        dateStart: e.target[5].value,
        dateEnd: e.target[6].value,
        country: country.toString(),
        businessUnit: businessUnit.toString(),
        agents: agents.toString(),
        transactionDepartment: departments.toString()
      };    
    }
    setFilter(newFilters);
    setFilters(false);
    setAux(!aux);
  };
  const renderActiveFilter = () => {
    let returnActive = [];
    Object.keys(activeFilters).forEach((type) => {
      activeFilters[type].forEach((item, index) => {
        returnActive.push(
          <button
            className="filters__button"
            onClick={() => deleteActiveFilter(type, index)}
            key={type + item.id}
          >
            {type}: {item.label}{" "}
            <img className="filters__icon" src={CancelIcon} alt="Close" />
          </button>
        );
      });
    });
    return returnActive;
  };  
  const handleChange = (prop) => (event) => {
    let actFilters = activeFilters[prop] ? activeFilters[prop] : [];
    const sel = document.getElementById(prop);
    const label = sel.options[sel.selectedIndex].text;
    if (actFilters.map((e) => e.id).indexOf(event.target.value) >= 0) {
      return;
    }
    if (event.target.value === "ALL") {
      actFilters = [];
    } else {
      actFilters.push({
        id: event.target.value,
        label,
      });
    }
    setActiveFilters({ ...activeFilters, [prop]: actFilters });
  };
  const deleteActiveFilter = (array, index) => {
    activeFilters[array].splice(index, 1);
    if (activeFilters[array].length === 0) {
      document.getElementById(array).value = "ALL";
      activeFilters[array] = [];
    }
    setActiveFilters({
      ...activeFilters,
    });
  };
  const renderGeneralSelect = (catalogue) => {
    let option = [];
    if (catalogues[catalogue] !== undefined) {
      catalogues[catalogue].forEach((item, i) => {
        option.push(
          <option
            key={i}
            value={catalogue !== "countries" ? item.id : item.name}
          >
            {catalogue !== "countries" ? (catalogue === "agents")? `${item.firstName} ${item.lastName}` : item.name : getCountries()[item.name]}
          </option>
        );
      });
    }
    return option;
  };
  const generalData = (data, filter) => {
    let aux = data.filter((item) => {
      let a = Object.keys(item).map((element) => {
        if (typeof element !== "object") {
          if (
            item[element] &&
            item[element]
              .toString()
              .toLowerCase()
              .includes(filter.toLowerCase())
          ) {
            return true;
          }
          return false;
        }
        return false;
      });
      if (inArray(true, a)) {
        return true;
      }
      return false;
    });
    return aux;
  };

  /* Transaction Table */
  const transactionColumns = [
    {
      name: props.boot.table.idTrans,
      selector: "idCrmTransaction",
      sortable: true,
      center: true,
      minWidth: "180px",
    },
    {
      name: "TP ID",
      selector: "tpId",
      sortable: true,
      center: true,
      minWidth: "140px",
    },
    {
      name: props.boot.login.email,
      selector: "email",
      sortable: true,
      center: true,
      minWidth: "200px"
    },
    {
      name: props.boot.table.country,
      selector: "country",
      sortable: true,
      center: true,
    },
    {
      name: props.boot.table.businessUnit,
      selector: "businessUnit",
      sortable: true,
      center: true,
      minWidth: "140px",
    },
    {
      name: props.boot.table.pspName,
      selector: "pspName",
      sortable: true,
      center: true,
      minWidth: "140px",
    },
    {
      name: props.boot.table.dpto,
      selector: "deparment",
      sortable: true,
      center: true,
      minWidth: "140px",
    },
    {
      name: "KYC",
      selector: "kyc",
      sortable: true,
      center: true,
    },
    {
      name: props.boot.table.depositDate,
      selector: "depositeDate",
      sortable: true,
      center: true,
      minWidth: "190px",
    },
    {
      name: "",
      selector: "",
      sortable: true,
      center: true,
      cell: (row, index) => (
        <>
          {inArray(
            "DXiwd2NWbKyQdCd5oOTRzzYPq2Cx3WWJBAABWLErDtC6BHKCnphs45QHbP8oqFHs",
            JSON.parse(localStorage.getItem("permissions"))) &&
            row.pspName.toLowerCase().search('tradingclub') !== -1 ? (
            <label>
              <button
                type="button"
                id={"appTransaction" + row.id}
                onClick={onClickdownloadDocument("receipt", row)}
                className="table__action"
              >
                <img
                  className="table__icon"
                  src={DownloadIcon}
                  alt="ACUSE DE RECIBO"
                />
              </button>
              <p style={{ margin: 0 }}>
                {`ACUSE`}
              </p>
            </label>
          ) : (
            <></>
          )}
          {inArray(
            "DXiwd2NWbKyQdCd5oOTRzzYPq2Cx3WWJBAABWLErDtC6BHKCnphs45QHbP8oqFHs",
            JSON.parse(localStorage.getItem("permissions"))) &&
            row.pspName.toLowerCase().search('tradingclub') !== -1 ? (
            <label>
              <button
                type="button"
                id={"appTransaction" + row.id}
                onClick={onClickdownloadDocument("order", row)}
                className="table__action"
              >
                <img
                  className="table__icon"
                  src={DownloadIcon}
                  alt="ORDEN DE PAGO"
                />
              </button>
              <p style={{ margin: 0 }}>
                {`ORDEN`}
              </p>
            </label>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];
  const TransFilter = ({ onFilter }) => (
    <div className="table__header">
      <input
        id="searchAffDetail"
        type="text"
        placeholder={props.boot.table.filter}
        aria-label="Search Input"
        onChange={onFilter}
        className="table__filter"
      />
    </div>
  );
  const transactionComponent = useMemo(() => {
    return <TransFilter onFilter={(e) => setTransSearch(e.target.value)} />;
  }, []);

  /* Clients Table */
  const clientsColumns = [
    {
      name: "TP ID",
      selector: "tpId",
      sortable: true,
      center: true,
      minWidth: "180px",
    },
    {
      name: props.boot.compliance.assignedTo,
      selector: "agent",
      sortable: true,
      center: true,
      minWidth: "180px",
    },
    {
      name: props.boot.table.name,
      selector: "firstName",
      sortable: true,
      
      minWidth: "300px",
      cell: ({ firstName, lastName, tpId }) => (
        <Link to={`/compliance/user/${tpId}`} className="table__link"><div data-tag="allowRowEvents">{`${firstName.toUpperCase()} ${lastName.toUpperCase()}`}</div></Link>
      )
    },
    {
      name: props.boot.login.email,
      selector: "email",
      sortable: true,
      minWidth: "300px",
      cell: ({ email, tpId }) => (
        <Link to={`/compliance/user/${tpId}`} className="table__link"><div data-tag="allowRowEvents">{email}</div></Link>
      )
    },
    {
      name: props.boot.table.businessUnit,
      selector: "businessUnit",
      sortable: true,
      center: true,
      minWidth: "180px",
    },
    {
      name: props.boot.table.dpto,
      selector: "department",
      sortable: true,
      center: true,
      minWidth: "180px",
    },
    {
      name: props.boot.table.ftdDate,
      selector: "ftdDate",
      sortable: true,
      center: true,
      minWidth: "180px",
    },
    {
      name: "POIFRONT",
      selector: "POIFront",
      center: true,

      cell: (row) => (
        <>
          {row.POIFront ? (
            <img src={CheckIcon} alt="Check" className="table__icon" />
          ) : (
            <img src={MarkIcon} alt="Check" className="table__icon" />
          )}
        </>
      ),
    },
    {
      name: "POIBACK",
      selector: "POIBack",
      center: true,

      cell: (row) => (
        <>
          {row.POIBack ? (
            <img src={CheckIcon} alt="Check" className="table__icon" />
          ) : (
            <img src={MarkIcon} alt="Check" className="table__icon" />
          )}
        </>
      ),
    }
    ,{
      name: "POR",
      selector: "POR",
      center: true,

      cell: (row) => (
        <>
          {row.POR ? (
            <img src={CheckIcon} alt="Check" className="table__icon" />
          ) : (
            <img src={MarkIcon} alt="Check" className="table__icon" />
          )}
        </>
      ),
    },
    // {
    //   name: "CCFRONT",
    //   selector: "CCFront",
    //   center: true,

    //   cell: (row) => (
    //     <>
    //       {row.CCFront ? (
    //         <img src={CheckIcon} alt="Check" className="table__icon" />
    //       ) : (
    //         <img src={MarkIcon} alt="Check" className="table__icon" />
    //       )}
    //     </>
    //   ),
    // },
    // {
    //   name: "CCBACK",
    //   selector: "CCBack",
    //   center: true,

    //   cell: (row) => (
    //     <>
    //       {row.CCBack ? (
    //         <img src={CheckIcon} alt="Check" className="table__icon" />
    //       ) : (
    //         <img src={MarkIcon} alt="Check" className="table__icon" />
    //       )}
    //     </>
    //   ),
    // }
    ,{
      name: "DOD",
      selector: "DOD",
      center: true,

      cell: (row) => (
        <>
          {row.DOD ? (
            <img src={CheckIcon} alt="Check" className="table__icon" />
          ) : (
            <img src={MarkIcon} alt="Check" className="table__icon" />
          )}
        </>
      ),
    },
    {
      name: "POP",
      selector: "POP",
      center: true,

      cell: (row) => (
        <>
          {row.POP ? (
            <img src={CheckIcon} alt="Check" className="table__icon" />
          ) : (
            <img src={MarkIcon} alt="Check" className="table__icon" />
          )}
        </>
      ),
    },
  ];
  const ClientsFilter = ({ onFilter }) => (
    <div className="table__header">
      {
        (inArray(
          "5F6Pg7tCAFomq2dK2Qrf5ACtDLdlFEXh2vFQUWVVqOd183Am2LvWLuwagAbl1r0L",
          JSON.parse(localStorage.getItem("permissions"))
        ))?
        <button className="table__button table__button--primary" onClick={() => setAgentsAssign(!agentsAssign)}>
        {props.boot.compliance.assignClients}
        </button> : null
      }
      <input
        id="searchAffDetail"
        type="text"
        placeholder={props.boot.table.filter}
        aria-label="Search Input"
        onChange={onFilter}
        className="table__filter"
      />
    </div>
  );
  const clientsComponent = useMemo(() => {
    return <ClientsFilter onFilter={(e) => setClientsSearch(e.target.value)} />;
  }, [agentsAssign]);

  /* Agents Table */
  const agentsColumns = [
    {
      name: props.boot.table.agent,
      selector: "userName",
      sortable: true,
      center: true,
      minWidth: "180px",
    },
    {
      name: props.boot.compliance.clients,
      selector: "clients",
      sortable: true,
      center: true,
      minWidth: "180px",
    },
    {
      name: props.boot.table.approvedDocuments,
      selector: "approvedDocs",
      sortable: true,
      center: true,
      minWidth: "180px",
    },
    {
      name: props.boot.table.rejectedDocuments,
      selector: "rejectedDocs",
      sortable: true,
      center: true,
      minWidth: "180px",
    },
    {
      name: props.boot.table.pendingDocuments,
      selector: "pendingDocs",
      sortable: true,
      center: true,
      minWidth: "180px",
    }
  ];

  const AgentsFilter = ({ onFilter }) => (
    <div className="table__header">
      <input
        id="searchAffDetail"
        type="text"
        placeholder={props.boot.table.filter}
        aria-label="Search Input"
        onChange={onFilter}
        className="table__filter"
      />
    </div>
  );
  const agentsComponent = useMemo(() => {
    return <AgentsFilter onFilter={(e) => setAgentsSearch(e.target.value)} />;
  }, []);
  const handleAssign = async() =>{
    const select = document.getElementById("agents");
    const agent = select.value;
    const agentName = select.options[select.selectedIndex].text;
    if (clientsAssignInfo.length === 0) {
      swal(props.boot.compliance.swalErrorAssign, {
        icon: "error",
      });
    } else if (agentName === "All") {
      swal(props.boot.compliance.swalErrorAssignAgent, {
        icon: "error",
      });
    } else {
      const clients = clientsAssignInfo.map((item) => item.tpId);
      const params = {
          action: "updateAgent",
          agent: agent,
          tpId: clients
      };
      const response = await fetch(getBaseApi() + "/manage/Compliance", {
        method: "PUT",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("sessionId"),
          "Accept-Language": localStorage.getItem("moisesLang")
        }
      });
      const { data, result, error } = await response.json();
      if (result) {
        swal({
          title: `${props.boot.compliance.swalConfirmAssignAgent} ${agentName}?`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            setAgentsAssign(!agentsAssign)
            setClientsAssignInfo([]);
            setAux(!aux);
            swal(props.boot.compliance.swalAssignSuccess, {
              icon: "success",
            });
          } else {
            swal(props.boot.compliance.swalAssignCancel);
          }
        });
      } else {
        // console.log(error);
        swal({
          icon: "error",
          text: props.boot.compliance.swalAssignError,
        });
      } 
    }
  };
  /* Clients to assign table */
  const clientsAssign = [
    {
      name: "TP ID",
      selector: "tpId",
      sortable: true,
      center: true,
      minWidth: "180px",
    },
    {
      name: props.boot.login.email,
      selector: "email",
      sortable: true,
      center: true,
      minWidth: "180px",
    }
    ,{
      name: props.boot.table.country,
      selector: "country",
      sortable: true,
      center: true,
      minWidth: "180px",
    }
    ,{
      name: props.boot.compliance.assignedTo,
      selector: "agent",
      sortable: true,
      center: true,
      minWidth: "180px",
    },
    {
      name: props.boot.table.ftdDate,
      selector: "ftdDate",
      sortable: true,
      center: true,
      minWidth: "180px",
    },
  ];
  const getInfoTransaction = async(params = filter) => {
    setLoading(true);
    const response = await fetch(getBaseApi() + "/manage/Compliance" +
    convertToQuery(params), {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("sessionId"),
        "Accept-Language": localStorage.getItem("moisesLang")
      }
    });
    const {result, data, error} = await response.json();
    if (result) {
      setTransactionInfo(data.compliance);
      setCatalogues(data.catalogues);
    } else if (error === "EISN"){
      props.history.push("/welcome");
    } else {
      console.log(error);
    }
    setLoading(false);
  };
  const getCustomersDocuments = async(params = filter) => {
    setLoading(true);
    const response = await fetch(getBaseApi() + "/manage/Compliance" +
    convertToQuery(params), {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("sessionId"),
        "Accept-Language": localStorage.getItem("moisesLang")
      }
    });
    const {result, data, error} = await response.json();
    if (result) {
      setCatalogues(data.catalogues);
      const dataDocument = data.compliance.map((item) => {
       return ({
         ...item,
         POR: item.files.includes("POR") ? 1 : 0,
         POIFront: item.files.includes("POIFront") ? 1 : 0,
         POIBack: item.files.includes("POIBack") ? 1 : 0,
         CCFront: item.files.includes("CCFront") ? 1 : 0,
         CCBack: item.files.includes("CCBack") ? 1 : 0,
         DOD: item.files.includes("DOD") ? 1 : 0,
         POP: item.files.includes("POP") ? 1 : 0,
         agent: (item.agent !== null) ? `${item.agentFirstName} ${item.agentLastName}` : "-" 
       }); 
      });
      setClientsInfo(dataDocument);
    } else if (error === "EISN"){
      props.history.push("/welcome");
    } else {
      console.log(error);
    }
    setLoading(false);
  };
  const getAgentsInfo = async() => {
    setLoading(true);
    const response = await fetch(getBaseApi() + "/manage/Compliance?action=agentInfo", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("sessionId"),
        "Accept-Language": localStorage.getItem("moisesLang")
      }
    });
    const {result, data, error} = await response.json();
    if (result) {
      setAgentsInfo(data);
    } else if (error === "EISN"){
      props.history.push("/welcome");
    } else {
      console.log(error);
    }
    setLoading(false);
  };
  const getCharts = async() => {
    const response = await fetch(getBaseApi() + "/manage/Compliance?action=Charts", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("sessionId"),
        "Accept-Language": localStorage.getItem("moisesLang")
      }
    });
    const { result, data, error } = await response.json();
    if (result) {
      setCharts({
        POR: data.docs.filter((item) => item.crmCode === "POR")[0],
        POP: data.docs.filter((item) => item.crmCode === "POP")[0],
        POIBack: data.docs.filter((item) => item.crmCode === "POIBack")[0],
        CCBack: data.docs.filter((item) => item.crmCode === "CCBack")[0],
        POIFront: data.docs.filter((item) => item.crmCode === "POIFront")[0],
        CCFront: data.docs.filter((item) => item.crmCode === "CCFront")[0],
        kyc: data.kyc,
        totalFtd: data.totalFtd
      });
    } else if (error === "EISN"){
      props.history.push("/welcome");
    } else {
      console.log(error);
    }
  };
  useEffect(() => {
    getCharts();
  }, []);
  useEffect(() => {
    if (
      !inArray(
        "pZ6DGyY9jMvvBwMdCPdJdxqU9zfQUhZM30opcLX4g6QxTSdWDwB84TKVoe1Zc9NX",
        JSON.parse(localStorage.getItem("permissions"))
      )
    ) {
      props.history.push("/welcome");
    }
    if (activeBlock === "transaction") {
      getInfoTransaction();
    } else if (activeBlock === "clients") {
      getCustomersDocuments();
    } else if (activeBlock === "agents") {
      getAgentsInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aux]);
  useEffect(() => {
    if (activeBlock === "transaction") {
      setFilters(false);
      setFilter(initialTransaction);
      setActiveFilters({});
      getInfoTransaction(initialTransaction);
    } else if (activeBlock === "clients") {
      setFilters(false);
      setFilter(initialCustomer);
      setActiveFilters({});
      getCustomersDocuments(initialCustomer);
    } else if (activeBlock === "agents") {
      setFilters(false);
      setFilter({});
      setActiveFilters({});
      getAgentsInfo();
    }
  }, [activeBlock]);
  if (loading) {
    return (
      <>
        <Sidebar
          boot={props.boot}
          function={props.language}
        />
        <div className="loading">
          <div className="loading__data">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <Sidebar boot={props.boot} function={props.language} />
      <main className="general">
        <Header boot={props.boot} title="Compliance" function={props.language} />
        <section className="compliance">
          <div className="tabs">
            <button
              onClick={() => setActiveBlock("transaction")}
              className={
                "tabs__switch tabs__switch" +
                (activeBlock === "transaction" && "--active")
              }
            >
              {props.boot.compliance.transaction}
            </button>
            <button
              onClick={() => setActiveBlock("clients")}
              className={
                "tabs__switch tabs__switch" +
                (activeBlock === "clients" && "--active")
              }
            >
              {props.boot.compliance.clients}
            </button>
            {
              (inArray(
                "Ga7Gcg0CA250DkWAYSzrbIgqw6Mocu4Wyqdr9ApHttSp4DyUpEckI6EybLsE9eDe",
                JSON.parse(localStorage.getItem("permissions"))
              ))?
              <button
              onClick={() => setActiveBlock("agents")}
              className={
                "tabs__switch tabs__switch" +
                (activeBlock === "agents" && "--active")
                }
              >
                {props.boot.compliance.agents}
              </button>
            : null
            }
          </div>
          <div className="compliance__tables">
            {activeBlock === "transaction" && (
              <div className="compliance__transaction">
                <div className="table">
                  <div className="table__initial">
                    <h4 className="table__title">
                      {props.boot.compliance.transaction}
                    </h4>
                    <span className="table__operations">
                      <button
                        onClick={() => setAux(!aux)}
                        className="table__action table__action--upper"
                      >
                        <img
                          src={ReloadIcon}
                          alt="Reload"
                          className="table__icon table__icon--upper"
                        />
                      </button>
                      {inArray(
                        "crdJr3ERYBtE43DUDxf1Gka1Vmw2FNfNptyUEiKVUsD3ahvCkWaneMjbzjnLJ8ht",
                        JSON.parse(localStorage.getItem("permissions"))
                      ) && (
                        <button
                          onClick={() => onClickDownloadTransaction()}
                          className="table__action table__action--upper"
                        >
                          <img
                            src={DownloadIcon}
                            alt=""
                            className="table__icon table__icon--upper"
                          />
                        </button>
                      )}
                      {/* <button onClick={() => onClickPrintInfoAffico()} className="table__action table__action--upper">
          <span style={{ color: "#fff"}}>
            {props.boot.table.print}
          </span>
        </button> */}
                      <button
                        onClick={() => {
                          setFilters(!filters);
                          // setActiveFilters({
                          //   country: Array.isArray(filter.country)
                          //     ? activeFilters.country.filter((e) => {
                          //         return filter.country.indexOf(e.id) >= 0;
                          //       })
                          //     : [],
                          //   affiliate: Array.isArray(filter.affiliate)
                          //     ? activeFilters.affiliate.filter((e) => {
                          //         return filter.affiliate.indexOf(e.id) >= 0;
                          //       })
                          //     : [],
                          //   businessUnit: Array.isArray(filter.businessUnit)
                          //     ? activeFilters.businessUnit.filter((e) => {
                          //         return (
                          //           filter.businessUnit.indexOf(e.id) >= 0
                          //         );
                          //       })
                          //     : [],
                          // });
                        }}
                        className="table__action table__action--upper"
                      >
                        <img
                          src={FiltersIcon}
                          alt=""
                          className="table__icon table__icon--upper"
                        />
                      </button>
                    </span>
                  </div>
                  {filters && (
                    <>
                      <div className="hidden">
                        <h2 className="hidden__title">
                          {props.boot.affico.filters}
                        </h2>
                        <form onSubmit={(e) => handleChangeFilters(e)}>
                          <div className="hidden__grid hidden__grid--five">
                            <article className="hidden__item">
                              <label
                                className="hidden__label"
                                htmlFor="businessUnit"
                              >
                                {props.boot.table.businessUnit}
                              </label>
                              <select
                                defaultValue={
                                  filter.businessUnit? filter.businessUnit === "ALL"
                                    ? filter.businessUnit
                                    : filter.businessUnit.split(",")[
                                        filter.businessUnit.split(",").length - 1
                                      ]
                                  : "ALL"    
                                }
                                onChange={handleChange("businessUnit")}
                                className="hidden__input"
                                name="businessUnit"
                                id="businessUnit"
                                type="text"
                              >
                                <option value="ALL">
                                  {props.boot.general.all}
                                </option>
                                {renderGeneralSelect("businessUnits")}
                              </select>
                            </article>
                            <article className="hidden__item">
                              <label
                                className="hidden__label"
                                htmlFor="affiliate"
                              >
                                {props.boot.table.affiliate}
                              </label>
                              <select
                                defaultValue={
                                  filter.affiliate? filter.affiliate === "ALL"
                                    ? filter.affiliate
                                    : filter.affiliate.split(",")[
                                        filter.affiliate.split(",").length - 1
                                      ]
                                  : "ALL"    
                                }
                                onChange={handleChange("affiliate")}
                                className="hidden__input"
                                name="affiliates"
                                id="affiliate"
                                type="text"
                              >
                                <option value="ALL">
                                  {props.boot.general.all}
                                </option>
                                {renderGeneralSelect("affiliates")}
                              </select>
                            </article>
                            <article className="hidden__item">
                              <label
                                className="hidden__label"
                                htmlFor="country"
                              >
                                {props.boot.table.country}
                              </label>
                              <select
                              defaultValue={
                                filter.country? filter.country === "ALL"
                                  ? filter.country
                                  : filter.country.split(",")[
                                      filter.country.split(",").length - 1
                                    ]
                                : "ALL"    
                              }
                                id="country"
                                className="hidden__input"
                                onChange={handleChange("country")}
                              >
                                <option value="ALL">
                                  {props.boot.general.all}
                                </option>
                                {renderGeneralSelect("countries")}
                              </select>
                            </article>
                            <article className="hidden__item">
                              <label
                                className="hidden__label"
                                htmlFor="departments"
                              >
                                {props.boot.table.transactionDepartment}
                              </label>
                              <select
                                defaultValue={
                                  filter.department? filter.department === "ALL"
                                    ? filter.department
                                    : filter.department.split(",")[
                                        filter.department.split(",").length - 1
                                      ]
                                  : "ALL"    
                                }
                                name="departments"
                                id="department"
                                className="hidden__input"
                                onChange={handleChange("department")}
                              >
                                <option value="ALL">
                                  {props.boot.general.all}
                                </option>
                                <option value="FTD">
                                   FTD 
                                </option>
                                <option value="RET">
                                   RET
                                </option>
                                {renderGeneralSelect("departments")}
                              </select>
                            </article>
                            <article className="hidden__item">
                              <label className="hidden__label" htmlFor="psp">
                                PSP
                              </label>
                              <select
                                defaultValue={
                                  filter.psp? filter.psp === "ALL"
                                    ? filter.psp
                                    : filter.psp.split(",")[
                                        filter.psp.split(",").length - 1
                                      ]
                                  : "ALL"    
                                }
                                id="psp"
                                name="psp"
                                className="hidden__input"
                                onChange={handleChange("psp")}
                              >
                                <option value="ALL">
                                  {props.boot.general.all}
                                </option>
                                {renderGeneralSelect("psp")}
                              </select>
                            </article>
                            <article className="hidden__item">
                              <label className="hidden__label" htmlFor="">
                                {props.boot.affico.date}
                              </label>
                              <select
                                defaultValue={filter.dateType}
                                name="date"
                                id="dateType"
                                className="hidden__select"
                                onChange={(e) => modifyFilterState(e)}
                              >
                                <option value="Today">
                                  {props.boot.range.today}
                                </option>
                                <option value="Yesterday">
                                  {props.boot.range.yesterday}
                                </option>
                                <option value="This Week">
                                  {props.boot.range.thisWeek}
                                </option>
                                <option value="Last Week">
                                  {props.boot.range.lastWeek}
                                </option>
                                <option value="This Month">
                                  {props.boot.range.thisMonth}
                                </option>
                                <option value="Last Month">
                                  {props.boot.range.lastMonth}
                                </option>
                                <option value="This Year">
                                  {props.boot.range.thisYear}
                                </option>
                                <option value="Range">
                                  {props.boot.range.range}
                                </option>
                              </select>
                            </article>
                            <article className="hidden__item">
                              <DatePicker
                                disableToolbar
                                disabled={typeDate}
                                style={{ width: "100%" }}
                                format="yyyy-MM-dd"
                                label={props.boot.affico.dateStart}
                                value={filter.dateStart}
                                onChange={(date) =>
                                  setFilter({ ...filter, dateStart: date })
                                }
                              />
                            </article>
                            <article className="hidden__item">
                              <DatePicker
                                disableToolbar
                                disabled={typeDate}
                                style={{ width: "100%" }}
                                format="yyyy-MM-dd"
                                label={props.boot.affico.dateEnd}
                                value={filter.dateEnd}
                                onChange={(date) =>
                                  setFilter({ ...filter, dateEnd: date })
                                }
                              />
                            </article>
                          </div>
                          <div className="hidden__actions">
                            <button
                              onClick={() => setFilters(false)}
                              type="button"
                              className="hidden__button hidden__button--cancel"
                            >
                              {props.boot.general.cancel}
                            </button>
                            <button
                              type="submit"
                              className="hidden__button hidden__button--save"
                            >
                              {props.boot.general.save}
                            </button>
                          </div>
                        </form>
                      </div>
                      <div className="filters">
                        <p className="filters__title">
                          {props.boot.affico.activeFilters}:
                        </p>
                        {renderActiveFilter()}
                      </div>
                    </>
                  )}
                  <DataTable
                    columns={transactionColumns}
                    data={generalData(transactionInfo, transSearch)}
                    className="table__container"
                    pagination
                    noHeader
                    subHeader
                    subHeaderComponent={transactionComponent}
                  />
                </div>
              </div>
            )}
            {activeBlock === "clients" && (
              <div className="compliance__clients">
                <div className="table">
                  {agentsAssign && (
                    <div className="compliance__assign">
                      <h5 className="compliance__assign-title">
                        {props.boot.compliance.assignClients}
                      </h5>
                      <div className="table table--assign">
                        <DataTable
                          columns={clientsAssign}
                          data={clientsAssignInfo}
                          className="table__container"
                          noHeader
                        />
                        <div className="compliance__assign-actions">
                          <article className="hidden__item hidden__item--small">
                            <label className="hidden__label" htmlFor="agents">
                              {props.boot.compliance.agents}
                            </label>
                            <select 
                              className="hidden__input"
                              defaultValue=""
                              name="agents"
                              id="agents"
                              type="text"
                            >
                              <option value="" disabled>
                                {props.boot.table.agent}
                              </option>
                            {
                              catalogues.agents.map((item, index) => {
                                return(
                                  <option value={item.id} key={index}>
                                    {`${item.firstName} ${item.lastName}`}
                                  </option>
                                )
                              })
                            }
                            </select>
                          </article>
                          <div className="compliance__assign-buttons">
                            <button
                              type="button"
                              id="btn-cancel-assign"
                              className="compliance__assign-button compliance__assign-button--cancel"
                              onClick={() => {
                                setAgentsAssign(!agentsAssign);
                                setClientsAssignInfo([]);
                              }}
                            >
                              {props.boot.general.cancel}
                            </button>
                            <button
                              type="button"
                              id="btn-save-assign"
                              className="compliance__assign-button compliance__assign-button--save"
                              onClick={handleAssign}
                            >
                              {props.boot.general.save}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="table__initial">
                    <h4 className="table__title">
                      {props.boot.compliance.clients}
                    </h4>
                    <span className="table__operations">
                      <button
                        onClick={() => setAux(!aux)}
                        className="table__action table__action--upper"
                      >
                        <img
                          src={ReloadIcon}
                          alt="Reload"
                          className="table__icon table__icon--upper"
                        />
                      </button>
                      {inArray(
                        "crdJr3ERYBtE43DUDxf1Gka1Vmw2FNfNptyUEiKVUsD3ahvCkWaneMjbzjnLJ8ht",
                        JSON.parse(localStorage.getItem("permissions"))
                      ) && (
                        <button
                          onClick={() => onClickDownloadClients()}
                          className="table__action table__action--upper"
                        >
                          <img
                            src={DownloadIcon}
                            alt=""
                            className="table__icon table__icon--upper"
                          />
                        </button>
                      )}
                      {/* <button onClick={() => onClickPrintInfoAffico()} className="table__action table__action--upper">
         <span style={{ color: "#fff"}}>
           {props.boot.table.print}
         </span>
       </button> */}
                      <button
                        onClick={() => {
                          setFilters(!filters);
                          // setActiveFilters({
                          //   country: Array.isArray(filter.country)
                          //     ? activeFilters.country.filter((e) => {
                          //         return filter.country.indexOf(e.id) >= 0;
                          //       })
                          //     : [],
                          //   agents: Array.isArray(filter.agents)
                          //     ? activeFilters.agents.filter((e) => {
                          //         return filter.agents.indexOf(e.id) >= 0;
                          //       })
                          //     : [],
                          //   businessUnit: Array.isArray(filter.businessUnit)
                          //     ? activeFilters.businessUnit.filter((e) => {
                          //         return (
                          //           filter.businessUnit.indexOf(e.id) >= 0
                          //         );
                          //       })
                          //     : [],
                          // });
                        }}
                        className="table__action table__action--upper"
                      >
                        <img
                          src={FiltersIcon}
                          alt=""
                          className="table__icon table__icon--upper"
                        />
                      </button>
                    </span>
                  </div>
                  {filters && (
                    <>
                      <div className="hidden">
                        <h2 className="hidden__title">
                          {props.boot.affico.filters}
                        </h2>
                        <form onSubmit={(e) => handleChangeFilters(e)}>
                          <div className="hidden__grid hidden__grid--five">
                            <article className="hidden__item">
                              <label
                                className="hidden__label"
                                htmlFor="businessUnit"
                              >
                                {props.boot.table.businessUnit}
                              </label>
                              <select
                                defaultValue={
                                  filter.businessUnit? filter.businessUnit === "ALL"
                                    ? filter.businessUnit
                                    : filter.businessUnit.split(",")[
                                        filter.businessUnit.split(",").length - 1
                                      ]
                                  : "ALL"    
                                }
                                defaultValue={filter.businessUnits}
                                onChange={handleChange("businessUnit")}
                                className="hidden__input"
                                name="businessUnit"
                                id="businessUnit"
                                type="text"
                              >
                                <option value="ALL">
                                  {props.boot.general.all}
                                </option>
                                {renderGeneralSelect("businessUnits")}
                              </select>
                            </article>
                            <article className="hidden__item">
                              <label
                                className="hidden__label"
                                htmlFor="country"
                              >
                                {props.boot.table.country}
                              </label>
                              <select
                                defaultValue={
                                  filter.country? filter.country === "ALL"
                                    ? filter.country
                                    : filter.country.split(",")[
                                        filter.country.split(",").length - 1
                                      ]
                                  : "ALL"    
                                }
                                id="country"
                                className="hidden__input"
                                onChange={handleChange("country")}
                              >
                                <option value="ALL">
                                  {props.boot.general.all}
                                </option>
                                {renderGeneralSelect("countries")}
                              </select>
                            </article>
                            <article className="hidden__item">
                              <label
                                className="hidden__label"
                                htmlFor="departments"
                              >
                                {props.boot.table.transactionDepartment}
                              </label>
                              <select
                                defaultValue={
                                  filter.transactionDepartment? filter.transactionDepartment === "ALL"
                                    ? filter.transactionDepartment
                                    : filter.transactionDepartment.split(",")[
                                        filter.transactionDepartment.split(",").length - 1
                                      ]
                                  : "ALL"    
                                }
                                name="departments"
                                id="department"
                                className="hidden__input"
                                onChange={handleChange("department")}
                              >
                                <option value="ALL">
                                  {props.boot.general.all}
                                </option>
                                <option value="FTD">
                                   FTD 
                                </option>
                                <option value="RET">
                                   RET
                                </option>
                              </select>
                              </article>
                             <article className="hidden__item">
                              <label
                                className="hidden__label"
                                htmlFor="assigned"
                              >
                                {props.boot.compliance.assignedTo}
                              </label>
                              <select
                                defaultValue={
                                  filter.agent? filter.agent === "ALL"
                                    ? filter.agent
                                    : filter.agent.split(",")[
                                        filter.agent.split(",").length - 1
                                      ]
                                  : "ALL"    
                                }
                                id="agents"
                                name="agents"
                                className="hidden__input"
                                onChange={handleChange("agents")}
                              >
                                <option value="ALL">
                                  {props.boot.general.all}
                                </option>
                                {renderGeneralSelect("agents")}
                              </select>
                            </article>

                            <article className="hidden__item">
                              <label className="hidden__label" htmlFor="">
                                {props.boot.affico.date}
                              </label>
                              <select
                                defaultValue={filter.dateType}
                                name="date"
                                id="dateType"
                                className="hidden__select"
                                onChange={(e) => modifyFilterState(e)}
                              >
                                <option value="Today">
                                  {props.boot.range.today}
                                </option>
                                <option value="Yesterday">
                                  {props.boot.range.yesterday}
                                </option>
                                <option value="This Week">
                                  {props.boot.range.thisWeek}
                                </option>
                                <option value="Last Week">
                                  {props.boot.range.lastWeek}
                                </option>
                                <option value="This Month">
                                  {props.boot.range.thisMonth}
                                </option>
                                <option value="Last Month">
                                  {props.boot.range.lastMonth}
                                </option>
                                <option value="This Year">
                                  {props.boot.range.thisYear}
                                </option>
                                <option value="Range">
                                  {props.boot.range.range}
                                </option>
                              </select>
                            </article>
                            <article className="hidden__item">
                              <DatePicker
                                disableToolbar
                                disabled={typeDate}
                                style={{ width: "100%" }}
                                format="yyyy-MM-dd"
                                label={props.boot.affico.dateStart}
                                value={filter.dateStart}
                                onChange={(date) =>
                                  setFilter({ ...filter, dateStart: date })
                                }
                              />
                            </article>
                            <article className="hidden__item">
                              <DatePicker
                                disableToolbar
                                disabled={typeDate}
                                style={{ width: "100%" }}
                                format="yyyy-MM-dd"
                                label={props.boot.affico.dateEnd}
                                value={filter.dateEnd}
                                onChange={(date) =>
                                  setFilter({ ...filter, dateEnd: date })
                                }
                              />
                            </article>
                          </div>
                          <div className="hidden__actions">
                            <button
                              onClick={() => setFilters(false)}
                              type="button"
                              className="hidden__button hidden__button--cancel"
                            >
                              {props.boot.general.cancel}
                            </button>
                            <button
                              type="submit"
                              className="hidden__button hidden__button--save"
                            >
                              {props.boot.general.save}
                            </button>
                          </div>
                        </form>
                      </div>
                      <div className="filters">
                        <p className="filters__title">
                          {props.boot.affico.activeFilters}:
                        </p>
                        {renderActiveFilter()}
                      </div>
                    </>
                  )}
                  <DataTable
                    columns={clientsColumns}
                    data={generalData(clientsInfo, clientsSearch)}
                    className="table__container"
                    pagination
                    noHeader
                    subHeader
                    selectableRows
                    onSelectedRowsChange = {(e)=>{
                      const dataFormat = e.selectedRows.map((item) => {  
                        return({
                          ...item,
                          agent: item.agent!==null ? item.agent : "not assigned"  
                        });
                      });
                      setClientsAssignInfo(dataFormat);
                    }}
                    subHeaderComponent={clientsComponent}
                  />
                </div>
              </div>
            )}
            {activeBlock === "agents" && (
              <div className="compliance__agents">
                <div className="table">
                  <div className="table__initial">
                    <h4 className="table__title">
                      {props.boot.compliance.agents}
                    </h4>
                    <span className="table__operations">
                      <button
                        onClick={() => setAux(!aux)}
                        className="table__action table__action--upper"
                      >
                        <img
                          src={ReloadIcon}
                          alt="Reload"
                          className="table__icon table__icon--upper"
                        />
                      </button>
                      {inArray(
                        "crdJr3ERYBtE43DUDxf1Gka1Vmw2FNfNptyUEiKVUsD3ahvCkWaneMjbzjnLJ8ht",
                        JSON.parse(localStorage.getItem("permissions"))
                      ) && (
                        <button
                          onClick={() => onClickDownloadAgents()}
                          className="table__action table__action--upper"
                        >
                          <img
                            src={DownloadIcon}
                            alt=""
                            className="table__icon table__icon--upper"
                          />
                        </button>
                      )}
                      {/* <button
                        onClick={() => {
                          setFilters(!filters);
                        }}
                        className="table__action table__action--upper"
                      >
                        <img
                          src={FiltersIcon}
                          alt=""
                          className="table__icon table__icon--upper"
                        />
                      </button> */}
                    </span>
                  </div>
                  {/* {filters && (
                    <>
                      <div className="hidden">
                        <h2 className="hidden__title">
                          {props.boot.affico.filters}
                        </h2>
                        <form onSubmit={(e) => handleChangeFilters(e)}>
                          <div className="hidden__grid hidden__grid--five">
                            <article className="hidden__item">
                              <label className="hidden__label" htmlFor="">
                                {props.boot.affico.date}
                              </label>
                              <select
                                name="date"
                                id="dateType"
                                className="hidden__select"
                                onChange={(e) => modifyFilterState(e)}
                                defaultValue={filter.dateType}
                              >
                                <option value="Today">
                                  {props.boot.range.today}
                                </option>
                                <option value="Yesterday">
                                  {props.boot.range.yesterday}
                                </option>
                                <option value="This Week">
                                  {props.boot.range.thisWeek}
                                </option>
                                <option value="Last Week">
                                  {props.boot.range.lastWeek}
                                </option>
                                <option value="This Month">
                                  {props.boot.range.thisMonth}
                                </option>
                                <option value="Last Month">
                                  {props.boot.range.lastMonth}
                                </option>
                                <option value="This Year">
                                  {props.boot.range.thisYear}
                                </option>
                                <option value="Range">
                                  {props.boot.range.range}
                                </option>
                              </select>
                            </article>
                            <article className="hidden__item">
                              <DatePicker
                                disableToolbar
                                disabled={typeDate}
                                style={{ width: "100%" }}
                                format="yyyy-MM-dd"
                                label={props.boot.affico.dateStart}
                                value={filter.dateStart}
                                onChange={(date) =>
                                  setFilter({ ...filter, dateStart: date })
                                }
                              />
                            </article>
                            <article className="hidden__item">
                              <DatePicker
                                disableToolbar
                                disabled={typeDate}
                                style={{ width: "100%" }}
                                format="yyyy-MM-dd"
                                label={props.boot.affico.dateEnd}
                                value={filter.dateEnd}
                                onChange={(date) =>
                                  setFilter({ ...filter, dateEnd: date })
                                }
                              />
                            </article>
                          </div>
                          <div className="hidden__actions">
                            <button
                              onClick={() => setFilters(false)}
                              type="button"
                              className="hidden__button hidden__button--cancel"
                            >
                              {props.boot.general.cancel}
                            </button>
                            <button
                              type="submit"
                              className="hidden__button hidden__button--save"
                            >
                              {props.boot.general.save}
                            </button>
                          </div>
                        </form>
                      </div>
                      <div className="filters">
                        <p className="filters__title">
                          {props.boot.affico.activeFilters}:
                        </p>
                        {renderActiveFilter()}
                      </div>
                    </>
                  )} */}
                  <DataTable
                    columns={agentsColumns}
                    data={generalData(agentsInfo, agentsSearch)}
                    className="table__container"
                    pagination
                    noHeader
                    subHeader
                    subHeaderComponent={agentsComponent}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="compliance__graphics">
              <Chart
              chartData={(() => {
                const typeFiles = ['POR', 'POP', 'POIBack', 'CCBack', 'POIFront', 'CCFront', 'KYC'];
                return typeFiles.map((type) => {
                  const approved = charts[type]?.files?.approved || 0;
                  const pending = charts[type]?.files?.pending || 0;
                  const rejected = charts[type]?.files?.rejected || 0;
                  return {
                    crmCode: type,
                    files: {
                      approved: approved && charts.totalFtd ? approved * (100 / (charts.totalFtd)) : 0,
                      pending: pending && charts.totalFtd ? pending * (100 / (charts.totalFtd )) : 0,
                      rejected: rejected && charts.totalFtd ? rejected * (100 / (charts.totalFtd)) : 0
                    }
                  }
                });
              })()}
              // kyc={50}
              boot={props.boot}
            />
          </div>
        </section>
      </main>
    </>
  );
}
