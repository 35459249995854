import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import getBoot from "./common/Boot";
import { useAppContext } from "./context/AppContext";

import Login from "./pages/Login";
import Welcome from "./pages/Welcome";
import User from "./pages/User";
import Permissions from "./pages/Permissions";
import Affico from "./pages/Affico";
import AffiliateDetails from "./pages/AffiliateDetails";
import Paysys from "./pages/Paysys";
import PaysysUser from "./pages/PaysysUser";
import Settings from "./pages/Settings";
import Compliance from "./pages/Compliance";
import ComplianceUser from "./pages/ComplianceUser";
import AdvancedSettings from "./pages/Advanced";
import Support from "./pages/Support";
import Dashboard from "./pages/Dashboard";
import Data from "./pages/Data";

const ToLogin = () => <Redirect to="/" />;
const ToHome = () => <Redirect to="/welcome" />;
const ToSettings = () => <Redirect to="/settings" />;

export default function App() {
  const [{ theme, sessionId, user, TwoFAEnable, resetAuth }, dispatch] =
    useAppContext();
  const isAuth = sessionId && user.authorized;
  const TwoFAValidation = isAuth && TwoFAEnable;
  const html = document.querySelector("html");

  if (localStorage.getItem("moisesLang") === null) {
    localStorage.setItem("moisesLang", "en");
    window.location.reload();
  }

  const [boot, setBoot] = useState(
    getBoot()[localStorage.getItem("moisesLang")]
  );
  function changeBoot(key) {
    setBoot(getBoot()[key]);
    html.lang = key;
    localStorage.setItem("moisesLang", key);
  }

  useEffect(() => {
    html.dataset.theme = `theme-${theme}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  useEffect(() => {
    let time;
    const now = new Date().getTime();
    if (resetAuth !== null) {
      time = setTimeout(() => {
        dispatch({ type: "LOGOUT" });
      }, resetAuth - now);
    }

    return () => {
      clearTimeout(time);
    };
  }, [resetAuth, dispatch]);

  useEffect(() => {
    function onRigthClick(event) {
      event.preventDefault();
    }

    // In development comment onInspect
    function onInspect(event) {
      if (event.key === "F12") {
        event.preventDefault();
      }
    }

    document.addEventListener("contextmenu", onRigthClick);
    document.addEventListener("keydown", onInspect);

    return () => {
      document.removeEventListener("contextmenu", onRigthClick);
      document.removeEventListener("keydown", onInspect);
    };
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={isAuth ? ToHome : Login} />
        <Route
          path="/welcome"
          exact
          component={TwoFAValidation ? Welcome : ToSettings}
        />
        <Route
          path="/advanced/settings"
          exact
          render={(props) =>
            TwoFAValidation ? (
              <AdvancedSettings boot={boot} language={changeBoot} {...props} />
            ) : (
              <ToSettings />
            )
          }
        />
        <Route
          path="/user"
          exact
          render={(props) =>
            TwoFAValidation ? (
              <User boot={boot} language={changeBoot} {...props} />
            ) : (
              <ToSettings />
            )
          }
        />
        <Route
          path="/permissions"
          exact
          render={(props) =>
            TwoFAValidation ? (
              <Permissions boot={boot} language={changeBoot} {...props} />
            ) : (
              <ToSettings />
            )
          }
        />
        <Route
          path="/affico"
          exact
          render={(props) =>
            TwoFAValidation ? (
              <Affico boot={boot} language={changeBoot} {...props} />
            ) : (
              <ToSettings />
            )
          }
        />
        <Route
          path="/affiliate"
          exact
          render={(props) =>
            TwoFAValidation ? (
              <AffiliateDetails boot={boot} language={changeBoot} {...props} />
            ) : (
              <ToSettings />
            )
          }
        />
        <Route
          path="/paysys"
          exact
          render={(props) =>
            TwoFAValidation ? (
              <Paysys boot={boot} language={changeBoot} {...props} />
            ) : (
              <ToSettings />
            )
          }
        />
        <Route
          path="/payuser"
          exact
          render={(props) =>
            TwoFAValidation ? (
              <PaysysUser boot={boot} language={changeBoot} {...props} />
            ) : (
              <ToSettings />
            )
          }
        />
        <Route
          path="/settings"
          exact
          render={(props) =>
            isAuth ? (
              <Settings boot={boot} language={changeBoot} {...props} />
            ) : (
              <ToLogin />
            )
          }
        />
        <Route
          path="/compliance"
          exact
          render={(props) =>
            TwoFAValidation ? (
              <Compliance boot={boot} language={changeBoot} {...props} />
            ) : (
              <ToSettings />
            )
          }
        />
        <Route
          path="/compliance/user/:id"
          exact
          render={(props) =>
            TwoFAValidation ? (
              <ComplianceUser boot={boot} language={changeBoot} {...props} />
            ) : (
              <ToSettings />
            )
          }
        />
        <Route
          path="/support"
          exact
          render={(props) =>
            TwoFAValidation ? (
              <Support boot={boot} language={changeBoot} {...props} />
            ) : (
              <ToSettings />
            )
          }
        />
        <Route
          path="/dashboard"
          exact
          render={(props) =>
            TwoFAValidation ? (
              <Dashboard boot={boot} language={changeBoot} {...props} />
            ) : (
              <ToSettings />
            )
          }
        />
        <Route
          path="/data"
          exact
          render={(props) =>
            isAuth ? (
              <Data boot={boot} language={changeBoot} {...props} />
            ) : (
              <ToLogin />
            )
          }
        />
        <Route component={isAuth ? ToHome : ToLogin} />
      </Switch>
    </BrowserRouter>
  );
}
