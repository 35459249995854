import React, { useCallback, useEffect, useState, useMemo } from "react"
import { useAppContext } from "../context/AppContext";

import "../styles/pages/Advanced.scss";
import swal from "sweetalert";

import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import PspConfiguration from "../components/PspConfiguration";
import {
  inArray,
  eraseLocalStorage,
} from "../common/Functions";
import { getBaseApi } from "../common/Routes";

export default function Advanced(props) {
  const [loading, setLoading] = useState(true);
  const [aux, setAux] = useState(false);
  const [{ boot, sessionId, lang }] = useAppContext();
  const [tab, setTab] = useState({ id: 0, label: "PSPS" });
  const [pspSearch, setPspSearch] = useState("");
  const [catalogues, setCatalogues] = useState([]);
  const [options, setOptions] = useState([]);
  const [optionsChanged, setOptionsChanged] = useState([]);
  const [optionsChangedBackup, setOptionsChangedBackup] = useState([]);

  const getAdvancedSettings = async () => {
    setLoading("Loading");
    try {
      const request = await fetch(`${getBaseApi()}/manage/AdvancedSettings`, {
        headers: {
          Authorization: `Bearer ${sessionId}`,
          "Accept-Language": `${lang}`
        },
      });
      const {data, result, error} = await request.json();
      if (result) {
        const {psps, catalogues} = data;
        setCatalogues(catalogues);
        setOptions(psps);
        setOptionsChanged([]);
        setOptionsChangedBackup([]);
      } else if (error === "EISN") {
        eraseLocalStorage();
      } else {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle1,
          text: error,
        });
      }
      setLoading(false);
    } catch(err) {
      swal({
        icon: "error",
        title: props.boot.swal.errorTitle1,
        text: err.message,
      });
      setLoading(false);
    }
  };

  const handleAddOption = useCallback((optionToAdd) => {
    setOptionsChanged([...optionsChanged, optionToAdd]);
    setOptionsChangedBackup([...optionsChangedBackup, optionToAdd]);
  }, [optionsChanged, optionsChangedBackup]);

  const handleEditOption = ({ id: optionID, ...option }) => {
    const optionToUpdate = optionsChanged.find(({ id }) => (id === optionID));
    const optionsWithoutOption = optionsChanged.filter(({ id }) => (id !== optionID));
    if (optionToUpdate) {
      setOptionsChanged([
        ...optionsWithoutOption,
        {
          ...optionToUpdate,
          ...option
        }
      ]);
    }
  };

  const handleReset = () => {
    setOptionsChanged(optionsChangedBackup);
  };
  const getOptionsChanged = useCallback(() => {
    return optionsChanged.filter((option, i) => {
      return Object.keys(optionsChangedBackup[i]).some((key) => {
        if (Array.isArray(option[key])) {
          return option[key].sort().join() !== optionsChangedBackup[i][key].sort().join();
        }
        // eslint-disable-next-line eqeqeq
        return option[key] != optionsChangedBackup[i][key];
      });
    }).map(({id, countriesSelected, businessUnitSelected, max, min, status}) => ({
      idCrmPspCat: id,
      settings: {
          available: status,
          maxAmount: max,
          minAmount: min,
          selectedCountries: countriesSelected,
          selectedBusinessUnits: businessUnitSelected.map((bu) => bu.id)
      }
    }));
  }, [ optionsChanged, optionsChangedBackup ]);

  const handleApply = async () => {
    try {
        const difference = getOptionsChanged();
        if (difference.length) {
          // console.log("diff", difference);
          setLoading("Loading");
          const request = await fetch(`${getBaseApi()}/manage/AdvancedSettings`, {
            method: "PUT",
            body: JSON.stringify(difference),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionId}`,
              "Accept-Language": `${lang}`
            }
          });
          const { result, error} = await request.json();
          if (result) {
            swal({
              icon: "success",
              title: "Success!",
              text: "Updated!",
            });
            setAux(!aux);
          } else if (error === "EISN") {
            eraseLocalStorage();
          } else {
            swal({
              icon: "error",
              title: props.boot.swal.errorTitle1,
              text: error,
            });
          }
          setLoading(false);
        }
      } catch(err) {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle1,
          text: err.message,
        });
        setLoading(false);
      }
  };

  const filterOptions = (data, filter) => (
    data.filter((item) => (
      Object.keys(item).some((element) => (
        item[element] &&
        item[element]
          .toString()
          .toLowerCase()
          .includes(filter.toLowerCase())
      ))
    )).sort((a, b) => b.settings?.available - a.settings?.available)
  );

  const PspOptionsToRender = useMemo(() => (filterOptions(options, pspSearch)), [options, pspSearch]);

  useEffect(() => {
    if (
      !inArray(
        "sPpFdKPt5aZfrQbA58VAp0eSCCwZbFHOlOjocoQNB8ed3oHPZtO6D3xlCRgVPDBp",
        JSON.parse(localStorage.getItem("permissions"))
      )
    ) {
      props.history.push("/welcome");
    }
    getAdvancedSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aux]);

  if (loading) {
    return (
      <>
        <Sidebar
          boot={props.boot}
          function={props.language}
        />
        <div className="loading">
          <div className="loading__data">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Sidebar boot={boot} />
      <main className="general">
        <Header boot={boot} title={`Advanced \nsettings`} />
        <div className="tabs">
          <button
            onClick={() => setTab({ id: 0, label: "PSPS" })}
            className={`tabs__switch${tab.id === 0 ? " tabs__switch--active" : ""}`}
          >
            PSPS
          </button>
        </div>
        <section className="advanced">
          <div className="advanced__search">
            <p className="advanced__search-text">
              <span className="advanced__search-title">Búsqueda</span>
              PSPS
            </p>
            <label htmlFor="search" className="advanced__search-input-container">
              <input
                type="text"
                name="search"
                id="search"
                placeholder="Search"
                className="advanced__search-input"
                onChange={(e) => setPspSearch(e.target.value)}
                value={pspSearch}
              />
          </label>
          </div>
          <div className="advanced__content">
            {PspOptionsToRender.map((option) => {
              const id = option.idCrmPspCat;
              const configurable = (!option.settings || options.settings === {});
              // console.log('====================================');
              // console.log(option.settings?.availableBusinessUnits);
              // console.log(catalogues?.businessUnits);
              // console.log(catalogues?.businessUnits?.filter((bu) => {
              //   return option.settings?.availableBusinessUnits?.indexOf(bu.id) !== -1;
              // }));
              // console.log('====================================');

              return (
                <PspConfiguration
                  key={option.name}
                  settings={{
                    id,
                    configurable,
                    name: option.name,
                    status: option.settings?.available,
                    min: option.settings?.minAmount,
                    max: option.settings?.maxAmount,
                    countriesSelected: option.settings?.selectedCountries,
                    countriesAvailables: option.settings?.availableCountries ?? [],
                    businessUnitSelected: catalogues?.businessUnits?.filter((bu) => {
                      return option.settings?.availableBusinessUnits?.indexOf(bu.id) !== -1
                        && !option.settings?.notAvailableBusinessUnit?.length;
                    }),
                    allBusinessUnits: catalogues?.businessUnits ?? [],
                    allCountries: catalogues?.countries ?? [],
                    toggleStatus(status) {
                      handleEditOption({ id, status: !status });
                    },
                    toggleCountry(country, selected) {
                      const countryIsSelected = selected.includes(country);
                      handleEditOption({
                        id,
                        countriesSelected: countryIsSelected
                          ? selected.filter((countryAvailable) => countryAvailable !== country)
                          : [...selected, country]
                      });
                    },
                    toggleBusinessUnit(idBU, selected) {
                      const businessUnit = catalogues?.businessUnits?.find(({ id }) => id === parseInt(idBU));
                      const businessUnitIsSelected = selected.find((bu) => (bu.id === businessUnit.id));
                      handleEditOption({
                        id,
                        businessUnitSelected: businessUnitIsSelected
                          ? selected.filter((businessUnitAvailable) => businessUnitAvailable.id !== businessUnit.id)
                          : [...selected, businessUnit]
                      });
                    },
                    changeAmount(event) {
                      handleEditOption({ id, [event.target.name]: event.target.value });
                    }
                  }}
                  editableSettings={optionsChanged}
                  updateActive={handleAddOption}
                />
              );
            })}
          </div>
          <div className="advanced__actions">
            <button onClick={handleReset} type="button" className="advanced__action advanced__action--secondary">Reset</button>
            <button onClick={handleApply} type="button" className="advanced__action" disabled={!getOptionsChanged().length}>Apply</button>
          </div>
        </section>
      </main>
    </>
  );
}
