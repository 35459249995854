import React, { useEffect, useRef } from "react";
import { Chart, registerables } from "chart.js";
import { useAppContext } from "../context/AppContext";

import "../styles/Chart.scss";

const COLOR_LABEL = {
  approved: "#289aa9",
  rejected: "#e22343",
  pending: "#ee930b",
  kyc: "#00b347"
};

export default function ChartJS({ type = "bar", chartData, boot }) {
  const canvasRef = useRef(null);
  const [{ theme }] = useAppContext();
  const labels = chartData.map(({ crmCode }) => crmCode);
  const filesLabels = chartData.map(({ files }) => Object.keys(files))[chartData.length-1].reverse();
  const files = chartData.map(({ files }) => files);
  const datasets = filesLabels.map((label) => ({
    // barPercentage: 0.5,
    label: label.toUpperCase(),
    data: files.map((file) => file[label]),
    backgroundColor: COLOR_LABEL[label]
  })); 
  useEffect(() => {
    Chart.register(...registerables);
  }, []);

  useEffect(() => {
    let chartDoughnut;
    if (canvasRef.current) {
      chartDoughnut = new Chart(canvasRef.current.getContext("2d"), {
        type,
        data: { labels, datasets },
        options: {
          maintainAspectRatio: false,
          display: false,
          color: theme === "dark" ? "rgba(255, 255, 255, 1)" : "rgba(0, 0, 0, 1)",
          borderColor: "rgba(0, 0, 0, 0)",
          scales: {
            x: {
              stacked: true,
              min: 0,
              max: 100,
              ticks: {
                color: theme === "dark" ? "rgba(255, 255, 255, 1)" : "rgba(0, 0, 0, 1)",
              }
            },
            y: {
              // stacked: true,
              min: 0,
              max: 100,
              ticks: {
                color: theme === "dark" ? "rgba(255, 255, 255, 1)" : "rgba(0, 0, 0, 1)",
              }
            }
          },
          plugins: {
            title: {
              display: true,
              color: theme === "dark" ? "rgba(255, 255, 255, 1)" : "rgba(0, 0, 0, 1)",
              text: "FILES"
            },
            tooltip: {
              titleColor: theme === "dark" ? "rgba(255, 255, 255, 1)" : "rgba(0, 0, 0, 1)",
              backgroundColor: theme === "dark" ? "rgba(46, 46, 46, 0.58)" : "rgba(242, 242, 242, 1)",
              padding: 10,
              callbacks: {
                label(context) {
                  // console.log(context.label);
                  const label = `${context.dataset.label}: ${context.parsed.y}%`;
                  if (context.label === "KYC") {
                    return (context.dataset.label === "KYC")?label:null;
                  } else {
                    return (context.dataset.label !== "KYC")?label:null;
                  }
                },
                labelTextColor() {
                  return theme === "dark" ? "rgba(255, 255, 255, 1)" : "rgba(0, 0, 0, 1)";
                }
              }
            }
          }
        }
      });
      canvasRef.current.style.height = "500px";
      canvasRef.current.style.maxHeight = "500px";
    }
    return () => {
      chartDoughnut.destroy();
    };
  }, [datasets, labels, theme, type]);


  return (
    <div className="chart">
      <canvas ref={canvasRef} className="chart__chartjs" />
    </div>
  );
}