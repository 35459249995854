import React from "react";
import ReactDOM from "react-dom";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { AppContextProvider } from "./context/AppContext";

import App from "./App.jsx";

import "normalize.css";
import "./styles/General.scss";
import "./styles/Fonts.scss";
import "./styles/table.scss";

ReactDOM.render(
  <AppContextProvider>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <App />
    </MuiPickersUtilsProvider>
  </AppContextProvider>,
  document.getElementById("root")
);
