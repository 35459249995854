import React, { useState, useEffect } from "react";
import { DatePicker } from "@material-ui/pickers";
import swal from "sweetalert";
import { getBaseApi } from "../common/Routes";
import {
  inArray,
  getDateRange,
  eraseLocalStorage,
  convertToQuery,
  numberFormat
} from "../common/Functions";
/* styles */
import "../styles/Paysys.scss";
/* components */
import Header from "../components/Header.jsx";
import Sidebar from "../components/Sidebar.jsx";
import Kpis from "../components/DataKpis.jsx";

export default function Paysys(props) {
  const [loading, setLoading] = useState(true);

  /* General Filter */
  const [filters, setFilters] = useState(false);
  const [params, setParams] = useState({
    dateType: "Today",
    dateStart: new Date(),
    dateEnd: new Date(),
    countries: "ALL",
    affiliates: "ALL",
    psps: "ALL",
    transactionStatus: "ALL",
    transactionDepartment: "ALL",
    transactionCategory: "ALL",
    businessUnits: "ALL"
  });
  const [typeDate, setTypeDate] = useState(true);
  const [aux, setAux] = useState(false);
  const [statistics, setStatistics] = useState([]);

  const onSubmitFilters = (e) => {
    e.preventDefault();
    let filters = {
      dateType: e.target[0].value,
      dateStart: params.dateStart,
      dateEnd: params.dateEnd
    };
    setParams(filters);
    setFilters(false);
    setLoading(true);
    setAux(!aux);
  };

  const getStatistics = () => {
    fetch(getBaseApi() + "/manage/Statistics" + convertToQuery(params), {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("sessionId"),
        "Accept-Language": localStorage.getItem("moisesLang")
      }
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.result) {
          setStatistics(response.data);
          setLoading(false);
        } else if (response.error === "EISN") {
          eraseLocalStorage();
        } else {
          swal({
            icon: "error",
            title: props.boot.swal.errorTitle1,
            text: response.error
          });
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle1,
          text: error.message
        });
      });
  };

  useEffect(() => {
    if (
      !inArray(
        "AwllWFlEvxRGqZxEylyCfvw4tCh01kiPaaHEfscKvHMAYpf57WSSSrHfq40TwLI5",
        JSON.parse(localStorage.getItem("permissions"))
      )
    ) {
      props.history.push("/welcome");
    }
    getStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aux]);

  if (loading) {
    return (
      <>
        <Sidebar boot={props.boot} function={props.language} />
        <div className="loading">
          <div className="loading__data">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </>
    );
  }

  const renderBusinessUnitKPI = () => {
    let zone = statistics.map((item) => {
      return (
        <Kpis
          boot={props.boot}
          title={item.businessUnit}
          kpis={{
            retAmount: numberFormat(item.retAmount),
            ftdNumber: numberFormat(item.ftdAmount),
            totalDeposits: numberFormat(item.totalDeposits)
          }}
          grids="four"
        />
      );
    });
    return zone;
  };

  return (
    <>
      <Sidebar boot={props.boot} function={props.language} />
      <main className="general">
        <Header boot={props.boot} title="Data" function={props.language} />

        <>{renderBusinessUnitKPI()}</>
        <>
          <form onSubmit={(e) => onSubmitFilters(e)}>
            <div className="hidden">
              <h2 className="hidden__title">{props.boot.affico.filters}</h2>
              <div className="hidden__grid hidden__grid--six">
                <article className="hidden__item">
                  <label className="hidden__label" htmlFor="">
                    {props.boot.affico.date}
                  </label>
                  <select
                    name="position"
                    id="position"
                    className="hidden__select"
                    defaultValue={params.dateType}
                    onChange={(e) => {
                      const { dateStart, dateEnd } = getDateRange(
                        e.target.value
                      );
                      setParams({ ...params, dateStart, dateEnd });
                      setTypeDate(e.target.value !== "Range");
                    }}
                    required
                  >
                    <option defaultValue hidden value="">
                      {props.boot.affico.date}
                    </option>
                    {[
                      "Today",
                      "Yesterday",
                      "This Week",
                      "Last Week",
                      "This Month",
                      "Last Month",
                      "This Year",
                      "Range"
                    ].map((dateType, i) => (
                      <option key={i} defaultValue value={dateType}>
                        {dateType}
                      </option>
                    ))}
                  </select>
                </article>
                <article className="hidden__item">
                  <DatePicker
                    disableToolbar
                    disabled={typeDate}
                    style={{ width: "100%" }}
                    format="yyyy-MM-dd"
                    label={props.boot.affico.dateStart}
                    value={params.dateStart}
                    onChange={(date) =>
                      setParams({ ...params, dateStart: date })
                    }
                  />
                </article>
                <article className="hidden__item">
                  <DatePicker
                    disableToolbar
                    disabled={typeDate}
                    style={{ width: "100%" }}
                    format="yyyy-MM-dd"
                    label={props.boot.affico.dateEnd}
                    value={params.dateEnd}
                    onChange={(date) => setParams({ ...params, dateEnd: date })}
                  />
                </article>
              </div>
              <div className="hidden__actions">
                <button
                  onClick={() => setFilters(false)}
                  type="button"
                  className="hidden__button hidden__button--cancel"
                >
                  {props.boot.general.cancel}
                </button>
                <button className="hidden__button hidden__button--save">
                  {props.boot.general.save}
                </button>
              </div>
            </div>
          </form>
        </>
      </main>
    </>
  );
}
