import config from "../config";

export default async function search({ token, email }) {
  const body = {
    email
  };

  const request = await fetch(`${config.api}/find-ftd-by-email`, {
    method: "POST",
    headers: {
      // Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  const errorMessage = "error-search-customer"

  if (!request.ok || request.status === 500) throw new Error(errorMessage);

  const { data, error, message, result } = await request.json();

  if (error || !result) throw new Error(message || errorMessage);

  return data.user;
}
