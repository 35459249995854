import React, { useRef } from "react";

import "../styles/components/Select.scss";

export default function Select({ value, text, readonly, defaultValue = "Choose an option", options = [], autoclose = true, onChoose }) {
  const selectRef = useRef(null);

  const handleCloseSelect = () => {
    if (selectRef.current.dataset.active) {
      delete selectRef.current.dataset.active;
    } else {
      selectRef.current.dataset.active = true;
    }
  };

  const handleSelect = (event) => {
    const option = event.target;
    if (!option.classList.contains("select")) {
      const accountSelected = option.dataset.value;
      onChoose(accountSelected);
      if (autoclose) selectRef.current.dataset.active = false;
    } else {
      handleCloseSelect();
      selectRef.current.lastElementChild.scrollTo(0, 0);
    }
  };

  return (
    <div
      ref={selectRef}
      data-text={text || ""}
      onClick={handleSelect}
      className="select"
      role="button"
      tabIndex={0}
      data-readonly={readonly}
    >
      <p className="select__value">
        {value ?? defaultValue}
      </p>
      <div className="select__options" onMouseLeave={handleCloseSelect}>
        {options.length > 0 ? options.map((option) => (
          <span key={option.id} data-value={option.value} className="select__option">
            {option.label}
          </span>
        )) : null}
      </div>
    </div>
  );
}
