import React, { useCallback, useState } from "react";

import "../styles/components/PspConfiguration.scss";

import Select from "./Select";

export default function PspConfiguration({ settings, editableSettings = [], updateActive }) {
  const [clicked, setClicked] = useState(false);
  const editable = editableSettings.find(({ id }) => (id === settings.id)) ?? {
    status: true,
    min: "",
    max: "",
    countriesSelected: [],
    businessUnitSelected: []
  };

  const differenceSet = new Set([...editable.countriesSelected, ...settings.countriesAvailables]);
  editable.countriesSelected.forEach((country) => { differenceSet.delete(country); });
  const countriesSelectables = [...differenceSet].map((country, index) => ({
    id: `${country}-${index}`,
    value: country,
    label: country
  }));
  const differenceBusinessUnitsSet = new Set([...editable.businessUnitSelected, ...settings.allBusinessUnits]);
  editable.businessUnitSelected.forEach((bu) => { differenceBusinessUnitsSet.delete(bu); });
  const businessUnitSelectables = [...differenceBusinessUnitsSet].map((businessUnit) => ({
    id: businessUnit.id,
    value: businessUnit.id,
    label: businessUnit.name
  }));

  const handleUpdateActive = useCallback(() => {
    updateActive({
      id: settings.id,
      status: settings.status,
      min: settings.min,
      max: settings.max,
      countriesSelected: settings.countriesSelected,
      businessUnitSelected: settings.businessUnitSelected,
      allCountries: settings.allCountries,
      allBusinessUnits: settings.allBusinessUnits,
    });
  }, [settings, updateActive]);

  return (
    <details className="psp-configuration" data-configurable={!settings.configurable}>
      <summary
        className="psp-configuration__summary"
        onClick={!clicked ? () => {
          setClicked(true);
          handleUpdateActive();
        } : null}
      >
        <p className="psp-configuration__name">{settings.name}</p>
        <p className="psp-configuration__status" data-status={settings?.status}>{settings?.status ? "Active" : "Inactive"}</p>
        <p className="psp-configuration__countries">
          {settings?.countriesSelected?.map((counrty) => (
            <span key={counrty} className="psp-configuration__country">
              {`${counrty}, `}
            </span>
          ))}
        </p>
        <div>
          <p className="psp-configuration__amount">{`$ ${settings?.min || 0}`}</p>
          <p className="psp-configuration__amount">{`$ ${settings?.max || 0}`}</p>
        </div>
      </summary>
      <div className="psp-configuration__content">
        <div className="psp-configuration__settings">
          <button
            onClick={() => settings.toggleStatus(editable.status)}
            type="button"
            className="psp-configuration__switch"
            aria-label="on/off psp"
            data-active={editable.status}
          >
            <span className="psp-configuration__switch-circle" />
          </button>
          <Select
            value="Contry"
            text={"country"}
            options={countriesSelectables}
            onChoose={(country) => settings.toggleCountry(country, editable.countriesSelected)}
            autoclose={false}
          />
          <p className="psp-configuration__settings-countries psp-configuration__border">
            {editable.countriesSelected?.map((country) => (
              <span
                key={country}
                onClick={() => settings.toggleCountry(country, editable.countriesSelected)}
                className="psp-configuration__settings-country"
                role="button"
              >
                {country}
              </span>
            ))}
          </p>
          <label htmlFor="min" data-text="Mínimos" className="psp-configuration__settings-label psp-configuration__border">
            <input
              id="min"
              name="min"
              type="text"
              value={editable.min}
              onChange={settings.changeAmount}
              className="psp-configuration__settings-input"
            />
          </label>
          <span></span>
          <span className="psp-configuration__settings-separator"></span>
          <span></span>
          <span></span>
          <Select
            value="Business Unit"
            text={"Business Unit"}
            options={businessUnitSelectables}
            onChoose={(businessUnit) => settings.toggleBusinessUnit(businessUnit, editable.businessUnitSelected)}
            autoclose={false}
          />
          <p className="psp-configuration__settings-countries">
            {editable.businessUnitSelected?.map((businessUnit) => (
              <span
                key={businessUnit.id}
                onClick={() => settings.toggleBusinessUnit(businessUnit.id, editable.businessUnitSelected)}
                className="psp-configuration__settings-country"
                role="button"
              >
                {businessUnit.name}
              </span>
            ))}
          </p>
          <label htmlFor="max" data-text="Máximo" className="psp-configuration__settings-label">
            <input
              id="max"
              name="max"
              type="text"
              value={editable.max}
              onChange={settings.changeAmount}
              className="psp-configuration__settings-input"
            />
          </label>
        </div>
      </div>
    </details>
  );
}
