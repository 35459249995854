import React, { useState, useEffect } from "react";
import { getBaseApi } from "../common/Routes";
import {
  eraseLocalStorage,
  convertToQuery,
  inArray,
  getParameterByName,
} from "../common/Functions";
import "../styles/AffiliateDetails.scss";

import Header from "../components/Header.jsx";
import Sidebar from "../components/Sidebar.jsx";
import Kpis from "../components/Kpis.jsx";

import CancelIcon from "../assets/images/icons/cancel-blue.svg";
/* Assets */
import swal from "sweetalert";

export default function AffiliateDetails(props) {
  const [loading, setLoading] = useState(true);
  const [activeBlock, setActiveBlock] = useState("general");
  const [affiliateInfo, setAffiliateInfo] = useState([]);
  const [aux, setAux] = useState(false);
  const [kpis, setKpis] = useState([]);
  const [inputIp, setInputIp] = useState(3);
  const [prepayments, setPrepayments] = useState({});
  
  useEffect(() => {
    if (getParameterByName("affiliate") !== "") {
      if (
        !inArray(
          "FBHG6k7zwYCcMcdY7XVRhmCVVODjm7adUYaiUzcNRQ9kp7feVviR8i4wzRFTRMXK",
          JSON.parse(localStorage.getItem("permissions"))
        )
      ) {
        props.history.push("/welcome");
      }
      getAffiliateInfo()
    } else {
      props.history.push('/affico')
    }
  }, [aux]);

  const renderInputIp = () => {
    let option = [];
    for (let i = 0; i < inputIp; i++)
      option.push(
        <article key={i} className="affiliate__allowed">
          <input type="text"
            className="affiliate__ip"
            placeholder="0.0.0.0"
            defaultValue={
              affiliateInfo.ips !== undefined ? affiliateInfo.ips[i] : ""
            }
            required />
          <button className="affiliate__delete"
            type="button"
            onClick={() => { setInputIp(inputIp > 1 ? inputIp - 1 : 1) }}>
            <img
              src={CancelIcon}
              alt="Cancel"
              className="affiliate__icon"
            />
          </button>
        </article>
      );
    return option;
  };

  const getAffiliateInfo = () => {
    setLoading(true)
    const params = {
      dateType: getParameterByName("dateType"),
      dateStart: getParameterByName("dateStart"),
      dateEnd: getParameterByName("dateEnd"),
      affiliate: getParameterByName("affiliate"),
    }	
    fetch(
      getBaseApi() +
        "/manage/Affiliate" +
        convertToQuery(params),
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("sessionId"),
          "Accept-Language": localStorage.getItem("moisesLang")
        },
      }
    ).then((res) => res.json())
      .then((response) => {
        if (response.result) {
          setAffiliateInfo(response.data);
          setInputIp(response.data.ips.length)
          setKpis({
            insertDate: response.data.insertDate,
            totalFtd: response.data.totalFtd,
            totalLeads: response.data.totalLeads,
            convertion: response.data.convertion,
          })
          setLoading(false)
        } else if (response.error === "EISN") {
          eraseLocalStorage();
        } else {
          swal({
            icon: "error",
            title: props.boot.swal.errorTitle,
            text: response.error,
          });
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle,
          text: error.message,
        });
      });
  };

  const handleChange = (prop) => (event) => {
    setAffiliateInfo({...affiliateInfo, [prop]: event.target.value })
  }

  const handleChangePrepayment = (prop) => (event) => {
    setPrepayments({...prepayments, [prop]: event.target.value })
  }

  const onSubmitUpdateAffiliate = (e) => {
    e.preventDefault();
    swal({
      icon: "warning",
      title: "Are you sure you want to do this?",
      text: "A user will be updated",
      buttons: true,
    }).then((willUpdate) => {
      if (willUpdate) {
        let data = {
          name: affiliateInfo.name,
          cpa:  affiliateInfo.cpa,
          idAffiliate:  affiliateInfo.id
        };
        let ips = [];
        for (let i = 4; i < e.target.length - 4; i = i + 2) {
          ips.push(e.target[i].value);
        }
        data.ips = ips;
        setLoading(true)
        fetch(getBaseApi() + "/manage/Affiliate", {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("sessionId"),
            "Accept-Language": localStorage.getItem("moisesLang")
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.result) {
              swal({
                icon: "success",
                title: "Success!",
                text: "The user was updated successfuly!",
              });
              setAux(!aux);
            } else if (response.error === "EISN") {
              eraseLocalStorage();
            } else {
              swal({
                icon: "error",
                title: props.boot.swal.errorTitle1,
                text: response.error,
              });
            }
            setLoading(false)
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: props.boot.swal.errorTitle1,
              text: error.message,
            });
            setLoading(false)
          });
      }
    });
  };

  const onSubmitInsertPrepay = (e) => {
    e.preventDefault();
    const data = {
      idCrmAffiliate: affiliateInfo.id,
      action: "Prepay",
      prepayAmount: prepayments.amount,
      prepayDate: prepayments.date,
      observations: prepayments.notes,
    }
    setLoading(true)
    fetch(getBaseApi() + "/manage/Prepay", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("sessionId"),
        "Accept-Language": localStorage.getItem("moisesLang")
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.result) {
          swal({
            icon: "success",
            title: "Success!",
            text: "The prepay was updated successfuly!",
          });
          getAffiliateInfo()
        } else if (response.error === "EISN") {
          eraseLocalStorage();
        } else {
          swal({
            icon: "error",
            title: props.boot.swal.errorTitle1,
            text: response.error,
          });
        }
        setLoading(false)
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle1,
          text: error.message,
        });
        setLoading(false)
      });
  };

  return (
    <>
      <Sidebar
        boot={props.boot}
        function={props.language}
      />
      {!loading && (
        <main className="general">
          <Header boot={props.boot} title="Affico" function={props.language} />
          <section className="affiliate">
            <h2 className="affiliate__subtitle">
              {props.boot.affico.affiliateDetails}
            </h2>
            <div className="tabs">
              <button
                onClick={() => setActiveBlock("general")}
                className={
                  "tabs__switch tabs__switch" +
                  (activeBlock === "general" && "--active")
                }
              >
                General
              </button>
              <button
                onClick={() => setActiveBlock("prepay")}
                className={
                  "tabs__switch tabs__switch" +
                  (activeBlock === "prepay" && "--active")
                }
              >
                {props.boot.affico.prepay}
              </button>
            </div>
            <div className="affiliate__box">
              <h3 className="affiliate__name">{affiliateInfo.name}</h3>
              {activeBlock === "general" && (
                <form onSubmit={(e) => onSubmitUpdateAffiliate(e)}>
                  <div className="affiliate__grid affiliate__grid--four">
                    <article className="affiliate__item">
                      <label className="affiliate__label" htmlFor="">
                        {props.boot.table.name}
                      </label>
                      <input
                        className="affiliate__input"
                        type="text"
                        id="nameAff"
                        defaultValue={affiliateInfo.name}
                        onChange={handleChange('name')}
                        required
                      />
                    </article>
                    <article className="affiliate__item">
                      <label className="affiliate__label" htmlFor="">
                        {props.boot.affico.partnerID}
                      </label>
                      <input
                        className="affiliate__input"
                        type="text"
                        id="partnerAff"
                        defaultValue={affiliateInfo.id}
                        readOnly
                      />
                    </article>
                    <article className="affiliate__item">
                      <label className="affiliate__label" htmlFor="">
                        CPA
                      </label>
                      <input
                        className="affiliate__input"
                        type="text"
                        id="cpaAff"
                        defaultValue={affiliateInfo.cpa}
                        onChange={handleChange('cpa')}
                        required
                      />
                    </article>
                    <article className="affiliate__item">
                      <label className="affiliate__label" htmlFor="">
                        {props.boot.affico.secretKey}
                      </label>
                      <input
                        className="affiliate__input"
                        type="text"
                        id="secretAff"
                        defaultValue={affiliateInfo.secretKey}
                        onChange={handleChange('secretKey')}
                      />
                    </article>
                  </div>
                  <Kpis boot={props.boot} kpis={kpis} grids="four" />
                  <h4 className="affiliate__heading">
                    {props.boot.affico.allowedIP}
                  </h4>
                  <div className="affiliate__grid affiliate__grid--five">
                    { renderInputIp() }
                  </div>
                  <div className="affiliate__add">
                    <button className="affiliate__new"
                      type="button"
                      onClick={() => setInputIp(inputIp + 1)}>
                      {props.boot.affico.addIP}
                    </button>
                  </div>
                  <div className="affiliate__actions">
                  <button
                      className="affiliate__button affiliate__button--cancel"
                      onClick={() => props.history.push('/affico')}
                    >
                      {props.boot.general.cancel}
                    </button>
                    { inArray(
                      "cJc5LGUhrye3CcgB5IJs1KBT7lz2LcDBTkVIvCtkknrCkDSYqOGHsUMHhvcRT2cE",
                      JSON.parse(localStorage.getItem("permissions"))
                    ) && (
                      <button className="affiliate__button affiliate__button--save">
                        {props.boot.general.save}
                      </button>
                    )}
                  </div>
                </form>
              )}
              {activeBlock === "prepay" && (
                <form onSubmit={(e) => onSubmitInsertPrepay(e)}>
                  { affiliateInfo.prepayments.length && <div className="affiliate__pays">
                    { affiliateInfo.prepayments.map((prepayment, i) => {
                      return (
                        <article
                          key={i}
                          className={
                            "affiliate__payment" + 
                            (i % 2 ? " affiliate__payment--last" : '')
                          }>
                          <h5 className="affiliate__amount">+{prepayment.prepayAmount}</h5>
                          <p className="affiliate__date">{prepayment.prepayDate}</p>
                        </article>
                      )
                    })}
                  </div>
                  }
                  <div className="affiliate__grid affiliate__grid--five">
                    <article className="affiliate__item">
                      <label className="affiliate__label" htmlFor="">
                        {props.boot.affico.date}
                      </label>
                      <input
                        className="affiliate__input"
                        type="date"
                        id="datePrepay"
                        onChange={handleChangePrepayment('date')}
                        required
                      />
                    </article>
                    <article className="affiliate__item">
                      <label className="affiliate__label" htmlFor="">
                        {props.boot.affico.usdAmount}*
                      </label>
                      <input
                        className="affiliate__input"
                        type="number"
                        id="amountPrepay"
                        onChange={handleChangePrepayment('amount')}
                        required
                      />
                    </article>
                    <article className="affiliate__item">
                      <label className="affiliate__label" htmlFor="">
                        {props.boot.affico.notes}
                      </label>
                      <input
                        className="affiliate__input"
                        type="text"
                        id="notesPrepay"
                        onChange={handleChangePrepayment('notes')}
                        required
                      />
                    </article>
                  </div>
                  <div className="affiliate__actions">
                    <button
                      className="affiliate__button affiliate__button--cancel"
                      onClick={() => props.history.push('/affico')}
                    >
                      {props.boot.general.cancel}
                    </button>
                    <button className="affiliate__button affiliate__button--save">
                      {props.boot.affico.addPredeposit}
                    </button>
                  </div>
                </form>
              )}
            </div>
          </section>
        </main>
      )}
      {loading && (
        <div className="loading">
          <div className="loading__data">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </>
  );
}
