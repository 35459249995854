import getBoot from "../common/Boot";
import { eraseLocalStorage } from "../common/Functions";

const DEFAULT_IMAGE = "https://webservicesnt.org/apis/epasero/cosmetics/images/user_perfil.svg";

export default function reducer(state, action) {
  switch (action.type) {
    case "LOGIN":
      const { permissions, sessionId, profileImage: imageUrl, ...defaultUser } = action.payload;
      const profileImage = (imageUrl === "" || imageUrl === null || imageUrl === undefined) ? DEFAULT_IMAGE : imageUrl;
      const user = { ...defaultUser, profileImage };

      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      const resetAuth = tomorrow.getTime();

      localStorage.setItem("resetAuth", resetAuth);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("sessionId", sessionId);
      localStorage.setItem("permissions", JSON.stringify(permissions));
      
      return {
        ...state,
        user,
        permissions,
        sessionId,
        resetAuth
      };
    case "LOGOUT":
      localStorage.removeItem("resetAuth");
      localStorage.removeItem("user");
      localStorage.removeItem("permissions");
      localStorage.removeItem("sessionId");
      localStorage.removeItem("2FA");
      eraseLocalStorage();
      return {
        ...state,
        user: {},
        permissions: [],
        sessionId: null,
        TwoFAEnable: false,
        resetAuth: null
      };
    case "CHANGE_IMAGE":
      return {
        ...state,
        user: {
          ...state.user,
          profileImage: action.payload || DEFAULT_IMAGE
        }
      };
    case "CHANGE_THEME":
      const themeValidation = state.theme === "light" ? "dark" : "light";
      localStorage.setItem("theme", themeValidation);
      return {
        ...state,
        theme: themeValidation
      };
    case "CHANGE_LANGUAGE":
      const langValidation = state.lang === "en" ? "es" : "en";
      localStorage.setItem("moisesLang", langValidation);
      return {
        ...state,
        boot: getBoot()[langValidation],
        lang: langValidation
      };
    case "TOGGLE_2FA":
      if (action.payload) { 
	localStorage.setItem("2FA", action.payload);
      } else {
	localStorage.removeItem("2FA");
      }
      return {
        ...state,
       TwoFAEnable: action.payload
      };
 		  
    default:
      return state;
  }
}
