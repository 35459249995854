import React, { createContext, useContext, useReducer } from "react";
import getBoot from "../common/Boot";
import reducer from "./reducer";

const AppContext = createContext(null);

export function AppContextProvider({ children }) {
  const storageTheme = localStorage.getItem("theme");
  const storageLang = localStorage.getItem("moisesLang");
  const storageSession = localStorage.getItem("sessionId");
  const storageUser = localStorage.getItem("user");
  const storagePermissions = localStorage.getItem("permissions");
  const storageTwoFAEnable = localStorage.getItem("2FA");
  const storageResetAuth = localStorage.getItem("resetAuth");

  const initialState = {
    theme: storageTheme || "light",
    boot: storageLang ? getBoot()[storageLang] : getBoot()["es"],
    lang: storageLang || "es",
    user: storageUser ? JSON.parse(storageUser) : {},
    permissions: storagePermissions ? JSON.parse(storagePermissions) : [],
    sessionId: storageSession || null,
    TwoFAEnable: Boolean(storageTwoFAEnable),
    resetAuth: storageResetAuth ? parseInt(storageResetAuth, 10) : null
  };

  return (
    <AppContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </AppContext.Provider>
  );
}

export const useAppContext = () => useContext(AppContext);
