import React, { useEffect, useMemo, useRef, useState, useCallback } from "react";
import DataTable from "react-data-table-component";
import { useParams } from "react-router-dom";
import { getBaseApi } from "../common/Routes";
import swal from "sweetalert";
import { inArray } from "../common/Functions";
import { useAppContext } from "../context/AppContext";
/* styles */
import "../styles/ComplianceUser.scss";
/* icons */
import DownloadIcon from "../assets/images/icons/download-white.svg";
import ReplyIcon from "../assets/images/icons/reply_icon.svg";
import CheckIcon from "../assets/images/icons/check_icon.svg";
import CloseIcon from "../assets/images/icons/close_icon.svg";
import SearchIcon from "../assets/images/icons/search_icon.svg";
import UploadIcon from "../assets/images/icons/upload.svg";
/* components */
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Modal from "../components/Modal"

const defaultFiles = {
  dod: null,
  por: null,
  ccf: null,
  ccb: null,
  poif: null,
  poib: null,
  pop: null
};

const NoDataComponent = () => {
  const [{ boot }] = useAppContext();
  return (
    <p className="compliance-user__no-data">
      {/* {boot.compliance.notFile} */}
      NO FILES TO DISPLAY
    </p>
  );
}

const UploadDocument = ({ onDrop, onChangeImage, onCancel, file, section, boot, reload, crmId, selectDOD, setFiles }) => {
  const chooseFileRef = useRef(null);
  const fileTypes = {
    dod: {type: "6", extraType: ""},
    por: {type: "2", extraType: ""},
    ccf: {type: "4", extraType: "Front"},
    ccb: {type: "4", extraType: "Back"},
    poif: {type: "3", extraType: "Front"},
    poib: {type: "3", extraType: "Back"},
    pop: {type: "7", extraType: ""}
  };
  const handleUploadFile = (e) => {
    e.preventDefault();
    if (section === "dod" && selectDOD.length === 0) {
      swal(boot.swalErrorDOD, {
        icon: "error",
      });
    } else if (file) {
      const data = new FormData();
      data.append("action", "Upload");
      data.append("file", file);
      data.append("crmId", crmId);
      const { type, extraType } = fileTypes[section];
      data.append("fileType", type);
      data.append("extraType", extraType);
      const expirationDate = (section !== "dod") ? e.target[1].value : "";
      data.append("expirationDate", expirationDate);
      data.append("idTransaction", selectDOD.toString());
      fetch(getBaseApi() + "/manage/Compliance", {
        method: "POST",
        body: data,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("sessionId"),
          "Accept-Language": localStorage.getItem("moisesLang")
        }
      })
      .then((res) => res.json())
      .then((response) => {
        if (response.result) {
          reload();
          swal(boot.swalSuccessFile, {
            icon: "success",
          });
        } else {
          console.log("error" + response.error);
        }
      })
    } else {
      swal(boot.swalErrorFile, {
        icon: "error",
      });
    }
  };
  const handleDragOver = (event) => {
    event.preventDefault();
    if (!chooseFileRef.current.classList.contains("compliance-user__choose-file-input--hover")) {
      chooseFileRef.current.classList.add("compliance-user__choose-file-input--hover");
    }
  };
  const handleDragLeave = (event) => {
    event.preventDefault();
    if (chooseFileRef.current.classList.contains("compliance-user__choose-file-input--hover")) {
      chooseFileRef.current.classList.remove("compliance-user__choose-file-input--hover");
    }
  };
  return (
    <form onSubmit={handleUploadFile} className="compliance-user__choose-file-container">
      <div className="compliance-user__choose-file">  
        <div
          ref={chooseFileRef}
          onDrop={chooseFileRef.current ? onDrop(section, chooseFileRef.current) : null}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          className="compliance-user__choose-file-input"
        >
          {
            file ? (
              <>
                <p className="compliance-user__choose-file-text">File to upload</p>
                {
                  file.type.includes("image/")
                    ? <img src={URL.createObjectURL(file)} alt={`Preview of ${file.name}`} className="compliance-user__choose-file-preview" />
                    : <p className="compliance-user__choose-file-name">{file.name}</p>
                }
                <button onClick={onCancel(section)} className="compliance-user__button compliance-user__button--cancel">
                  Cancel
                </button>
              </>
            ) : (
              <>
                <label htmlFor={section} className="compliance-user__button compliance-user__button--chose">
                  <input id={section} onChange={onChangeImage(section)} value={!file ? "" : file} type="file" name="upload" className="compliance-user__input compliance-user__input--upload" />
                  {boot.choose}
                </label>
                {boot.chooseHere}
              </>
            )
          }
        </div>
        {
          !(section === "dod") ? (
            <label data-text={boot.expirationDate} className="compliance-user__label compliance-user__label--maxwidth">
              <input type="date" name="expirationDate" className="compliance-user__input" required />
            </label>
          ) : null
        }
      </div>
      <button
        className="compliance-user__button"
        // onClick={handleUploadFile}
        type="submit"
      >
        <img src={UploadIcon} alt="Download" className="compliance-user__button-icon" />
        {boot.upload}
      </button>
    </form>
  );
};

export default function ComplianceUser({ boot, language, history }) {
  const { compliance } = boot;
  const [files, setFiles] = useState(defaultFiles);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState("");
  const [data, setData] = useState({
    customer: {
      birthDate: "",
      country: "",
      crmId: "",
      email: "",
      firstName: "",
      ftdDate: "",
      lastName: "",
      lastTransaction: "",
      phoneCode: "",
      phoneNumber: "",
      street: "",
      tpId: "",
      zipCode: ""
    }
  });
  const [dataDOD, setDataDOD] = useState([]);
  const [dataCCFront, setDataCCFront] = useState([]);
  const [dataCCBack, setDataCCBack] = useState([]);
  const [dataPOR, setDataPOR] = useState([]);
  const [dataPOIFront, setDataPOIFront] = useState([]);
  const [dataPOIBack, setDataPOIBack] = useState([]);
  const [dataPOP, setDataPOP] = useState([]);
  const [selectDOD, setSelectDOD] = useState([]);
  const { id } = useParams();
  const getPermission = useCallback(async () => {
    const permission = await fetch(getBaseApi() + `/manage/Compliance?action=CustomerAgent&agent=${localStorage.getItem("oyapHSrXRL")}&tpId=${id}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("sessionId"),
        "Accept-Language": localStorage.getItem("moisesLang")
      }
    });
    const { result:permissionResult} = await permission.json();
    if (!permissionResult) {
      history.push("/compliance");
    } 
  }, [history, id]);
  
  const handleDrop = (sectionFile, element) => (event) => {
    event.preventDefault();
    if (element.classList.contains("compliance-user__choose-file-input--hover")) {
      element.classList.remove("compliance-user__choose-file-input--hover");
    }
    let [file] = [null];
    if (event.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      file = [...event.dataTransfer.items].map((item) => item.kind === "file" ? item.getAsFile() : null)[0];
    } else {
      // Use DataTransfer interface to access the file(s)
      file= [...event.dataTransfer.files].map((file) => file)[0];
    }
    setFiles({ ...files, [sectionFile]: file });
  };
  const handleChangeImage = (sectionFile) => (event) => {
    const [file] = event.target.files;
    setFiles({ ...files, [sectionFile]: file });
  };
  const handleCancel = (sectionFile = null) => () => {
    if (sectionFile) {
      setFiles({ ...files, [sectionFile]: null });
    } else {
      setFiles(defaultFiles);
    }
  };
  const handleView = (url) => {
    setShowModal(true);
    setFile(url);
  };
  const handleDownload = async (url) => {
   const urlGetFile = "https://webservicesnt.org/apis/epasero/forex/compliance/";
   const urlArray = url.split("/");
   const urlFile = `${urlGetFile}?f=${urlArray[urlArray.length-3]}/${urlArray[urlArray.length-2]}/${urlArray[urlArray.length-1]}`;
   const link = document.createElement("a");
   link.setAttribute("href", urlFile);
   swal({
    title: "Are you sure you want to download the file?",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  })
  .then((willDelete) => {
    if (willDelete) {
      link.click();
    }
  });
  };
  const getInfo = useCallback(
    async (section) => {
      setLoading(true);
      if (!inArray(
        "Tw7GYQeMAbAyuaCFazDgz3cEKIshAxnVVNQXbulMmwAxeiEzuVAUkEaXVgoMrvRQ",
        JSON.parse(localStorage.getItem("permissions"))
      )) {
        getPermission();
      }
      const response = await fetch(getBaseApi() + `/manage/Compliance?action=DocumentInfo&tpId=${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("sessionId"),
          "Accept-Language": localStorage.getItem("moisesLang")
        }
      });
      const { result, data, error } = await response.json();
      setLoading(false);
      if (result) {
        handleCancel(section)();
        setData(data);
        setDataDOD(data.documents.documentsDOD);
        setDataCCBack(data.documents.documentsCCBack);
        setDataCCFront(data.documents.documentsCCFront);
        setDataPOR(data.documents.documentsPOR);
        setDataPOIFront(data.documents.documentsPOIFront);
        setDataPOIBack(data.documents.documentsPOIBack);
        setDataPOP(data.documents.documentsPOP);
      } else {
        history.push("/compliance");
        // console.log(error)
      }
    }, [getPermission, history, id]);
  const handleChangeStatus = useCallback((status, id) => {
    swal({
      title: "Are you sure you want to change the status?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        const params = {
          action: "ChangeStatus",
          idFile: id,
          status: status
        };
        fetch(getBaseApi() + `/manage/Compliance`, {
          method: "PUT",
          body: JSON.stringify(params),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("sessionId"),
            "Accept-Language": localStorage.getItem("moisesLang")
          }
        })
        .then((res) => res.json())
        .then((response) => {
          if (response.result) {
            swal("Status changed successfully", {
              icon: "success",
            });
            getInfo();
          }
        })
      }
    });
  }, [getInfo]);
  const columnsDOD = useMemo(() => [
    {
      name: compliance.transaction,
      selector: "idCrmTransaction",
      sortable: true,
      center: true,
    },
    {
      name: "PSP",
      selector: "pspName",
      sortable: true,
      center: true,
    },
    {
      name: compliance.amount,
      selector: "amount",
      sortable: true,
      center: true,
    },
    {
      name: compliance.date,
      selector: "insertDate",
      sortable: true,
      center: true,
    },
    {
      name: compliance.status,
      selector: "status",
      sortable: true,
      center: true,
      minWidth: "130px",
      cell: ({ status }) => (
        <p className={`compliance-user__dropdown-table-status compliance-user__dropdown-table-status--${(status)?status.toLowerCase():"default"}`}>
          {(status)?status:"NOT FILE"}
        </p>
      )
    },
    {
      name: compliance.actions,
      selector: "actions",
      sortable: true,
      center: true,
      minWidth: "250px",
      cell: ({ status, idCrmCustomerFile, url }) => {
        const show=(url)?false:true;
        status=(url)?status:"pending";
        return (
        <div className="compliance-user__dropdown-table-actions">
          {
            // status.toLowerCase() !== "approved" ? (
              <button 
                className="compliance-user__dropdown-table-action compliance-user__dropdown-table-action--first"
                onClick={()=>{
                  const newStatus = status.toLowerCase() !== "pending" ? "PENDING" : "APPROVED";
                  handleChangeStatus(newStatus, idCrmCustomerFile)
                }}
                disabled={show}
              >
                <img
                  src={status.toLowerCase() !== "pending" ? ReplyIcon : CheckIcon}
                  alt=""
                  className="compliance-user__dropdown-table-icon"
                />
              </button>
            // ) : null
          }
          {
            status.toLowerCase() === "pending" ? (
              <button 
                className="compliance-user__dropdown-table-action compliance-user__dropdown-table-action--second"
                onClick={()=>{
                  handleChangeStatus("REJECTED", idCrmCustomerFile)
                }}
                disabled={show}
              >
                <img src={CloseIcon} alt="" className="compliance-user__dropdown-table-icon" />
              </button>
            ) : null
          }
          <button
            className="compliance-user__dropdown-table-action compliance-user__dropdown-table-action--third"
            onClick={() => {
              handleView(url);
            }}
            disabled={show}
          >
            <img src={SearchIcon} alt="" className="compliance-user__dropdown-table-icon" />
          </button>
          {inArray(
            "crdJr3ERYBtE43DUDxf1Gka1Vmw2FNfNptyUEiKVUsD3ahvCkWaneMjbzjnLJ8ht",
            JSON.parse(localStorage.getItem("permissions"))
          ) && (<button
            className="compliance-user__dropdown-table-action compliance-user__dropdown-table-action--four"
            onClick={() => {
              handleDownload(url);
            }}
            disabled={show}
          >
            <img src={DownloadIcon} alt="Download" className="compliance-user__dropdown-table-icon" />
          </button> )
          }
        </div>
      )}
    }
  ], [compliance.actions, compliance.amount, compliance.date, compliance.status, compliance.transaction, handleChangeStatus]);
  const columnsProofOfResidence = useMemo(() => [
    {
      name: compliance.uploadDate,
      selector: "insertDate",
      sortable: true,
      center: true,
      minWidth: "150px",
    },
    {
      name: compliance.expirationDate,
      selector: "expirationDate",
      sortable: true,
      center: true,
      minWidth: "150px",
    },
    {
      name: compliance.status,
      selector: "status",
      sortable: true,
      center: true,
      minWidth: "130px",
      cell: ({ status }) => (
        <p className={`compliance-user__dropdown-table-status compliance-user__dropdown-table-status--${status.toLowerCase()}`}>
          {status}
        </p>
      )
    },
    {
      name: compliance.actions,
      selector: "actions",
      sortable: true,
      center: true,
      minWidth: "250px",
      cell: ({ status, idCrmCustomerFile, url }) => (
        <div className="compliance-user__dropdown-table-actions">
          {
            // status.toLowerCase() !== "approved" ? (
              <button 
                className="compliance-user__dropdown-table-action compliance-user__dropdown-table-action--first"
                onClick={()=>{
                  const newStatus = status.toLowerCase() !== "pending" ? "PENDING" : "APPROVED";
                  handleChangeStatus(newStatus, idCrmCustomerFile)
                }}
              >
                <img
                  src={status.toLowerCase() !== "pending" ? ReplyIcon : CheckIcon}
                  alt=""
                  className="compliance-user__dropdown-table-icon"
                />
              </button>
            // ) : null
          }
          {
            status.toLowerCase() === "pending" ? (
              <button 
                className="compliance-user__dropdown-table-action compliance-user__dropdown-table-action--second"
                onClick={()=>{
                  handleChangeStatus("REJECTED", idCrmCustomerFile)
                }}
              >
                <img src={CloseIcon} alt="" className="compliance-user__dropdown-table-icon" />
              </button>
            ) : null
          }
          <button
            className="compliance-user__dropdown-table-action compliance-user__dropdown-table-action--third"
            onClick={() => {
              handleView(url);
            }}
          >
            <img src={SearchIcon} alt="" className="compliance-user__dropdown-table-icon" />
          </button>
          {inArray(
            "crdJr3ERYBtE43DUDxf1Gka1Vmw2FNfNptyUEiKVUsD3ahvCkWaneMjbzjnLJ8ht",
            JSON.parse(localStorage.getItem("permissions"))
          ) && (<button
            className="compliance-user__dropdown-table-action compliance-user__dropdown-table-action--four"
            onClick={() => {
              handleDownload(url);
            }}
          >
            <img src={DownloadIcon} alt="Download" className="compliance-user__dropdown-table-icon" />
          </button>)
          }
        </div>
      )
    }
  ], [compliance.actions, compliance.expirationDate, compliance.status, compliance.uploadDate, handleChangeStatus]);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  if (loading) {
    return (
      <>
        <Sidebar boot={boot} function={language} />
        <div className="loading">
          <div className="loading__data">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <Sidebar boot={boot} function={language} />
      <main className="general">
        <Header boot={boot} title="Compliance" function={language} />
        <section className="compliance-user">
          <div className="compliance-user__header">
            <h2 className="compliance-user__title">{compliance.documents}</h2>
            {/* <button className="compliance-user__button">
              <img src={DownloadIcon} alt="Download" className="compliance-user__button-icon" />
              {compliance.download}
            </button> */}
          </div>
          <div className="compliance-user__section">
            <h2 className="compliance-user__subtitle">{data.customer.firstName}</h2>
            <div className="compliance-user__grid">
              <label data-text="Tp id" className="compliance-user__label">
                <input type="text" value={(data.customer.tpId)?data.customer.tpId :""} className="compliance-user__input" readOnly />
              </label>
              <label data-text={compliance.email} className="compliance-user__label">
                <input type="text" value={(data.customer.email)?data.customer.email:""} className="compliance-user__input" readOnly />
              </label>
              <label data-text={compliance.phone} className="compliance-user__label">
                <input
                  type="text"
                  value={(data.customer.phoneCode)?(data.customer.phoneNumber)?`${data.customer.phoneCode} ${data.customer.phoneNumber}`:data.customer.phoneCode:(data.customer.phoneNumber)?data.customer.phoneNumber:""}
                  className="compliance-user__input"
                  readOnly
                />
              </label>
              <label data-text={compliance.country} className="compliance-user__label">
                <input type="text" value={(data.customer.country)?data.customer.country:""} className="compliance-user__input" readOnly />
              </label>
              {/* <label data-text={compliance.complianceStatus} className="compliance-user__label">
                <input type="text" value={} className="compliance-user__input" readOnly />
              </label> */}
              <label data-text={compliance.address} className="compliance-user__label">
                <input type="text" value={(data.customer.street)?data.customer.street: ""} className="compliance-user__input" readOnly />
              </label>
              <label data-text="C.P." className="compliance-user__label">
                <input type="text" value={(data.customer.zipCode)?data.customer.zipCode: ""} className="compliance-user__input" readOnly />
              </label>
              <label data-text={compliance.dateOfBirth} className="compliance-user__label">
                <input type="text" value={(data.customer.birthDate)?data.customer.birthDate: ""} className="compliance-user__input" readOnly />
              </label>
              <label data-text={compliance.lastDeposit} className="compliance-user__label">
                <input type="text" value={(data.customer.lastTransaction)?data.customer.lastTransaction:""} className="compliance-user__input" readOnly />
              </label>
              <label data-text={compliance.ftdDate} className="compliance-user__label">
                <input type="text" value={(data.customer.ftdDate)?data.customer.ftdDate:""} className="compliance-user__input" readOnly />
              </label>
            </div>
          </div>
          <details className="compliance-user__section">
            <summary className="compliance-user__dropdown">{compliance.idf}</summary>
            <div className="compliance-user__dropdown-content">
              <UploadDocument
                boot={compliance}
                onDrop={handleDrop}
                onChangeImage={handleChangeImage}
                onCancel={handleCancel}
                file={files.poif}
                section="poif"
                reload={getInfo}
                crmId={data.customer.crmId}
                selectDOD={selectDOD}
              />
              <h3 className="compliance-user__dropdown-title">{compliance.historyUploads}</h3>
              <div className="compliance-user__dropdown-table">
                <DataTable
                  columns={columnsProofOfResidence}
                  data={dataPOIFront}
                  noHeader
                  defaultSortField="insertDate"
                  defaultSortAsc={false}
                  noDataComponent={<NoDataComponent />}
                  pagination
                />
              </div>
            </div>
          </details>
          <details className="compliance-user__section">
            <summary className="compliance-user__dropdown">{compliance.idb}</summary>
            <div className="compliance-user__dropdown-content">
              <UploadDocument
                boot={compliance}
                onDrop={handleDrop}
                onChangeImage={handleChangeImage}
                onCancel={handleCancel}
                file={files.poib}
                section="poib"
                reload={getInfo}
                crmId={data.customer.crmId}
                selectDOD={selectDOD}
              />
              <h3 className="compliance-user__dropdown-title">{compliance.historyUploads}</h3>
              <div className="compliance-user__dropdown-table">
                <DataTable
                  columns={columnsProofOfResidence}
                  data={dataPOIBack}
                  noHeader
                  defaultSortField="insertDate"
                  defaultSortAsc={false}
                  noDataComponent={<NoDataComponent />}
                  pagination
/>
              </div>
            </div>
          </details>
          <details className="compliance-user__section">
            <summary className="compliance-user__dropdown">{compliance.por}</summary>
            <div className="compliance-user__dropdown-content">
              <UploadDocument
                boot={compliance}
                onDrop={handleDrop}
                onChangeImage={handleChangeImage}
                onCancel={handleCancel}
                file={files.por}
                section="por"
                reload={getInfo}
                crmId={data.customer.crmId}
                selectDOD={selectDOD}
              />
              <h3 className="compliance-user__dropdown-title">{compliance.historyUploads}</h3>
              <div className="compliance-user__dropdown-table">
                <DataTable
                  columns={columnsProofOfResidence}
                  data={dataPOR}
                  noHeader
                  defaultSortField="insertDate"
                  defaultSortAsc={false}
                  noDataComponent={<NoDataComponent />}
                  pagination
                />
              </div>
            </div>
          </details>
          <details className="compliance-user__section">
            <summary className="compliance-user__dropdown">{compliance.dod}</summary>
            <div className="compliance-user__dropdown-content">
              {
                selectDOD.length > 0 ? (
                  <UploadDocument
                    boot={compliance}
                    onDrop={handleDrop}
                    onChangeImage={handleChangeImage}
                    onCancel={handleCancel}
                    file={files.dod}
                    setFiles={setFiles}
                    section="dod"
                    reload={getInfo}
                    crmId={data.customer.crmId}
                    selectDOD={selectDOD}
                  />
                ) : null
              }
              <h3 className="compliance-user__dropdown-title">{compliance.dodTransactions}</h3>
              <div className="compliance-user__dropdown-table">
                <DataTable
                  columns={columnsDOD}
                  data={dataDOD}
                  noHeader
                  noDataComponent={<NoDataComponent />}
                  paginationselectableRows
                  defaultSortField="insertDate"
                  defaultSortAsc={false}
                  selectableRows
                  onSelectedRowsChange = {(e) => {
                    const { selectedRows } = e;
                    const idTransaction = selectedRows.filter((item) => (item.status !== "PENDING" && item.status !== "APPROVED")).map((element) => {
                      return element.idCrmTransaction;
                    });
                    setSelectDOD(idTransaction);
                  }}
                />
              </div>
            </div>
          </details>
          <details className="compliance-user__section">
            <summary className="compliance-user__dropdown">{compliance.pop}</summary>
            <div className="compliance-user__dropdown-content">
              <UploadDocument
                boot={compliance}
                onDrop={handleDrop}
                onChangeImage={handleChangeImage}
                onCancel={handleCancel}
                file={files.pop}
                section="pop"
                reload={getInfo}
                crmId={data.customer.crmId}
                selectDOD={selectDOD}
              />
              <h3 className="compliance-user__dropdown-title">{compliance.historyUploads}</h3>
              <div className="compliance-user__dropdown-table">
                <DataTable
                  columns={columnsProofOfResidence}
                  data={dataPOP}
                  noHeader
                  defaultSortField="insertDate"
                  defaultSortAsc={false}
                  noDataComponent={<NoDataComponent />}
                  pagination
                />
              </div>
            </div>
          </details>
          {/* <details className="compliance-user__section">
            <summary className="compliance-user__dropdown">{compliance.ccf}</summary>
            <div className="compliance-user__dropdown-content">
              <UploadDocument
                boot={compliance}
                onDrop={handleDrop}
                onChangeImage={handleChangeImage}
                onCancel={handleCancel}
                file={files.ccf}
                section="ccf"
                reload={getInfo}
                crmId={data.customer.crmId}
                selectDOD={selectDOD}
              />
              <h3 className="compliance-user__dropdown-title">{compliance.historyUploads}</h3>
              <div className="compliance-user__dropdown-table">
                <DataTable
                  columns={columnsProofOfResidence}
                  data={dataCCFront}
                  noHeader
                  defaultSortField="insertDate"
                  defaultSortAsc={false}
                  noDataComponent={<NoDataComponent />}
                  pagination
                />
              </div>
            </div>
          </details>
          <details className="compliance-user__section">
            <summary className="compliance-user__dropdown">{compliance.ccb}</summary>
            <div className="compliance-user__dropdown-content">
              <UploadDocument
                boot={compliance}
                onDrop={handleDrop}
                onChangeImage={handleChangeImage}
                onCancel={handleCancel}
                file={files.ccb}
                section="ccb"
                reload={getInfo}
                crmId={data.customer.crmId}
                selectDOD={selectDOD}
              />
              <h3 className="compliance-user__dropdown-title">{compliance.historyUploads}</h3>
              <div className="compliance-user__dropdown-table">
                <DataTable
                  columns={columnsProofOfResidence}
                  data={dataCCBack}
                  noHeader
                  defaultSortField="insertDate"
                  defaultSortAsc={false}
                  noDataComponent={<NoDataComponent />}
                  pagination
                />
              </div>
            </div>
          </details> */}
        </section>
      </main>
      <Modal showModal={showModal} setShowModal={setShowModal} file={file}/>
    </>
  );
}
