import React, { useState, useEffect, useMemo } from "react";
import { DatePicker } from "@material-ui/pickers";
import format from "date-fns/format";
import DataTable from "react-data-table-component";
import swal from "sweetalert";
import { getBaseApi } from "../common/Routes";
import PureModal from "react-pure-modal";
import {
  inArray,
  getDateRange,
  eraseLocalStorage,
  convertToQuery,
  numberFormat
} from "../common/Functions";
import { getCountries } from "../common/Boot";
/* styles */
import "../styles/Paysys.scss";
/* icons */
import SearchIcon from "../assets/images/icons/search-white.svg";
import ReloadIcon from "../assets/images/icons/reload-white.svg";
import DownloadIcon from "../assets/images/icons/download-white.svg";
import FiltersIcon from "../assets/images/icons/filters-white.svg";
import CancelIcon from "../assets/images/icons/cancel-blue.svg";
import ApproveIcon from "../assets/images/icons/check-white.svg";
import CancelWhiteIcon from "../assets/images/icons/cancel-white.svg";
import SendIcon from "../assets/images/icons/send.svg";
/* components */
import Header from "../components/Header.jsx";
import Sidebar from "../components/Sidebar.jsx";
import Kpis from "../components/Kpis.jsx";

export default function Paysys(props) {
  const [loading, setLoading] = useState(true);
  const [modalSendFTD, setModalSendFTD] = useState(false);

  /* General Filter */
  const [filters, setFilters] = useState(false);
  const [params, setParams] = useState({
    dateType: "Today",
    dateStart: new Date(),
    dateEnd: new Date(),
    countries: "ALL",
    affiliates: "ALL",
    psps: "ALL",
    transactionStatus: "ALL",
    transactionDepartment: "ALL",
    transactionCategory: "ALL",
    businessUnits: "ALL"
  });
  const [typeDate, setTypeDate] = useState(true);
  const [aux, setAux] = useState(false);
  const [activeTransactions, setActiveTransactions] = useState([]);
  const [pspSummary, setPspSummary] = useState([]);
  const [kpis, setKpis] = useState([]);
  const [paysys, setPaysys] = useState({});
  const [catalogues, setCatalogues] = useState({});
  const [activeFilters, setActiveFilters] = useState({});

  const getPaysysInfo = () => {
    setLoading(true);
    setActiveTransactions([]);

    setPspSummary([]);
    const { dateStart, dateEnd, ...paramsSearch } = params;
    const query = convertToQuery({
      ...paramsSearch,
      dateStart: format(dateStart, "yyyy-MM-dd"),
      dateEnd: format(dateEnd, "yyyy-MM-dd")
    });
    fetch(
      getBaseApi() +
        "/manage/Paysys" +
        convertToQuery({
          ...paramsSearch,
          dateStart: format(dateStart, "yyyy-MM-dd"),
          dateEnd: format(dateEnd, "yyyy-MM-dd")
        }) +
        "&action=Information",
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("sessionId"),
          "Accept-Language": localStorage.getItem("moisesLang")
        }
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.result) {
          setActiveTransactions(
            response.data.transactions.map((trans) => {
              // trans.amount = trans.amount ? numberFormat(trans.amount) : "-";

              trans.dpto = trans.ftd;
              trans.transType = trans.category;
              return trans;
            })
          );
          setPspSummary(
            response.data.pspSummary.map((psp) => {
              psp.totalPayments = psp.totalPayments
                ? numberFormat(psp.totalPayments)
                : "-";
              psp.totalAmount = psp.totalAmount
                ? numberFormat(psp.totalAmount)
                : "-";
              psp.maxDeposit = psp.maxDeposit
                ? numberFormat(psp.maxDeposit)
                : "-";
              psp.minDeposit = psp.minDeposit
                ? numberFormat(psp.minDeposit)
                : "-";
              psp.pspName = psp.psp;
              psp.avgPayments = "x"; // Doubt
              psp.avgAmount = "x"; // Doubt
              return psp;
            })
          );
          setKpis({
            netAmount: numberFormat(response.data.kpis.netAmount),
            approvedPercentage: response.data.kpis.approvedPercentage,
            totalPayments: numberFormat(response.data.kpis.totalPayments),
            ftdPayments: response.data.kpis.ftd,
            retPayments: response.data.kpis.retention
          });
          setCatalogues(response.data.catalogues);
          setPaysys({
            retAmount: numberFormat(response.data.kpis.retentionAmount),
            ftdAmount: numberFormat(response.data.kpis.ftdAmount),
            totalDeposits: numberFormat(response.data.kpis.totalDeposits),
            totalWithdrawal: numberFormat(response.data.kpis.totalWithdrawal)
          });
          setLoading(false);
        } else if (response.error === "EISN") {
          eraseLocalStorage();
        } else {
          setLoading(false);
          swal({
            icon: "error",
            title: props.boot.swal.errorTitle,
            text: response.error
          });
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle,
          text: error.message
        });
      });
  };

  const onSubmitFilters = (e) => {
    e.preventDefault();

    let countries = activeFilters.countries
      ? activeFilters.countries.map((e) => e.id)
      : [];
    countries = !countries.length
      ? e.target[1].value === "ALL"
        ? "ALL"
        : [e.target[1].value]
      : countries;
    let affiliates = activeFilters.affiliates
      ? activeFilters.affiliates.map((e) => e.id)
      : [];
    affiliates = !affiliates.length
      ? e.target[5].value === "ALL"
        ? "ALL"
        : [e.target[5].value]
      : affiliates;
    let psps = activeFilters.psps ? activeFilters.psps.map((e) => e.id) : [];
    psps = !psps.length
      ? e.target[0].value === "ALL"
        ? "ALL"
        : [e.target[0].value]
      : psps;
    let filters = {
      psps: psps,
      countries,
      transactionStatus: e.target[2].value,
      transactionDepartment: e.target[3].value,
      transactionCategory: e.target[4].value,
      affiliates,
      businessUnits: e.target[6].value,
      dateType: e.target[7].value,
      dateStart: params.dateStart,
      dateEnd: params.dateEnd
    };
    setParams(filters);
    setFilters(false);
    setAux(!aux);
  };
  const downloadPaysys = () => {
    setLoading(true);
    const { dateStart, dateEnd, ...paramsSearch } = params;
    fetch(
      getBaseApi() +
        "/manage/Paysys" +
        convertToQuery({
          ...paramsSearch,
          dateStart: format(dateStart, "yyyy-MM-dd"),
          dateEnd: format(dateEnd, "yyyy-MM-dd")
        }) +
        "&action=Download",
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("sessionId"),
          "Accept-Language": localStorage.getItem("moisesLang")
        }
      }
    )
      .then((res) => res.blob())
      .then((getExcel) => {
        var url = window.URL.createObjectURL(getExcel);
        var excel = document.createElement("a");
        excel.href = url;
        excel.download = "PaysysReport.xlsx";
        document.body.appendChild(excel);
        excel.click();
        excel.remove();
        setLoading(false);
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle1,
          text: error.message
        });
        setLoading(false);
      });
  };
  const onHandleSubmitSendFTDToCrm = (e) => {
    e.preventDefault();
    if (!modalSendFTD?.length) return;
    let data = {
      crmIds: modalSendFTD,
      sendBU: e.target[0].value
    };
    swal({
      icon: "warning",
      title: "Are you sure you want to continue?",
      text: "Leads are going to send to CRM",
      buttons: true
    }).then((willDeleted) => {
      if (willDeleted) {
        setLoading(true);
        fetch(getBaseApi() + "/uploadLeadsOtherCrm", {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("sessionId"),
            "Accept-Language": localStorage.getItem("moisesLang")
          }
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.result) {
              swal({
                // icon: "info",
                title: "Summary response!",
                text: JSON.stringify(response.data)
                // content: (
                //   <ul style={{listStyle: 'none'}}>
                //     <li>{JSON.stringify(response.data)}</li>
                //     {/* {Object.keys(response.data).map((k) => (
                //       <li key={k}>{k}: {response.data[k]}</li>
                //     ))} */}
                //   </ul>
                // ),
              });
              setAux(!aux);
            } else if (response.error === "EISN") {
              eraseLocalStorage();
            } else {
              swal({
                icon: "error",
                title: props.boot.swal.errorTitle1,
                text: props.boot.error[response.error]
              });
            }
            setLoading(false);
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: props.boot.swal.errorTitle1,
              text: error.message
            });
            setLoading(false);
          });
      }
    });
  };

  const generalData = (data, filter) => {
    let aux = data.filter((item) => {
      let a = Object.keys(item).map((element) => {
        if (
          item[element] &&
          item[element].toString().toLowerCase().includes(filter.toLowerCase())
        ) {
          return true;
        }
        return false;
      });
      if (inArray(true, a)) {
        return true;
      }
      return false;
    });
    return aux;
  };
  /* Active Transactions table components*/
  const [activeTransFilter, setactiveTransFilter] = useState("");

  const activeTransactionsColumns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      center: true
    },
    {
      name: props.boot.login.email,
      selector: "email",
      sortable: true,
      center: true,
      minWidth: "300px"
    },
    {
      name: "TP ID",
      selector: "tpId",
      sortable: true,
      center: true,
      minWidth: "140px"
    },
    {
      name: "CRM ID",
      selector: "crmId",
      sortable: true,
      center: true,
      minWidth: "160px"
    },
    {
      name: props.boot.table.country,
      selector: "country",
      sortable: true,
      center: true
    },
    {
      name: props.boot.table.amount,
      selector: "amount",
      sortable: true,
      center: true
    },
    {
      name: props.boot.table.last4,
      selector: "last4",
      sortable: true,
      center: true,
      minWidth: "100px"
    },
    {
      name: props.boot.table.status,
      selector: "status",
      sortable: true,
      center: true,
      minWidth: "120px",
      cell: (row) => (
        <div className={`status${!!row.status ? ` status--${row.status.toLowerCase()}` : ""}`}>
          {props.boot.table[row.status?.toLowerCase()]}
        </div>
      )
    },
    {
      name: props.boot.table.dpto,
      selector: "dpto",
      sortable: true,
      center: true
    },
    {
      name: props.boot.table.reference,
      selector: "reference",
      sortable: true,
      center: true,
      minWidth: "150px"
    },
    {
      name: props.boot.table.transType,
      selector: "transType",
      sortable: true,
      center: true
    },
    {
      name: "PSP",
      selector: "psp",
      sortable: true,
      center: true,
      minWidth: "300px",
      omit: !inArray(
        "cvwnKktKBP5mxDBk6GUjC08TbCTSAsX0P9ToqKnvlCkqm6MbTqAKOb86IA8rfHGA",
        JSON.parse(localStorage.getItem("permissions"))
      )
    },
    {
      name: props.boot.table.businessUnit,
      selector: "businessUnit",
      sortable: true,
      center: true
    },
    {
      name: props.boot.table.providerId,
      selector: "idProviderTransaction",
      sortable: true,
      center: true,
      minWidth: "325px"
    },
    {
      name: props.boot.table.insertDate,
      selector: "insertDate",
      sortable: true,
      center: true,
      minWidth: "160px"
    },
    {
      name: props.boot.table.confirmDate,
      selector: "confirmDate",
      sortable: true,
      center: true,
      minWidth: "160px",
      omit: !inArray(
        "CcYv6Sav9w8hvMogUQhdqAiLDKBuO59hlUn0kFH0GkzZelqMmGrBggzE59brGFFC",
        JSON.parse(localStorage.getItem("permissions"))
      )
    },
    {
      name: props.boot.table.action,
      selector: "actions",
      sortable: true,
      center: true,
      minWidth: "140px",
      cell: (row) => (
        <span className="table__operations">
          {(inArray(
            "PegZ1Bqv95SOwal2SYlkYyO5ZiYrf3n7K2dhlMcvrlsvF5qVoRkF9k0NtU6QtrP4",
            JSON.parse(localStorage.getItem("permissions"))
          ) &&
            row.category === "Withdrawal" &&
            row.status === "PENDING") ||
          (inArray(
            "H8aWXtpdTGR1npWujmHXtolxTZCVcVE6vkxZ4GvqtxzZuIDtP2HOKYN2SI82Yh3u",
            JSON.parse(localStorage.getItem("permissions"))
          ) &&
            row.category === "Deposit" &&
            row.status === "PENDING") ? (
            <button
              type="button"
              id={"appTransaction" + row.id}
              onClick={(e) => autoApprove(row.id, row.category)}
              className="table__action"
            >
              <img
                className="table__icon"
                src={ApproveIcon}
                alt="Approve Transaction"
              />
            </button>
          ) : (
            <></>
          )}
          {inArray(
            "H8aWXtpdTGR1npWujmHXtolxTZCVcVE6vkxZ4GvqtxzZuIDtP2HOKYN2SI82Yh3u",
            JSON.parse(localStorage.getItem("permissions"))
          ) &&
          row.category === "Deposit" &&
          row.status === "PENDING" ? (
            <button
              type="button"
              id={"appTransaction" + row.id}
              onClick={(e) => autoApprove(row.id, row.category, "REJECTED")}
              className="table__action"
            >
              <img
                className="table__icon"
                src={CancelWhiteIcon}
                alt="Reject Transaction"
              />
            </button>
          ) : (
            <></>
          )}
          {inArray(
            "ExISrnsC7xd988exXyvZJShNM0DRfHWHibpx2UXYV4hdlBtxLvzagq4bEg84ipxG",
            JSON.parse(localStorage.getItem("permissions"))
          ) &&
          row.sendToCrm === "0" &&
          row.status === "APPROVED" ? (
            <button
              type="button"
              id={"appTransaction" + row.id}
              onClick={(e) => setModalSendFTD([row.crmId])}
              className="table__action"
            >
              <img src={SendIcon} alt="SEND TO CRM" className="table__icon" />
            </button>
          ) : (
            <></>
          )}
        </span>
      )
    }
  ];

  const ActiveTransactionsFilter = ({ onFilter }) => (
    <div className="table__header">
      <input
        id="searchActiveTransactions"
        type="text"
        placeholder={props.boot.table.filter}
        aria-label="Search Input"
        onChange={onFilter}
        className="table__filter"
      />
    </div>
  );

  const autoApprove = (idTransaction, type, status = "APPROVED") => {
    const data = {
      idTransaction,
      status,
      action: type === "Withdrawal" ? "Withdrawal" : "statusChange"
    };
    swal({
      icon: "warning",
      title: "Are you sure you want to do this?",
      text: `A transaction will be ${status.toLowerCase()}`,
      buttons: true
    }).then((willUpdate) => {
      if (willUpdate) {
        setLoading(true);
        fetch(getBaseApi() + "/manage/Transaction", {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("sessionId"),
            "Accept-Language": localStorage.getItem("moisesLang")
          },
          body: JSON.stringify(data)
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.result) {
              swal({
                icon: "success",
                title: "Success!",
                text: "The transacion was updated successfuly!"
              });
              setAux(!aux);
            } else if (response.error === "EISN") {
              eraseLocalStorage();
            } else {
              swal({
                icon: "error",
                title: props.boot.swal.errorTitle1,
                text: response.error
              });
            }
            setLoading(false);
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: props.boot.swal.errorTitle1,
              text: error.message
            });
            setLoading(false);
          });
      }
    });
  };
  const activeTransComponent = useMemo(() => {
    return (
      <ActiveTransactionsFilter
        onFilter={(e) => setactiveTransFilter(e.target.value)}
      />
    );
  }, []);

  /* PSP Summary table components*/
  const [pspSummaryFilter, setPspSummaryFilter] = useState("");
  const pspSummaryColumns = [
    {
      name: props.boot.table.pspName,
      selector: "pspName",
      sortable: true,
      center: true,
      minWidth: "250px"
    },
    {
      name: props.boot.table.totalPayments,
      selector: "totalPayments",
      sortable: true,
      center: true
    },
    {
      name: props.boot.table.totalAmount,
      selector: "totalAmount",
      sortable: true,
      sortFunction: (a, b) => {
        const firstAmount = parseInt(a.totalAmount, 10);
        const secondAmount = parseInt(b.totalAmount, 10);

        return firstAmount - secondAmount;
      },
      center: true
    },
    {
      name: props.boot.table.approvedPercentage,
      selector: "approvedPercentage",
      sortable: true,
      center: true
    },
    {
      name: props.boot.table.minDeposit,
      selector: "minDeposit",
      sortable: true,
      center: true
    },
    {
      name: props.boot.table.maxDeposit,
      selector: "maxDeposit",
      sortable: true,
      center: true
    }
  ];

  const PSPSummaryFilter = ({ onFilter }) => (
    <div className="table__header">
      <input
        id="searchPSP"
        type="text"
        placeholder={props.boot.table.filter}
        aria-label="Search Input"
        onChange={onFilter}
        className="table__filter"
      />
    </div>
  );
  const pspSummaryComponent = useMemo(() => {
    return (
      <PSPSummaryFilter onFilter={(e) => setPspSummaryFilter(e.target.value)} />
    );
  }, []);

  const renderActiveFilter = () => {
    let returnActive = [];
    Object.keys(activeFilters).forEach((type) => {
      activeFilters[type].forEach((item, index) => {
        returnActive.push(
          <button
            className="filters__button"
            onClick={() => deleteActiveFilter(type, index)}
            key={type + item.id}
          >
            {type}: {item.label}{" "}
            <img className="filters__icon" src={CancelIcon} alt="Close" />
          </button>
        );
      });
    });
    return returnActive;
  };

  const deleteActiveFilter = (array, index) => {
    activeFilters[array].splice(index, 1);
    if (activeFilters[array].length === 0) {
      document.getElementById(array).value = "ALL";
      activeFilters[array] = [];
    }
    setActiveFilters({
      ...activeFilters
    });
  };

  const handleChange = (prop) => (event) => {
    let actFilters = activeFilters[prop] ? activeFilters[prop] : [];
    const sel = document.getElementById(prop);
    const label = sel.options[sel.selectedIndex].text;
    if (actFilters.map((e) => e.id).indexOf(event.target.value) >= 0) {
      return;
    }
    if (event.target.value === "ALL") {
      actFilters = [];
    } else {
      actFilters.push({
        id: event.target.value,
        label
      });
    }
    setActiveFilters({ ...activeFilters, [prop]: actFilters });
  };

  const handleSetBusiness = (id) => () => {
    setParams({
      ...params,
      businessUnits: `${id}`
    });
    setFilters(false);
    setAux(!aux);
  };

  useEffect(() => {
    if (
      !inArray(
        "zIXBTgeWF6FQ9GhRaiaffs1ecmiKY7rZFgEpngkTcif96CrEDPxmkizF3bzIrGAU",
        JSON.parse(localStorage.getItem("permissions"))
      )
    ) {
      props.history.push("/welcome");
    }
    getPaysysInfo();
  }, [aux]);

  if (loading) {
    return (
      <>
        <Sidebar boot={props.boot} function={props.language} />
        <div className="loading">
          <div className="loading__data">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <Sidebar boot={props.boot} function={props.language} />
      <main className="general">
        <Header boot={props.boot} title="Paysys" function={props.language} />
        <section className="paysys__business">
          {catalogues.businessUnits?.map((unit) => {
            const activeBusiness = params.businessUnits.toLocaleLowerCase();
            return (
              <button
                key={unit.id}
                type="button"
                className="paysys__business-action"
                onClick={handleSetBusiness(unit.id)}
                data-active={
                  activeBusiness === `${unit.id}` || activeBusiness === "all"
                }
              >
                {unit.image ? (
                  <img
                    src={unit.image}
                    alt=""
                    className="paysys__business-action-image"
                  />
                ) : (
                  <p className="paysys__business-action-text">{unit.name}</p>
                )}
              </button>
            );
          })}
        </section>
        <form
          onSubmit={(e) => {
            const customer = e.target[0].value;
            if (customer) {
              props.history.push(`/payuser?customer=${customer}`);
            }
          }}
        >
          <div className="search">
            <input
              placeholder={props.boot.table.searchTP}
              type="text"
              className="search__input"
              id="searchUser"
            />
            <button className="search__button">
              <img src={SearchIcon} alt="Search" className="search__icon" />
            </button>
          </div>
        </form>
        {inArray(
          "087GWWVjy7jgJIWxh2VmUIjoafsAj3k8O4NHqSvxVDPqDBcppVyI2fhPIoIitzEA",
          JSON.parse(localStorage.getItem("permissions"))
        ) && (
          <>
            <Kpis boot={props.boot} kpis={kpis} paysys={paysys} grids="five" />
            <div className="table">
              <div className="table__initial">
                <h4 className="table__title">
                  {props.boot.paysys.activeTrans}
                </h4>
                <span className="table__operations">
                  <button
                    className="table__action table__action--upper"
                    onClick={getPaysysInfo}
                  >
                    <img
                      src={ReloadIcon}
                      alt=""
                      className="table__icon table__icon--upper"
                    />
                  </button>
                  {inArray(
                    "LZFkdxNJRdaXrxB9koBiBoFfLPmluXjKljzyJ3gBlsWd7BaNgdxCuEdw3Adfql3Y",
                    JSON.parse(localStorage.getItem("permissions"))
                  ) && (
                    <button
                      className="table__action table__action--upper"
                      onClick={() => downloadPaysys()}
                    >
                      <img
                        src={DownloadIcon}
                        alt=""
                        className="table__icon table__icon--upper"
                      />
                    </button>
                  )}
                  <button
                    onClick={() => {
                      setFilters(!filters);
                      setActiveFilters({
                        countries: Array.isArray(params.countries)
                          ? activeFilters.countries.filter((e) => {
                              return params.countries.indexOf(e.id) >= 0;
                            })
                          : [],
                        affiliates: Array.isArray(params.affiliates)
                          ? activeFilters.affiliates.filter((e) => {
                              return params.affiliates.indexOf(e.id) >= 0;
                            })
                          : [],
                        psps: Array.isArray(params.psps)
                          ? activeFilters.psps.filter((e) => {
                              return params.psps.indexOf(e.id) >= 0;
                            })
                          : []
                      });
                    }}
                    className="table__action table__action--upper"
                  >
                    <img
                      src={FiltersIcon}
                      alt=""
                      className="table__icon table__icon--upper"
                    />
                  </button>
                </span>
              </div>
              {filters && (
                <>
                  <form onSubmit={(e) => onSubmitFilters(e)}>
                    <div className="hidden">
                      <h2 className="hidden__title">
                        {props.boot.affico.filters}
                      </h2>
                      <div className="hidden__grid hidden__grid--six">
                        <article
                          className="hidden__item"
                          style={{
                            display: inArray(
                              "cvwnKktKBP5mxDBk6GUjC08TbCTSAsX0P9ToqKnvlCkqm6MbTqAKOb86IA8rfHGA",
                              JSON.parse(localStorage.getItem("permissions"))
                            )
                              ? "block"
                              : "none"
                          }}
                        >
                          <label className="hidden__label" htmlFor="">
                            PSP
                          </label>
                          <select
                            name="psps"
                            id="psps"
                            className="hidden__select"
                            defaultValue={
                              params.psps === "ALL"
                                ? params.psps
                                : params.psps[params.psps.length - 1]
                            }
                            onChange={handleChange("psps")}
                          >
                            <option defaultValue hidden value="">
                              PSP
                            </option>
                            <option defaultValue value="ALL">
                              {props.boot.table.all}
                            </option>
                            {catalogues.psps.map((p, i) => (
                              <option key={i} defaultValue value={p.id}>
                                {p.name}
                              </option>
                            ))}
                          </select>
                        </article>
                        <article className="hidden__item">
                          <label className="hidden__label" htmlFor="">
                            {props.boot.table.country}
                          </label>
                          <select
                            name="countries"
                            id="countries"
                            className="hidden__select"
                            defaultValue={
                              params.countries === "ALL"
                                ? params.countries
                                : params.countries[params.countries.length - 1]
                            }
                            onChange={handleChange("countries")}
                          >
                            <option defaultValue value="ALL">
                              {props.boot.table.all}
                            </option>
                            {catalogues.countries.map((c, i) => (
                              <option key={i} defaultValue value={c.name}>
                                {getCountries()[c.name]}
                              </option>
                            ))}
                          </select>
                        </article>
                        <article className="hidden__item">
                          <label className="hidden__label" htmlFor="">
                            {props.boot.table.paymentStatus}
                          </label>
                          <select
                            name="transactionStatus"
                            id="transactionStatus"
                            className="hidden__select"
                            defaultValue={params.transactionStatus}
                            required
                          >
                            <option defaultValue hidden value="">
                              {props.boot.table.paymentStatus}
                            </option>
                            <option defaultValue value="ALL">
                              {props.boot.table.all}
                            </option>
                            {catalogues.transactionStatus.map((t, i) => (
                              <option key={i} defaultValue value={t.name}>
                                {t.name}
                              </option>
                            ))}
                          </select>
                        </article>
                        <article className="hidden__item">
                          <label className="hidden__label" htmlFor="">
                            {props.boot.table.transactionDepartment}
                          </label>
                          <select
                            name="transactionStatus"
                            id="transactionStatus"
                            className="hidden__select"
                            defaultValue={params.transactionDepartment}
                            required
                          >
                            <option defaultValue hidden value="">
                              {props.boot.table.transactionDepartment}
                            </option>
                            <option defaultValue value="ALL">
                              {props.boot.table.all}
                            </option>
                            {["FTD", "RET"].map((t, i) => (
                              <option key={i} defaultValue value={t}>
                                {t}
                              </option>
                            ))}
                          </select>
                        </article>
                        <article className="hidden__item">
                          <label className="hidden__label" htmlFor="">
                            {props.boot.table.transCategories}
                          </label>
                          <select
                            name="transactionCategory"
                            id="transactionCategory"
                            className="hidden__select"
                            defaultValue={params.transactionCategory}
                            required
                          >
                            <option defaultValue hidden value="">
                              {props.boot.table.transCategories}
                            </option>
                            <option defaultValue value="ALL">
                              {props.boot.table.all}
                            </option>
                            {catalogues.transactionCategory.map((t, i) => (
                              <option key={i} defaultValue value={t.id}>
                                {t.name}
                              </option>
                            ))}
                          </select>
                        </article>
                        <article className="hidden__item">
                          <label className="hidden__label" htmlFor="">
                            {props.boot.table.affiliate}
                          </label>
                          <select
                            name="affiliates"
                            id="affiliates"
                            className="hidden__select"
                            defaultValue={
                              params.affiliates === "ALL"
                                ? params.affiliates
                                : params.affiliates[
                                    params.affiliates.length - 1
                                  ]
                            }
                            onChange={handleChange("affiliates")}
                          >
                            <option defaultValue hidden value="">
                              {props.boot.table.affiliate}
                            </option>
                            <option defaultValue value="ALL">
                              {props.boot.table.all}
                            </option>
                            {catalogues.affiliates.map((a, i) => (
                              <option key={i} defaultValue value={a.id}>
                                {a.name}
                              </option>
                            ))}
                          </select>
                        </article>
                        <article className="hidden__item">
                          <label className="hidden__label" htmlFor="">
                            {props.boot.table.businessUnit}
                          </label>
                          <select
                            name="businessUnits"
                            id="businessUnits"
                            className="hidden__select"
                            defaultValue={params.businessUnits}
                            required
                          >
                            <option defaultValue hidden value="">
                              {props.boot.table.businessUnit}
                            </option>
                            <option defaultValue value="ALL">
                              {props.boot.table.all}
                            </option>
                            {catalogues.businessUnits.map((a, i) => (
                              <option key={i} defaultValue value={a.id}>
                                {a.name}
                              </option>
                            ))}
                          </select>
                        </article>

                        <article className="hidden__item">
                          <label className="hidden__label" htmlFor="">
                            {props.boot.affico.date}
                          </label>
                          <select
                            name="position"
                            id="position"
                            className="hidden__select"
                            defaultValue={params.dateType}
                            onChange={(e) => {
                              const { dateStart, dateEnd } = getDateRange(
                                e.target.value
                              );
                              setParams({ ...params, dateStart, dateEnd });
                              setTypeDate(e.target.value !== "Range");
                            }}
                            required
                          >
                            <option defaultValue hidden value="">
                              {props.boot.affico.date}
                            </option>
                            {[
                              "Today",
                              "Yesterday",
                              "This Week",
                              "Last Week",
                              "This Month",
                              "Last Month",
                              "This Year",
                              "Range"
                            ].map((dateType, i) => (
                              <option key={i} defaultValue value={dateType}>
                                {dateType}
                              </option>
                            ))}
                          </select>
                        </article>
                        <article className="hidden__item">
                          <DatePicker
                            disableToolbar
                            disabled={typeDate}
                            style={{ width: "100%" }}
                            format="yyyy-MM-dd"
                            label={props.boot.affico.dateStart}
                            value={params.dateStart}
                            onChange={(date) =>
                              setParams({ ...params, dateStart: date })
                            }
                          />
                        </article>
                        <article className="hidden__item">
                          <DatePicker
                            disableToolbar
                            disabled={typeDate}
                            style={{ width: "100%" }}
                            format="yyyy-MM-dd"
                            label={props.boot.affico.dateEnd}
                            value={params.dateEnd}
                            onChange={(date) =>
                              setParams({ ...params, dateEnd: date })
                            }
                          />
                        </article>
                      </div>
                      <div className="hidden__actions">
                        <button
                          onClick={() => setFilters(false)}
                          type="button"
                          className="hidden__button hidden__button--cancel"
                        >
                          {props.boot.general.cancel}
                        </button>
                        <button className="hidden__button hidden__button--save">
                          {props.boot.general.save}
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="filters">
                    <p className="filters__title">
                      {props.boot.affico.activeFilters}:
                    </p>
                    {renderActiveFilter()}
                  </div>
                </>
              )}
              <DataTable
                columns={activeTransactionsColumns}
                data={generalData(activeTransactions, activeTransFilter)}
                className="table__container"
                pagination
                noHeader
                subHeader
                subHeaderComponent={activeTransComponent}
              />
            </div>
            {inArray(
              "cvwnKktKBP5mxDBk6GUjC08TbCTSAsX0P9ToqKnvlCkqm6MbTqAKOb86IA8rfHGA",
              JSON.parse(localStorage.getItem("permissions"))
            ) && (
              <div className="table table--margin">
                <div className="table__initial">
                  <h4 className="table__title">
                    {props.boot.paysys.pspSummary}
                  </h4>
                </div>
                <DataTable
                  columns={pspSummaryColumns}
                  data={generalData(pspSummary, pspSummaryFilter)}
                  className="table__container"
                  pagination
                  noHeader
                  subHeader
                  subHeaderComponent={pspSummaryComponent}
                />
              </div>
            )}
          </>
        )}
      </main>
      <PureModal
        isOpen={modalSendFTD}
        closeButton="close"
        closeButtonPosition="bottom"
        width="300px"
        onClose={() => {
          setModalSendFTD(false);
          return true;
        }}
      >
        <form onSubmit={onHandleSubmitSendFTDToCrm}>
          <h2 className="hidden__title hidden__title--no-margin">
            {props.boot.affico.sendToCRM}
          </h2>
          <div className="">
            <h3 className="tpid__title">
              tpId:{" "}
              <span className="tpid__title--light">
                {modalSendFTD.toString()}
              </span>
            </h3>
            <article className="hidden__item">
              <label className="hidden__label hidden__label--upper" htmlFor="">
                Business Units
              </label>
              <select
                className="hidden__input"
                name="affBusinessUnit"
                id="affBusinessUnit"
                type="text"
                required
              >
                <option value="" hidden>
                  {"Business Units"}
                </option>
                {catalogues?.businessUnitsReceiver?.map((bu) => (
                  <option key={bu.id} value={bu.id}>
                    {bu.name}
                  </option>
                ))}
              </select>
            </article>
          </div>
          <div className="tpid__actions">
            <button
              type="button"
              onClick={() => setModalSendFTD(false)}
              className="hidden__button hidden__button--cancel"
            >
              {props.boot.general.cancel}
            </button>
            <button
              type="submit"
              className="hidden__button hidden__button--save"
            >
              {props.boot.general.send}
            </button>
          </div>
        </form>
      </PureModal>
    </>
  );
}
