import React, { useState } from "react";
import search from "../../common/services/search";
import { useAppContext } from "../../context/AppContext";
import swal from "sweetalert";

import "./Searchables.scss";

export default function Searchables({ onFilter, toggleOpen, toggleLoading }) {
  const [{ boot, permissions }] = useAppContext();
  const [byEmail, setByEmail] = useState(false);
  const [email, setEmail] = useState("");

  const handlePrevent = (event) => {
    event.preventDefault();
  };

  const handleByEmail = async (event) => {
    try {
      event.preventDefault();
      toggleLoading(true);
      const customer = await search({ email })
      // if (!customer) {
      //   throw new Error(boot.affico["search-by-email-error"]);
      // }
      // const customer = {
      //   tpId: "123456789",
      //   business: [],
      // };

      console.log({ customer, email });

      toggleLoading(false);
      toggleOpen(customer);
    } catch (error) {
      swal("Error", error.message, "error");
    } finally {
      toggleLoading(false);
      setEmail("");
    }
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div className="searchables">
      {permissions.includes("FkaTb11TJ85sfRETeA2DjUruDI45HyKv88r5oZPptRRFD02yUNKOlj6Ak0jwrMob") ? (
        <button
          type="button"
          className="searchables__btn"
          onClick={() => setByEmail(!byEmail)}
        >
          {boot.affico[!byEmail ? "search-by-email" : "search-normal"]}
        </button>
      ) : null}
      <form className="searchables__box" onSubmit={byEmail ? handleByEmail : handlePrevent}>
        <input
          id={byEmail ? "searchableFTDByEmail" : "searchAffDetail"}
          type={byEmail ? "email" : "text"}
          placeholder={boot.affico[byEmail ? "search-by-email-placeholder" : "search-normal-placeholder"]}
          aria-label="Search Input"
          onChange={byEmail ? handleEmail : onFilter}
          className="table__filter"
          required={byEmail}
        />
        {byEmail ? (
          <button
            type="submit"
            className="searchables__btn searchables__btn--ok"
          >
            {boot.affico.search}
          </button>
        ) : null}
      </form>
    </div>
  );
}
