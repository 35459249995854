import React, { useState} from 'react'
import swal from 'sweetalert';
import { getBaseApi } from '../common/Routes';
import Header from '../components/Header';
import Loader from '../components/Loader';
import Sidebar from '../components/Sidebar';
import { useAppContext } from '../context/AppContext';

import "../styles/pages/Support.scss";

export default function Support(props) {
  const [loading, setLoading] = useState(false);
  const [{ boot, sessionId, lang }] = useAppContext();
  const [tab, setTab] = useState({ id: 0, label: "sendToLeverate" });
  const [sendLeverate, setSendLeverate] = useState(false);

  const onHandleSubmit = async(e) => {
    e.preventDefault();
    try {
      setLoading("Enviando . . .");
      const response = await fetch(`${getBaseApi()}/manage/Support`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionId}`,
          "Accept-Language": localStorage.getItem("moisesLang")
        },
        body: JSON.stringify({
          ...sendLeverate,
          action: "sendLeverate"
        }),
      })
      const {result, error, data} = await response.json();
      if (result) {
        swal({
          icon: "success",
          title: "Success sent!!",
          text: data,
        });
      } else {
        swal({
          icon: "error",
          title: "Error",
          text: error,
        });
      }
      setLoading(false);
    } catch (err) {
      swal({
        icon: "error",
        title: "Error",
        text: err.message,
      });
      setLoading(false);
    }
  };

  const onHandleChange = (name) => (e) => {
    setSendLeverate({ ...sendLeverate, [name]: e.target.value })
  }

  if (loading) {
    return (
      <>
        <Sidebar
          boot={props.boot}
          function={props.language}
        />
        <Loader/>
      </>
    );
  }

  return (
    <>
      <Sidebar boot={boot} />
      <main className="general">
        <Header boot={boot} title={`Support`} />
        <div className="tabs">
          <button
            onClick={() => setTab({ id: 0, label: "sendToLeverate" })}
            className={`tabs__switch${tab.id === 0 ? " tabs__switch--active" : ""}`}
          >
            Send To Leverate
          </button>
        </div>
        <div className="user__box">
          <form onSubmit={(e) => onHandleSubmit(e)}>
            <h3 className="user__title">
              {props.boot.support.infoTransaction}
            </h3>
            <div className="user__grid user__grid--fixed">
              <article className="user__item">
                <label className="user__label" htmlFor="">
                  {props.boot.table.transactionId}
                </label>
                <input
                  className="user__input"
                  type="text"
                  value={sendLeverate.transactionId}
                  onChange={onHandleChange("transactionId")}
                  required
                />
              </article>
              <article className="user__item">
                <label className="user__label" htmlFor="">
                  {props.boot.table.email}
                </label>
                <input
                  className="user__input"
                  type="email"
                  value={sendLeverate.email}
                  onChange={onHandleChange("email")}
                  required
                />
              </article>
            </div>
            <div className="support__actions">
              <button
                type="button"
                className="user__button user__button--cancel"
                onClick={() => props.history.push("/welcome")}
              >
                {props.boot.general.cancel}
              </button>
              <button type="submit" className="support__action">Send</button>
            </div>
          </form>
        </div>
      </main>
    </>
  );
}
