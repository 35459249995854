import React, { useState, useMemo, useEffect } from "react";
import { getBaseApi } from "../common/Routes";
import {
  eraseLocalStorage,
  inArray,
  renderGeneralSelect,
} from "../common/Functions";
import DataTable from "react-data-table-component";
import "../styles/Permissions.scss";
import Header from "../components/Header.jsx";
import Sidebar from "../components/Sidebar.jsx";

/* Assets */
import EditIcon from "../assets/images/icons/edit.svg";
import DeleteIcon from "../assets/images/icons/delete.svg";
import AddUserIcon from "../assets/images/icons/adduser-aqua.svg";
import AddPermissionIcon from "../assets/images/icons/addpermission-aqua.svg";
import AddPositionIcon from "../assets/images/icons/addposition-aqua.svg";
import swal from "sweetalert";

export default function Permissions(props) {
  const [loading, setLoading] = useState(true);
  const [showUser, setShowUser] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [addPermission, setAddPermission] = useState(false);
  const [addPosition, setAddPosition] = useState(false);
  const [editPosition, setEditPosition] = useState(false);
  const [activeBlock, setActiveBlock] = useState("users");

  const [usersInfo, setUsersInfo] = useState([]);
  const [userInfo, setUserInfo] = useState([]);

  const [auxPermissions, setAuxPermissions] = useState([]);
  const [newPermission, setNewPermission] = useState([]);
  const [removePermission, setRemovePermission] = useState([]);
  const [permissionsInfo, setPermissionsInfo] = useState([]);
  const [permissionsCatalogues, setPermissionsCatalogues] = useState([]);
  const [editPermission, setEditPermission] = useState(false);
  const [permission, setPermission] = useState([]);

  const [positionsInfo, setPositionsInfo] = useState([]);
  const [position, setPosition] = useState([]);
  const [catalogues, setCatalogues] = useState([]);
  const [aux, setAux] = useState(false);

  useEffect(() => {
    getCalalogues();
    getUsersInfo();
    getPermissionsInfo();
    getPositionsInfo();
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aux]);

  const getCalalogues = () => {
    fetch(getBaseApi() + "/manage/Users?action=Catalogues", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("sessionId"),
        "Accept-Language": localStorage.getItem("moisesLang")
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.result) {
          setCatalogues(response.data);
        } else if (response.error === "EISN") {
          eraseLocalStorage();
        } else {
          swal({
            icon: "error",
            title: props.boot.swal.errorTitle,
            text: response.error,
          });
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle,
          text: error.message,
        });
      });
  };
  const getUsersInfo = () => {
    fetch(getBaseApi() + "/manage/Users?action=All", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("sessionId"),
        "Accept-Language": localStorage.getItem("moisesLang")
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.result) {
          setUsersInfo(response.data);
        } else if (response.error === "EISN") {
          eraseLocalStorage();
        } else {
          swal({
            icon: "error",
            title: props.boot.swal.errorTitle,
            text: response.error,
          });
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle,
          text: error.message,
        });
      });
  };
  const getPermissionsInfo = () => {
    fetch(getBaseApi() + "/manage/Permissions?action=All", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("sessionId"),
        "Accept-Language": localStorage.getItem("moisesLang")
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.result) {
          setPermissionsInfo(response.data.permissions);
          setPermissionsCatalogues(response.data.catalogues);
        } else if (response.error === "EISN") {
          eraseLocalStorage();
        } else {
          swal({
            icon: "error",
            title: props.boot.swal.errorTitle,
            text: response.error,
          });
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle,
          text: error.message,
        });
      });
  };
  const getPositionsInfo = () => {
    fetch(getBaseApi() + "/manage/Positions?action=All", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("sessionId"),
        "Accept-Language": localStorage.getItem("moisesLang")
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.result) {
          setPositionsInfo(response.data);
        } else if (response.error === "EISN") {
          eraseLocalStorage();
        } else {
          swal({
            icon: "error",
            title: props.boot.swal.errorTitle,
            text: response.error,
          });
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle,
          text: error.message,
        });
      });
  };
  const onSubmitInsertUser = (e) => {
    e.preventDefault();
    swal({
      icon: "warning",
      title: "Are you sure you want do this?",
      text: "A new user will be inserted",
      buttons: true,
    }).then((willInsert) => {
      if (willInsert) {
        let data = {
          firstName: e.target[0].value,
          lastName: e.target[1].value,
          userName: e.target[2].value,
          password: e.target[3].value,
          position: e.target[5].value,
          businessUnit: e.target[4].value,
        };
        setLoading(true)
        fetch(getBaseApi() + "/manage/Users", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("sessionId"),
            "Accept-Language": localStorage.getItem("moisesLang")
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.result) {
              swal({
                icon: "success",
                title: "Success!",
                text: "The user was registered succesfuly!",
              });
              setAux(!aux);
              setShowUser(!showUser);
            } else if (response.error === "EISN") {
              eraseLocalStorage();
            } else {
              swal({
                icon: "error",
                title: props.boot.swal.errorTitle1,
                text: response.error,
              });
            }
            setLoading(false)
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: props.boot.swal.errorTitle1,
              text: error.message,
            });
            setLoading(false)
          });
      }
    });
  };
  const onSubmitInsertPermission = (e) => {
    e.preventDefault();
    swal({
      icon: "warning",
      title: "Are you sure you want do this?",
      text: "A new permission will be inserted",
      buttons: true,
    }).then((willInsert) => {
      if (willInsert) {
        let data = {
          name: e.target[0].value,
          details: e.target[1].value,
          isPage: parseInt(
            document.querySelector('input[name="isPage"]:checked').value
          ),
          page: e.target[4].value,
        };
        setLoading(true)
        fetch(getBaseApi() + "/manage/Permissions", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("sessionId"),
            "Accept-Language": localStorage.getItem("moisesLang")
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.result) {
              swal({
                icon: "success",
                title: "Success!",
                text: "The user was registered succesfuly!",
              });
              setAux(!aux);
              setAddPermission(!addPermission);
            } else if (response.error === "EISN") {
              eraseLocalStorage();
            } else {
              swal({
                icon: "error",
                title: props.boot.swal.errorTitle1,
                text: response.error,
              });
            }
            setLoading(false)
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: props.boot.swal.errorTitle1,
              text: error.message,
            });
            setLoading(false)
          });
      }
    });
  };
  const onSubmitInsertPosition = (e) => {
    e.preventDefault();
    swal({
      icon: "warning",
      title: "Are you sure you want do this?",
      text: "A new position will be inserted",
      buttons: true,
    }).then((willInsert) => {
      if (willInsert) {
        let data = {
          name: e.target[0].value,
          details: e.target[1].value,
          permissions: [],
        };
        setLoading(true)
        fetch(getBaseApi() + "/manage/Positions", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("sessionId"),
            "Accept-Language": localStorage.getItem("moisesLang")
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.result) {
              swal({
                icon: "success",
                title: "Success!",
                text: "The user was registered succesfuly!",
              });
              setAux(!aux);
              setAddPosition(!addPosition);
            } else if (response.error === "EISN") {
              eraseLocalStorage();
            } else {
              swal({
                icon: "error",
                title: props.boot.swal.errorTitle1,
                text: response.error,
              });
            }
            setLoading(false)
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: props.boot.swal.errorTitle1,
              text: error.message,
            });
            setLoading(false)
          });
      }
    });
  };
  const onClickGetUserById = (id) => {
    fetch(getBaseApi() + "/manage/Users?action=ById&user=" + id, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("sessionId"),
        "Accept-Language": localStorage.getItem("moisesLang")
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.result) {
          console.log(response.data);
          setUserInfo(response.data);
          setAuxPermissions(response.data.permissions);
          setNewPermission([]);
          setRemovePermission([]);
          setEditUser(!editUser);
        } else if (response.error === "EISN") {
          eraseLocalStorage();
        } else {
          swal({
            icon: "error",
            title: props.boot.swal.errorTitle1,
            text: response.error,
          });
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle1,
          text: error.message,
        });
      });
  };
  const onClickGetPermissionById = (id) => {
    fetch(getBaseApi() + "/manage/Permissions?action=ById&permission=" + id, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("sessionId"),
        "Accept-Language": localStorage.getItem("moisesLang")
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.result) {
          console.log(response.data);
          setPermission(response.data);
          setEditPermission(!editPermission);
        } else if (response.error === "EISN") {
          eraseLocalStorage();
        } else {
          swal({
            icon: "error",
            title: props.boot.swal.errorTitle1,
            text: response.error,
          });
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle1,
          text: error.message,
        });
      });
  };
  const onClickGetPositionById = (id) => {
    fetch(getBaseApi() + "/manage/Positions?action=ById&position=" + id, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("sessionId"),
        "Accept-Language": localStorage.getItem("moisesLang")
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.result) {
          console.log(response.data);
          setPosition(response.data);
          setAuxPermissions(response.data.permissions);
          setNewPermission([]);
          setRemovePermission([]);
          setEditPosition(!editPermission);
        } else if (response.error === "EISN") {
          eraseLocalStorage();
        } else {
          swal({
            icon: "error",
            title: props.boot.swal.errorTitle1,
            text: response.error,
          });
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: props.boot.swal.errorTitle1,
          text: error.message,
        });
      });
  };
  const onSubmitUpdateUser = (e) => {
    e.preventDefault();
    swal({
      icon: "warning",
      title: "Are you sure you want to do this?",
      text: "A user will be updated",
      buttons: true,
    }).then((willUpdate) => {
      if (willUpdate) {
        let data = {
          action: "permissions",
          user: userInfo.id,
          firstName: e.target[0].value,
          lastName: e.target[1].value,
          position: e.target[3].value,
          delete: removePermission,
          new: newPermission,
          businessUnit: e.target[2].value,
        };
        setLoading(true)
        fetch(getBaseApi() + "/manage/Users", {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("sessionId"),
            "Accept-Language": localStorage.getItem("moisesLang")
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.result) {
              swal({
                icon: "success",
                title: "Success!",
                text: "The user was updated successfuly!",
              });
              setAux(!aux);
              setEditUser(!editUser);
            } else if (response.error === "EISN") {
              eraseLocalStorage();
            } else {
              swal({
                icon: "error",
                title: props.boot.swal.errorTitle1,
                text: response.error,
              });
            }
            setLoading(false)
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: props.boot.swal.errorTitle1,
              text: error.message,
            });
            setLoading(false)
          });
      }
    });
  };
  const onSubmitUpdatePosition = (e) => {
    e.preventDefault();
    swal({
      icon: "warning",
      title: "Are you sure you want to do this?",
      text: "A position will be updated",
      buttons: true,
    }).then((willUpdate) => {
      if (willUpdate) {
        let data = {
          action: "permissions",
          position: position.id,
          name: e.target[0].value,
          details: e.target[1].value,
          delete: removePermission,
          new: newPermission,
        };
        setLoading(true)
        fetch(getBaseApi() + "/manage/Positions", {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("sessionId"),
            "Accept-Language": localStorage.getItem("moisesLang")
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.result) {
              swal({
                icon: "success",
                title: "Success!",
                text: "The user was updated successfuly!",
              });
              setAux(!aux);
              setEditPosition(!editPosition);
            } else if (response.error === "EISN") {
              eraseLocalStorage();
            } else {
              swal({
                icon: "error",
                title: props.boot.swal.errorTitle1,
                text: response.error,
              });
            }
            setLoading(false)
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: props.boot.swal.errorTitle1,
              text: error.message,
            });
            setLoading(false)
          });
      }
    });
  };
  const onSubmitUpdatePermission = (e) => {
    e.preventDefault();
    swal({
      icon: "warning",
      title: "Are you sure you want to do this?",
      text: "A permission will be updated",
      buttons: true,
    }).then((willUpdate) => {
      if (willUpdate) {
        let data = {
          permission: permission.id,
          name: e.target[0].value,
          details: e.target[1].value,
          isPage: parseInt(
            document.querySelector('input[name="isPage"]:checked')
          ),
          page: e.target[4].value,
        };
        setLoading(true)
        fetch(getBaseApi() + "/manage/Permissions", {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("sessionId"),
            "Accept-Language": localStorage.getItem("moisesLang")
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.result) {
              swal({
                icon: "success",
                title: "Success!",
                text: "The user was updated successfuly!",
              });
              setAux(!aux);
              setEditPermission(!editPermission);
            } else if (response.error === "EISN") {
              eraseLocalStorage();
            } else {
              swal({
                icon: "error",
                title: props.boot.swal.errorTitle1,
                text: response.error,
              });
            }
            setLoading(false)
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: props.boot.swal.errorTitle1,
              text: error.message,
            });
            setLoading(false)
          });
      }
    });
  };
  const onClickDeleteUser = (id) => {
    swal({
      icon: "warning",
      title: "Are you sure you want to do this?",
      text: "A user will be removed",
      buttons: true,
    }).then((willDelete) => {
      if (willDelete) {
        let data = {
          action: "User",
          user: id,
        };
        fetch(getBaseApi() + "/manage/Users", {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("sessionId"),
            "Accept-Language": localStorage.getItem("moisesLang")
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.result) {
              swal({
                icon: "success",
                title: "Success!",
                text: "The user was removed successfuly!",
              });
              setAux(!aux);
            } else if (response.error === "EISN") {
              eraseLocalStorage();
            } else {
              swal({
                icon: "error",
                title: props.boot.swal.errorTitle1,
                text: response.error,
              });
            }
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: props.boot.swal.errorTitle1,
              text: error.message,
            });
          });
      }
    });
  };
  const onClickDeletePermission = (id) => {
    swal({
      icon: "warning",
      title: "Are you sure you want to do this?",
      text: "A permission will be removed",
      buttons: true,
    }).then((willDelete) => {
      if (willDelete) {
        let data = {
          permission: id,
        };
        fetch(getBaseApi() + "/manage/Permissions", {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("sessionId"),
            "Accept-Language": localStorage.getItem("moisesLang")
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.result) {
              swal({
                icon: "success",
                title: "Success!",
                text: "The permission was removed successfuly!",
              });
              setAux(!aux);
            } else if (response.error === "EISN") {
              eraseLocalStorage();
            } else {
              swal({
                icon: "error",
                title: props.boot.swal.errorTitle1,
                text: response.error,
              });
            }
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: props.boot.swal.errorTitle1,
              text: error.message,
            });
          });
      }
    });
  };
  const onClickDeletePosition = (id) => {
    swal({
      icon: "warning",
      title: "Are you sure you want to do this?",
      text: "A position will be removed",
      buttons: true,
    }).then((willDelete) => {
      if (willDelete) {
        let data = {
          position: id,
        };
        fetch(getBaseApi() + "/manage/Positions", {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("sessionId"),
            "Accept-Language": localStorage.getItem("moisesLang")
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.result) {
              swal({
                icon: "success",
                title: "Success!",
                text: "The position was removed successfuly!",
              });
              setAux(!aux);
            } else if (response.error === "EISN") {
              eraseLocalStorage();
            } else {
              swal({
                icon: "error",
                title: props.boot.swal.errorTitle1,
                text: response.error,
              });
            }
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: props.boot.swal.errorTitle1,
              text: error.message,
            });
          });
      }
    });
  };
  const onChangeSwitchFather = (e) => {
    onChangeSwitch(e);
    if (e.target.checked) {
      let aux = auxPermissions;
      aux.push(parseInt(e.target.value));
      setAuxPermissions(aux);
    } else {
      let aux = [];
      auxPermissions.forEach((item) => {
        if (item !== e.target.value) aux.push(item);
      });
      setAuxPermissions(aux);
    }
    document
      .getElementsByName("checkbox-child-" + e.target.value)
      .forEach((item) => {
        setTimeout(() => {
          if (e.target.checked) {
            item.disabled = false;
            if (item.checked !== e.target.checked) item.click();
          } else {
            if (item.checked !== e.target.checked) item.click();
            item.disabled = true;
          }
        }, 100);
      });
  };
  const onChangeSwitch = (e) => {
    let id = parseInt(e.target.value);
    if (!e.target.checked) {
      //Si lo desactiva
      if (!inArray(id, newPermission)) {
        // Y no esta en array new permisos
        if (!inArray(id, removePermission)) {
          // Y no esta en array remove permisos
          let aux = removePermission;
          aux.push(id);
          setRemovePermission(aux);
        }
      } else {
        let aux = [];
        newPermission.forEach((item) => {
          if (item !== id) aux.push(item);
        });
        setNewPermission(aux);
      }
    } else {
      //Si lo activa
      if (!inArray(id, removePermission)) {
        // Y no esta en array remove permisos
        if (!inArray(id, newPermission)) {
          // Y no esta en array new permisos
          let aux = newPermission;
          aux.push(id);
          setNewPermission(aux);
        }
      } else {
        let aux = [];
        removePermission.forEach((item) => {
          if (item !== id) aux.push(item);
        });
        setRemovePermission(aux);
      }
    }
  };
  const renderPermissions = () => {
    let option = [];
    option.push(
      permissionsInfo
        .filter((item) => item.page === null)
        .map((father) => {
          return (
            <div className="hidden__box" key={father.id}>
              <div className="hidden__permission">
                <article className="switch">
                  <input
                    className="switch__check"
                    type="checkbox"
                    name={"checkbox-" + father.id}
                    id={"permission" + father.id}
                    defaultValue={father.id}
                    onChange={(e) => onChangeSwitchFather(e)}
                    defaultChecked={inArray(father.id, auxPermissions)}
                  />
                  <label
                    className="switch__label"
                    htmlFor={"permission" + father.id}
                  >
                    <span className="switch__aux switch__aux--margin" />
                    <h3 className="hidden__subtitle hidden__subtitle--inline">
                      {father.name}
                    </h3>
                  </label>
                </article>
              </div>
              <div className="hidden__permission">
                {permissionsInfo
                  .filter((item) => item.page === father.id)
                  .map((child) => {
                    return (
                      <article className="switch" key={child.id}>
                        <input
                          className="switch__check"
                          type="checkbox"
                          name={"checkbox-child-" + father.id}
                          id={"permission" + child.id}
                          defaultValue={child.id}
                          disabled={!inArray(father.id, auxPermissions)}
                          onChange={(e) => onChangeSwitch(e)}
                          defaultChecked={inArray(child.id, auxPermissions)}
                        />
                        <label
                          className="switch__label"
                          htmlFor={"permission" + child.id}
                        >
                          <span className="switch__aux" />
                          {child.name}
                        </label>
                      </article>
                    );
                  })}
              </div>
            </div>
          );
        })
    );
    return option;
  };

  /* General Filter */
  const generalData = (data, filter) => {
    let aux = data.filter((item) => {
      let a = Object.keys(item).map((element) => {
        if (
          item[element] &&
          item[element].toString().toLowerCase().includes(filter.toLowerCase())
        ) {
          return true;
        }
        return false;
      });
      if (inArray(true, a)) {
        return true;
      }
      return false;
    });
    return aux;
  };
  useEffect(() => {
    setUserFilter("");
    setPermissionsFilter("");
    // setPositionsFilter("");
  }, [activeBlock]);
  /* Users Data & Components */
  const [userFilter, setUserFilter] = useState("");
  const usersColumns = [
    {
      name: props.boot.table.userName,
      selector: "userName",
      sortable: true,
      center: true,
    },
    {
      name: props.boot.table.firstName,
      selector: "firstName",
      sortable: true,
      center: true,
    },
    {
      name: props.boot.table.lastName,
      selector: "lastName",
      sortable: true,
      center: true,
    },
    {
      name: props.boot.table.position,
      selector: "position",
      sortable: true,
      center: true,
    },
    {
      name: props.boot.table.action,
      selector: "",
      center: true,

      cell: (row) => (
        <>
          <button
            onClick={() => onClickGetUserById(row.id)}
            className="table__action"
          >
            <img className="table__icon" src={EditIcon} alt="Edit" />
          </button>
          <button
            onClick={() => onClickDeleteUser(row.id)}
            className="table__action"
          >
            <img className="table__icon" src={DeleteIcon} alt="Delete" />
          </button>
        </>
      ),
    },
  ];
  const UsersFilter = ({ onFilter }) => (
    <>
      <div className="table__header">
        <input
          id="searchUsers"
          type="text"
          placeholder={props.boot.table.filter}
          aria-label="Search Input"
          onChange={onFilter}
          className="table__filter"
        />
      </div>
    </>
  );
  const UserFilterComponent = useMemo(() => {
    return <UsersFilter onFilter={(e) => setUserFilter(e.target.value)} />;
  }, []);
  /* Permission Data & Components */
  const [permissionsFilter, setPermissionsFilter] = useState("");
  const permissionColumns = [
    {
      name: props.boot.table.name,
      selector: "name",
      sortable: true,
      center: true,
    },
    {
      name: props.boot.table.details,
      selector: "details",
      sortable: true,
      center: true,
      minWidth: "300px",
    },
    {
      name: props.boot.table.action,
      selector: "",
      center: true,
      cell: (row) => (
        <>
          <button
            onClick={() => onClickGetPermissionById(row.id)}
            className="table__action"
          >
            <img className="table__icon" src={EditIcon} alt="Edit" />
          </button>
          <button
            onClick={() => onClickDeletePermission(row.id)}
            className="table__action"
          >
            <img className="table__icon" src={DeleteIcon} alt="Delete" />
          </button>
        </>
      ),
    },
  ];
  const PermissionFilter = ({ onFilter }) => (
    <>
      <div className="table__header">
        <input
          id="searchPermission"
          type="text"
          placeholder={props.boot.table.filter}
          aria-label="Search Input"
          onChange={onFilter}
          className="table__filter"
        />
      </div>
    </>
  );
  const PermissionComponent = useMemo(() => {
    return (
      <PermissionFilter
        onFilter={(e) => setPermissionsFilter(e.target.value)}
      />
    );
  }, []);
  /* Position Data & Components */
  const [positionsFilter, setPositionsFilter] = useState("");
  const positionColumns = [
    {
      name: props.boot.table.name,
      selector: "name",
      sortable: true,
      center: true,
    },
    {
      name: props.boot.table.details,
      selector: "details",
      sortable: true,
      center: true,
      minWidth: "250px",
    },
    {
      name: props.boot.table.action,
      selector: "",
      center: true,
      minWidth: "170px",

      cell: (row) => (
        <>
          <button
            onClick={() => onClickGetPositionById(row.id)}
            className="table__action"
          >
            <img className="table__icon" src={EditIcon} alt="Edit" />
          </button>
          <button
            onClick={() => onClickDeletePosition(row.id)}
            className="table__action"
          >
            <img className="table__icon" src={DeleteIcon} alt="Remove" />
          </button>
        </>
      ),
    },
  ];
  const PositionFilter = ({ onFilter }) => (
    <>
      <div className="table__header">
        <input
          id="searchPosition"
          type="text"
          placeholder={props.boot.table.filter}
          aria-label="Search Input"
          onChange={onFilter}
          className="table__filter"
        />
      </div>
    </>
  );
  const PositionComponent = useMemo(() => {
    return (
      <PositionFilter onFilter={(e) => setPositionsFilter(e.target.value)} />
    );
  }, []);
  return (
    <>
      <Sidebar
        boot={props.boot}
        function={props.language}
      />
      {!loading && (
        <main className="general">
          <Header
            boot={props.boot}
            title={props.boot.permissions.title}
            function={props.language}
          />
          <section className="permissions">
            <div className="tabs">
              <button
                onClick={() => setActiveBlock("users")}
                className={
                  "tabs__switch tabs__switch" +
                  (activeBlock === "users" && "--active")
                }
              >
                {props.boot.permissions.users}
              </button>
              <button
                onClick={() => setActiveBlock("permissions")}
                className={
                  "tabs__switch tabs__switch" +
                  (activeBlock === "permissions" && "--active")
                }
              >
                {props.boot.permissions.title}
              </button>
              <button
                onClick={() => setActiveBlock("positions")}
                className={
                  "tabs__switch tabs__switch" +
                  (activeBlock === "positions" && "--active")
                }
              >
                {props.boot.permissions.positions}
              </button>
            </div>
            <div className="permissions__tables">
              {activeBlock === "users" && (
                <div className="permissions__users">
                  <div className="table">
                    <div className="table__initial">
                      <h4 className="table__title">
                        {props.boot.permissions.users}
                      </h4>
                      <button
                        onClick={(e) => setShowUser(true)}
                        className="table__add"
                      >
                        <img
                          className="table__image"
                          src={AddUserIcon}
                          alt="Add User"
                        />{" "}
                        {props.boot.permissions.addUser}
                      </button>
                    </div>
                    {showUser && (
                      <form
                        onSubmit={(e) => onSubmitInsertUser(e)}
                        className="hidden"
                      >
                        <h2 className="hidden__title">
                          {props.boot.permissions.addUser}
                        </h2>
                        <div className="hidden__grid hidden__grid--three">
                          <article className="hidden__item">
                            <label className="hidden__label" htmlFor="">
                              {props.boot.table.firstName}
                            </label>
                            <input
                              className="hidden__input"
                              type="text"
                              id="firstName"
                              required
                            />
                          </article>
                          <article className="hidden__item">
                            <label className="hidden__label" htmlFor="">
                              {props.boot.table.lastName}
                            </label>
                            <input
                              className="hidden__input"
                              type="text"
                              id="lastName"
                              required
                            />
                          </article>
                          <article className="hidden__item">
                            <label className="hidden__label" htmlFor="">
                              {props.boot.table.userName}
                            </label>
                            <input
                              className="hidden__input"
                              type="text"
                              id="userName"
                              required
                            />
                          </article>
                          <article className="hidden__item">
                            <label className="hidden__label" htmlFor="">
                              {props.boot.table.password}
                            </label>
                            <input
                              className="hidden__input"
                              type="text"
                              id="pass"
                              required
                            />
                          </article>
                          <article className="hidden__item">
                            <label className="hidden__label" htmlFor="">
                              {props.boot.table.businessUnit}
                            </label>
                            <select
                              name="businessUnit"
                              id="businessUnit"
                              className="hidden__select"
                            >
                              <option defaultValue hidden value="">
                                {props.boot.table.businessUnit}
                              </option>
                              {renderGeneralSelect(catalogues)}
                            </select>
                          </article>
                          <article className="hidden__item">
                            <label className="hidden__label" htmlFor="">
                              {props.boot.table.position}
                            </label>
                            <select
                              name="position"
                              id="position"
                              className="hidden__select"
                            >
                              <option defaultValue hidden value="">
                                {props.boot.table.position}
                              </option>
                              {renderGeneralSelect(positionsInfo)}
                            </select>
                          </article>
                        </div>
                        <div className="hidden__actions">
                          <button
                            onClick={() => setShowUser(false)}
                            type="button"
                            className="hidden__button hidden__button--cancel"
                          >
                            {props.boot.general.cancel}
                          </button>
                          <button
                            type="submit"
                            className="hidden__button hidden__button--save"
                          >
                            {props.boot.general.save}
                          </button>
                        </div>
                      </form>
                    )}
                    {editUser && (
                      <form
                        onSubmit={(e) => onSubmitUpdateUser(e)}
                        className="hidden"
                      >
                        <h2 className="hidden__title">
                          {props.boot.permissions.userInfo}
                        </h2>
                        <div className="hidden__grid hidden__grid--four">
                          <article className="hidden__item">
                            <label className="hidden__label" htmlFor="">
                              {props.boot.table.firstName}
                            </label>
                            <input
                              className="hidden__input"
                              type="text"
                              value={userInfo.firstName}
                              readOnly
                            />
                          </article>
                          <article className="hidden__item">
                            <label className="hidden__label" htmlFor="">
                              {props.boot.table.lastName}
                            </label>
                            <input
                              className="hidden__input"
                              type="text"
                              defaultValue={userInfo.lastName}
                            />
                          </article>
                          <article className="hidden__item">
                            <label className="hidden__label" htmlFor="">
                              {props.boot.table.businessUnit}
                            </label>
                            <select
                              name="businessUnit"
                              id="businessUnit"
                              className="hidden__select"
                              defaultValue={userInfo.BusinessUnit}
                            >
                              {renderGeneralSelect(catalogues)}
                            </select>
                          </article>
                          <article className="hidden__item">
                            <label className="hidden__label" htmlFor="">
                              {props.boot.table.position}
                            </label>
                            <select
                              name="position"
                              id="position"
                              className="hidden__select"
                              defaultValue={userInfo.idPosition}
                            >
                              {renderGeneralSelect(positionsInfo)}
                            </select>
                          </article>
                        </div>
                        <h2 className="hidden__title hidden__title--margin">
                          {props.boot.permissions.userInfo}
                        </h2>
                        <div className="hidden__grid hidden__grid--three">
                          {renderPermissions()}
                        </div>
                        <div className="hidden__actions">
                          <button
                            onClick={() => setEditUser(false)}
                            type="button"
                            className="hidden__button hidden__button--cancel"
                          >
                            {props.boot.general.cancel}
                          </button>
                          <button className="hidden__button hidden__button--save">
                            {props.boot.general.save}
                          </button>
                        </div>
                      </form>
                    )}
                    <DataTable
                      columns={usersColumns}
                      data={generalData(usersInfo, userFilter)}
                      className="table__container"
                      pagination
                      noHeader
                      subHeader
                      subHeaderComponent={UserFilterComponent}
                    />
                  </div>
                </div>
              )}
              {activeBlock === "permissions" && (
                <div className="permissions__permissions">
                  <div className="table">
                    <div className="table__initial">
                      <h4 className="table__title">
                        {props.boot.permissions.title}
                      </h4>
                      <button
                        onClick={(e) => setAddPermission(true)}
                        className="table__add"
                      >
                        <img
                          className="table__image"
                          src={AddPermissionIcon}
                          alt="Add Permission"
                        />{" "}
                        {props.boot.permissions.addPermission}
                      </button>
                    </div>
                    {addPermission && (
                      <form
                        onSubmit={(e) => onSubmitInsertPermission(e)}
                        className="hidden"
                      >
                        <h2 className="hidden__title">
                          {props.boot.permissions.addUser}
                        </h2>
                        <div className="hidden__grid hidden__grid--four">
                          <article className="hidden__item">
                            <label className="hidden__label" htmlFor="">
                              {props.boot.table.name}
                            </label>
                            <input
                              className="hidden__input"
                              type="text"
                              id="permissionName"
                              required
                            />
                          </article>
                          <article className="hidden__item">
                            <label className="hidden__label" htmlFor="">
                              {props.boot.table.details}
                            </label>
                            <input
                              className="hidden__input"
                              type="text"
                              id="permissionDetails"
                              required
                            />
                          </article>
                          <article className="hidden__item">
                            <p
                              className="hidden__label hidden__label--page"
                              htmlFor=""
                            >
                              {props.boot.table.page}
                            </p>
                            <span className="hidden__selector">
                              <input
                                type="radio"
                                name="isPage"
                                id="isPageYes"
                                value="1"
                              />
                              <label className="hidden__label" htmlFor="">
                                {props.boot.table.yes}
                              </label>
                            </span>
                            <span className="hidden__selector">
                              <input
                                type="radio"
                                name="isPage"
                                id="isPageNo"
                                value="0"
                              />
                              <label className="hidden__label" htmlFor="">
                                NO
                              </label>
                            </span>
                          </article>
                          <article className="hidden__item">
                            <label className="hidden__label" htmlFor="">
                              {props.boot.table.section}
                            </label>
                            <select
                              name="section"
                              id="section"
                              className="hidden__select"
                            >
                              <option defaultValue hidden value="">
                                {props.boot.table.section}
                              </option>
                              {renderGeneralSelect(permissionsCatalogues)}
                            </select>
                          </article>
                        </div>
                        <div className="hidden__actions">
                          <button
                            onClick={() => setAddPermission(false)}
                            type="button"
                            className="hidden__button hidden__button--cancel"
                          >
                            {props.boot.general.cancel}
                          </button>
                          <button
                            type="submit"
                            className="hidden__button hidden__button--save"
                          >
                            {props.boot.general.save}
                          </button>
                        </div>
                      </form>
                    )}
                    {editPermission && (
                      <form
                        onSubmit={(e) => onSubmitUpdatePermission(e)}
                        className="hidden"
                      >
                        <h2 className="hidden__title">Edit Permisssion</h2>
                        <div className="hidden__grid hidden__grid--four">
                          <article className="hidden__item">
                            <label className="hidden__label" htmlFor="">
                              {props.boot.table.name}
                            </label>
                            <input
                              className="hidden__input"
                              type="text"
                              id="permissionName"
                              defaultValue={permission.name}
                              required
                            />
                          </article>
                          <article className="hidden__item">
                            <label className="hidden__label" htmlFor="">
                              {props.boot.table.details}
                            </label>
                            <input
                              className="hidden__input"
                              type="text"
                              id="permissionDetails"
                              defaultValue={permission.details}
                              required
                            />
                          </article>
                          <article className="hidden__item">
                            <p
                              className="hidden__label hidden__label--page"
                              htmlFor=""
                            >
                              {props.boot.table.page}
                            </p>
                            <span className="hidden__selector">
                              <input
                                type="radio"
                                name="isPage"
                                id="isPageYes"
                                value="1"
                                defaultChecked={
                                  permission.page === null ? true : false
                                }
                              />
                              <label className="hidden__label" htmlFor="">
                                {props.boot.table.yes}
                              </label>
                            </span>
                            <span className="hidden__selector">
                              <input
                                type="radio"
                                name="isPage"
                                id="isPageNo"
                                value="0"
                                defaultChecked={
                                  permission.page !== null ? true : false
                                }
                              />
                              <label className="hidden__label" htmlFor="">
                                NO
                              </label>
                            </span>
                          </article>
                          <article className="hidden__item">
                            <label className="hidden__label" htmlFor="">
                              {props.boot.table.section}
                            </label>
                            <select
                              name="section"
                              id="section"
                              className="hidden__select"
                              defaultValue={permission.page}
                              required
                            >
                              {/* <option defaultValue hidden value="">
                              {props.boot.table.section}
                            </option> */}
                              {renderGeneralSelect(permissionsCatalogues)}
                            </select>
                          </article>
                        </div>
                        <div className="hidden__actions">
                          <button
                            onClick={() => setEditPermission(false)}
                            type="button"
                            className="hidden__button hidden__button--cancel"
                          >
                            {props.boot.general.cancel}
                          </button>
                          <button
                            type="submit"
                            className="hidden__button hidden__button--save"
                          >
                            {props.boot.general.save}
                          </button>
                        </div>
                      </form>
                    )}
                    <DataTable
                      columns={permissionColumns}
                      data={generalData(permissionsInfo, permissionsFilter)}
                      className="table__container"
                      pagination
                      noHeader
                      subHeader
                      subHeaderComponent={PermissionComponent}
                    />
                  </div>
                </div>
              )}
              {activeBlock === "positions" && (
                <div className="permissions__positions">
                  <div className="table">
                    <div className="table__initial">
                      <h4 className="table__title">
                        {props.boot.table.position}
                      </h4>
                      <button
                        onClick={(e) => setAddPosition(true)}
                        className="table__add"
                      >
                        <img
                          className="table__image"
                          src={AddPositionIcon}
                          alt="Add Position"
                        />{" "}
                        {props.boot.permissions.addPosition}
                      </button>
                    </div>
                    {addPosition && (
                      <form
                        onSubmit={(e) => onSubmitInsertPosition(e)}
                        className="hidden"
                      >
                        <h2 className="hidden__title">
                          {props.boot.permissions.addPosition}
                        </h2>
                        <div className="hidden__grid hidden__grid--four">
                          <article className="hidden__item hidden__item--empty"></article>
                          <article className="hidden__item">
                            <label className="hidden__label" htmlFor="">
                              {props.boot.table.name}
                            </label>
                            <input
                              className="hidden__input"
                              type="text"
                              required
                            />
                          </article>
                          <article className="hidden__item">
                            <label className="hidden__label" htmlFor="">
                              {props.boot.table.details}
                            </label>
                            <input
                              className="hidden__input"
                              type="text"
                              required
                            />
                          </article>

                          <article className="hidden__item hidden__item--empty"></article>
                        </div>
                        <div className="hidden__actions">
                          <button
                            onClick={() => setAddPosition(false)}
                            type="button"
                            className="hidden__button hidden__button--cancel"
                          >
                            {props.boot.general.cancel}
                          </button>
                          <button
                            type="submit"
                            className="hidden__button hidden__button--save"
                          >
                            {props.boot.general.save}
                          </button>
                        </div>
                      </form>
                    )}
                    {editPosition && (
                      <form
                        onSubmit={(e) => onSubmitUpdatePosition(e)}
                        className="hidden"
                      >
                        <h2 className="hidden__title">
                          {props.boot.permissions.addPosition}
                        </h2>
                        <div className="hidden__grid hidden__grid--four">
                          <article className="hidden__item"></article>
                          <article className="hidden__item">
                            <label className="hidden__label" htmlFor="">
                              {props.boot.table.name}
                            </label>
                            <input
                              className="hidden__input"
                              type="text"
                              id="positionName"
                              defaultValue={position.name}
                            />
                          </article>
                          <article className="hidden__item">
                            <label className="hidden__label" htmlFor="">
                              {props.boot.table.details}
                            </label>
                            <input
                              className="hidden__input"
                              type="text"
                              id="positionDetails"
                              defaultValue={position.details}
                            />
                          </article>

                          <article className="hidden__item"></article>
                        </div>
                        <div className="hidden__grid hidden__grid--three">
                          {renderPermissions()}
                        </div>
                        <div className="hidden__actions">
                          <button
                            onClick={() => setEditPosition(false)}
                            type="button"
                            className="hidden__button hidden__button--cancel"
                          >
                            {props.boot.general.cancel}
                          </button>
                          <button
                            type="submit"
                            className="hidden__button hidden__button--save"
                          >
                            {props.boot.general.save}
                          </button>
                        </div>
                      </form>
                    )}
                    <DataTable
                      columns={positionColumns}
                      data={generalData(positionsInfo, positionsFilter)}
                      className="table__container"
                      pagination
                      noHeader
                      subHeader
                      subHeaderComponent={PositionComponent}
                    />
                  </div>
                </div>
              )}
            </div>
          </section>
        </main>
      )}
      {loading && (
        <div className="loading">
          <div className="loading__data">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </>
  );
}
