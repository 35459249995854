import { getBaseApi } from "../Routes";
import config from "../config";

export const onClickDownloadRenata = async (token, filters) => {
  const type = `dateType=${filters.dateType}`;
  const start = `dateStart=${filters.dateStart}`;
  const end = `dateEnd=${filters.dateEnd}`;
  const countries = `countries=${filters.countries}`;
  const affiliates = `affiliates=${filters.affiliates}`;
  const statuses = `statuses=${filters.statuses}`;
  const BU = `businessUnits=${filters.businessUnits}`;
  const query = `?${type}&${start}&${end}&${countries}&${statuses}&${affiliates}&${BU}`;
  const request = await fetch(`${getBaseApi()}/manage/Downloads${query}&action=Download_REN`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  if (!request.ok || request.status === 500) throw new Error("error_excel");
  const response = await request.blob();
  const url = window.URL.createObjectURL(response);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "Affico.xlsx");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const moveCustomer = async ({ token, tpid, buid }) => {
  const request = await fetch(`${config.api_move}/move-lead-crm`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ tpid, idBusinessUnit: buid })
  });

  if (!request.ok || request.status === 500) throw new Error("error_move_customer");
  const response = await request.json();
  return response;
}
