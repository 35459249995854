import React from "react";

import "../styles/components/Loader.scss";

const Loader = ({ message, container }) => (
  <div className={`loader${container ? " loader__container" : ""}`}>
    <div className="loader__spinner" />
    <p className="loader__text">{message}</p>
  </div>
);

export const TableLoader = () => (
  <div className="loading loading--table">
    <div className="loading__data">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

export default Loader;